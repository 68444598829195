import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, FormGroup, FormLabel, RadioGroup, FormControlLabel, Radio, Switch, StylesProvider } from "@material-ui/core";
import ImageUploader from 'react-images-upload';

//import './Step.scss';

class AttributForm extends Component {
  
render() {
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Attribute Schacht</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                label="Bezeichnung"
                name="name_nummer"
                variant="outlined"
                required
                fullWidth
				value={this.props.values.name_nummer}
                onChange={this.props.handleChange}
				id={'name_nummer' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Feature-ID"
                name="fid"
                variant="outlined"
                fullWidth
				id={'fid' + this.props.values.id}
				value={this.props.values.fid}
                onChange={this.props.handleChange}
				className="step-input"
				inputProps={{ inputMode: 'numeric' }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'lage' + this.props.values.id}
            select
            label="Lage"
		    name="id_lage"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_lage}
            onChange={this.props.handleChange}
          >
            {this.props.lage.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
		<Grid item xs={12} sm={6}>
          <TextField
            label="Standort"
            name="standort"
            variant="outlined"
            fullWidth
		    id={'standort' + this.props.values.id}
			value={this.props.values.standort}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id={'zugang' + this.props.values.id}
            select
            label="Zugang"
            name="id_zugang"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_zugang}
            onChange={this.props.handleChange}
          >
            {this.props.zugang.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'material' + this.props.values.id}
            select
            label="Material (Schacht)"
		    name="id_material"
		    variant="outlined"
		    fullWidth
			disabled={this.props.values.dependZugang}
		    className="step-input"
            value={this.props.values.id_material}
            onChange={this.props.handleChange}
          >
            {this.props.materialSchacht.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'funktion' + this.props.values.id}
            select
            label="Funktion"
		    name="id_function"
		    variant="outlined"
		    fullWidth
			disabled={this.props.values.dependZugang}
		    className="step-input"
            value={this.props.values.id_function}
            onChange={this.props.handleChange}
          >
            {this.props.funktion.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'nutzung' + this.props.values.id}
            select
            label="Nutzung"
		    name="id_nutzung"
		    variant="outlined"
		    fullWidth
			disabled={this.props.values.dependZugang}
		    className="step-input"
            value={this.props.values.id_nutzung}
            onChange={this.props.handleChange}
          >
            {this.props.nutzung.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'status' + this.props.values.id}
            select
            label="Status"
		    name="id_status"
		    variant="outlined"
		    fullWidth
			disabled={this.props.values.dependZugang}
		    className="step-input"
            value={this.props.values.id_status}
            onChange={this.props.handleChange}
          >
            {this.props.status.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
			id={'bemerkung' + this.props.values.id}
            label="Bemerkung"
            name="bemerkung"
            variant="outlined"
            fullWidth
			value={this.props.values.bemerkung}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Fotos</Typography>
        </Grid>
		<Grid item xs={12}>
           <ImageUploader
		       optimisticPreviews
               withIcon={true}
			   withPreview={true}
               buttonText='Bild Auswählen'
               onChange={this.props.onImgDrop}
               imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
               maxFileSize={10485760}
			   fileSizeError="die Datei ist zu gross"
           />
		 </Grid>
    </>
	)
}
}

export default AttributForm;
