import {
  CREATE_GAS_SCHIEBER_KONTROLLE,
  UPDATE_GAS_SCHIEBER_ATTR,
  GET_GAS_SCHIEBER_ART,
  GET_GAS_SCHIEBER_BEURTEILUNG,
  GET_GAS_SCHIEBER_DECKEL,
  GET_GAS_SCHIEBER_INSPEKTION,
  GET_GAS_SCHIEBER_MATERIAL,
  GET_GAS_SCHIEBER_SCHACHT,
  GET_GAS_SCHIEBER_SCHALTZUSTAND,
  GET_GAS_SCHIEBER_STANGE,
  GET_GAS_SCHIEBER_TAFEL,
  GET_GAS_SCHIEBER_ZUGANG,
  GET_GAS_DRUCKZONE,
  GET_GAS_EIGENTUEMER,
  GET_GAS_GEMEINDE,
  GET_GAS_STATUS
} from "../actions/types";

const initialState = {
  gasSchieber: [],
  gasSchieberKontrolle: [],
  gasSchieberArt: [],
  gasSchieberBeurteilung: [],
  gasSchieberDeckel: [],
  gasSchieberInspektion: [],
  gasSchieberMaterial: [],
  gasSchieberSchacht: [],
  gasSchieberSchaltzustand: [],
  gasSchieberStange: [],
  gasSchieberTafel: [],
  gasSchieberZugang: [],
  gasDruckzone: [],
  gasEigentuemer: [],
  gasGemeinde: [],
  gasStatus: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_GAS_SCHIEBER_KONTROLLE:
      return {
        ...state,
		gasSchieberKontrolle: action.payload
      };
    case UPDATE_GAS_SCHIEBER_ATTR:
      return {
        ...state,
		gasSchieber: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case GET_GAS_SCHIEBER_ART:
      return {
        ...state,
        gasSchieberArt: action.payload,
      };
    case GET_GAS_SCHIEBER_BEURTEILUNG:
      return {
        ...state,
        gasSchieberBeurteilung: action.payload,
      };
    case GET_GAS_SCHIEBER_DECKEL:
      return {
        ...state,
        gasSchieberDeckel: action.payload,
      };
    case GET_GAS_SCHIEBER_INSPEKTION:
      return {
        ...state,
        gasSchieberInspektion: action.payload,
      };
    case GET_GAS_SCHIEBER_MATERIAL:
      return {
        ...state,
        gasSchieberMaterial: action.payload,
      };
    case GET_GAS_SCHIEBER_SCHACHT:
      return {
        ...state,
        gasSchieberSchacht: action.payload,
      };
    case GET_GAS_SCHIEBER_SCHALTZUSTAND:
      return {
        ...state,
        gasSchieberSchaltzustand: action.payload,
      };
    case GET_GAS_SCHIEBER_STANGE:
      return {
        ...state,
        gasSchieberStange: action.payload,
      };
    case GET_GAS_SCHIEBER_TAFEL:
      return {
        ...state,
        gasSchieberTafel: action.payload,
      };
    case GET_GAS_SCHIEBER_ZUGANG:
      return {
        ...state,
        gasSchieberZugang: action.payload,
      };
    case GET_GAS_DRUCKZONE:
      return {
        ...state,
        gasDruckzone: action.payload,
      };
    case GET_GAS_EIGENTUEMER:
      return {
        ...state,
        gasEigentuemer: action.payload,
      };
    case GET_GAS_GEMEINDE:
      return {
        ...state,
        gasGemeinde: action.payload,
      };
    case GET_GAS_STATUS:
      return {
        ...state,
        gasStatus: action.payload,
      };
    default:
      return state;
  }
}