import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider, Divider} from '@material-ui/core';

//Actions
import { uploadImg, createPhoto } from "../../../../actions/uploadActions";
import { updateAVEinzelobjektAttr, getAVEinzelobjektGemeinde } from "../../../../actions/avActions";

import equal from 'fast-deep-equal'

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleRadioChange, onImgDrop, values, avGemeinde}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} handleRadioChange={handleRadioChange} values={values} avGemeinde={avGemeinde} onImgDrop={onImgDrop}/>;
        default:
            return <></>;
    }
}


class AVEinzelobjektStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Layout
  	  step: 1,
      activeStep: 0,
	  
	  // Einzelobjekt
	  id: this.props.objData.id,
	  fid: this.props.objData.fid,
	  name_nummer: this.props.objData.name_nummer,
	  standort: this.props.objData.standort,
	  bemerkung: this.props.objData.bemerkung,
	  ist_unterirdisch: this.props.objData.ist_unterirdisch,
	  ist_unterstand: this.props.objData.ist_unterstand,
	  ist_gebaeudedetail: this.props.objData.ist_gebaeudedetail,
	  
	  // Bilder
	  pictures: [],
	  selectedImg: [],

	}
	this.handleChange = this.handleChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

 componentDidMount() {
	 //console.log(this.props.objData.id)
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.objData.id !== prevProps.objData.id) {
	  this.setState({ 
	    id: this.props.objData.id,
	    fid: this.props.objData.fid,
	    name_nummer: this.props.objData.name_nummer,
	    standort: this.props.objData.standort,
	    bemerkung: this.props.objData.bemerkung,
	    id_gemeinde: this.props.objData.id_gemeinde,
		ist_unterirdisch: this.props.objData.ist_unterirdisch,
		ist_unterstand: this.props.objData.ist_unterstand,
		ist_gebaeudedetail: this.props.objData.ist_gebaeudedetail
      });
    }
	 
}

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }
  
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }

  onImgDrop(picture) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(picture),
	  pictures: this.state.pictures.concat(picture),
    });
  }

  handleNext = () => {
	  
	 let EditData = {
	   db_schema: this.props.objInfo.db_schema,
       id: this.state.id,
	   fid: this.state.fid,
       name_nummer: this.state.name_nummer,
	   ist_unterirdisch: this.state.ist_unterirdisch,
	   ist_unterstand: this.state.ist_unterstand,
	   ist_gebaeudedetail: this.state.ist_gebaeudedetail,
	   standort: this.state.standort,
	   bemerkung: this.state.bemerkung
     };
	  
	  if (EditData.id_gemeinde === '') {EditData.id_gemeinde = null}
	  if (EditData.fid === '') {EditData.fid = null}
      this.props.updateAVEinzelobjektAttr(EditData);
	  
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }
      this.setState({ loading: false, activeStep: 0 })
	  this.props.history.push("/map");
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.push("/map");
  }
  

  render() {
    const object = this.props.objData;
	let buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleRadioChange={this.handleRadioChange}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  avGemeinde={this.props.avGemeinde}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						<Divider />
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={2} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={10} sm={6} justify="flex-end">
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object,
  uploads: state.uploads,

});

export default connect(
  mapStateToProps,
    { uploadImg,
	  createPhoto,
      updateAVEinzelobjektAttr  }
)(AVEinzelobjektStepper);