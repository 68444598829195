import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AppBar, Toolbar, Typography, Grid, Container, Paper, Box, } from "@material-ui/core";

import Spinner from "../layout/Spinner/Spinner";
import AnlageStepper from "./was/anlage/Stepper";
import HydrantStepper from "./was/hydrant/Stepper";
import ReservoirStepper from "./was/reservoir/Stepper";
import SchieberStepper from "./was/schieber/Stepper";
import PumpwerkStepper from "./was/pumpwerk/Stepper";
import EwSchachtStepper from "./ew/schacht/Stepper";
import GasSchieberStepper from "./gas/schieber/Stepper";
import AbwSchachtStepper from "./abw/schacht/Stepper";
import AbwSchachtKEKStepper from "./abw/schachtKEK/Stepper";
import AbwManholeStepper from "./abw/manhole/Stepper";
import AVEinzelobjektStepper from "./av/einzelobjekt/Stepper";

import './Workflow.scss';
import logo from "../../img/logo_mobile.png";

import { getObject } from "../../actions/objectActions";

class Workflow extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  category: this.props.match.params.category,
	  object: this.props.match.params.object,
	  db_schema: this.props.match.params.db_schema,
	  id: this.props.match.params.id
	}
  }
	  
  componentDidMount() {
	this.props.getObject(this.props.match.params.category, this.props.match.params.object, this.props.match.params.db_schema, this.props.match.params.id);
    let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.add("top-nav-invisibile"); }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.getObject(this.props.match.params.category, this.props.match.params.object, this.props.match.params.db_schema, this.props.match.params.id);
    }
  }


  render() {
      const { object, objectLoading } = this.props.objects;
	  const objectValue = this.props.match.params.object
	  const objectValueCap = objectValue.charAt(0).toUpperCase() + objectValue.slice(1)
	  let stepperContent

    if (Object.keys(object).length === 0 && object.constructor === Object && objectLoading) {
      stepperContent = <Spinner />;
    } else if (Object.keys(object).length > 0) {
		
		let objData = JSON.parse(JSON.stringify(object).replace(/\:null/gi, "\:\"\"")); 
		
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'anlage') {
		  stepperContent = <AnlageStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'hydrant') {
		  stepperContent = <HydrantStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'reservoir') {
		  stepperContent = <ReservoirStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'schieber') {
		  stepperContent = <SchieberStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'pumpwerk') {
		  stepperContent = <PumpwerkStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'ew' && this.props.match.params.object === 'schacht') {
		  stepperContent = <EwSchachtStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'gas' && this.props.match.params.object === 'schieber') {
		  stepperContent = <GasSchieberStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'abw' && this.props.match.params.object === 'schacht') {
		  stepperContent = <AbwSchachtStepper history={this.props.history} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'av' && this.props.match.params.object === 'einzelobjekt') {
		  stepperContent = <AVEinzelobjektStepper history={this.props.history} objInfo={this.state} objData={objData}/>
	    }
	    if (this.props.match.params.category === 'abw' && this.props.match.params.object === 'manhole') {
		  stepperContent = <AbwSchachtStepper history={this.props.history} objInfo={this.state}/>
	    }
		if (this.props.match.params.category === 'abw' && this.props.match.params.object === 'schachtkek') {
		  stepperContent = <AbwSchachtKEKStepper history={this.props.history} objInfo={this.state}/>
	    }
	}
	  
    return (
	    <div>
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                             <img src={logo} className="wf-logo-mobile" alt="logo" height="70px" />
                         </Grid>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>{objectValueCap}</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
	        <Box component="main" className="wf-box-wrapper">
                <Container maxWidth="md" className="wf-container">
                    <Paper elevation={5}>
						{stepperContent}
                    </Paper>
                </Container>
            </Box>
	    </div>
    );
  }
}


Workflow.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  objects: state.objects
});

export default connect(
  mapStateToProps,
    { getObject	}
)(Workflow);