import {
  UPLOAD_IMG,
  UPLOAD_LOADING,
  CREATE_PHOTO
} from "../actions/types";

const initialState = {
  photos: [],
  uploadLoading: false,
  photo: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_IMG:
      return {
        ...state,
        photos: [action.payload, ...state.photos],
		uploadLoading: false
      };
    case UPLOAD_LOADING:
      return {
        ...state,
        uploadLoading: true
      };
    case CREATE_PHOTO:
      return {
        ...state,
		photo: action.payload
      };
    default:
      return state;
  }
}