import React, { Component } from 'react';
import { TextField, Grid, Typography, Checkbox, FormGroup, FormControlLabel, FormLabel, FormHelperText, RadioGroup, Radio, StylesProvider, Divider, withStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

import './Step.scss';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class WeekControlForm extends Component {
  
render() {

    return (
	  <>
        <Grid item xs={12}>
          <Typography className="form-titel" variant="h6">Kontrolle - Woche</Typography>
        </Grid>
		<StylesProvider injectFirst>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel" >Umgebung*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_umgebung"
		  	    value={this.props.values.ist_umgebung}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Schutzzone, Zufahrt, Abschrankung, Signalisation, Bepflanzungen, Bewirtschaftung, Entwässerung, Nässe, Fremdnutzungen, Grabarbeiten</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_umgebung_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_umgebung_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_umgebung_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Gebäude*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_gebaeude"
		  	    value={this.props.values.ist_gebaeude}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Zugang, Fassade, Aussen- und Innentüren, Schliessanlage, Schmutzschleuse, Vandalismus, Beschriftungen, Dach, Kondenswasser, Sauberkeit, Aussengerät Klimaanlage, Blitzschutzanlage</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_gebaeude_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_gebaeude_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
			  value={this.props.values.ist_gebaeude_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Einbruchmeldeanlage*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_einbruch"
		  	    value={this.props.values.ist_einbruch}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Bediengerät Securiton, Aktivierungskontrolle</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_einbruch_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_einbruch_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_einbruch_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Beleuchtung*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_beleuchtung"
		  	    value={this.props.values.ist_beleuchtung}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>innerhalb und ausserhalb des Gebäudes </FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_beleuchtung_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_beleuchtung_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_beleuchtung_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Steuerung und Prozessleitsystem*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_steuerung"
		  	    value={this.props.values.ist_steuerung}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Panel-PC, Display für Notbetrieb, anstehende Alarme</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_steuerung_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_steuerung_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_steuerung_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Elektroschaltanlage und -Installationen*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_elektro"
		  	    value={this.props.values.ist_elektro}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Sicht- und Geruchskontrolle, Steckdosen, Frequenzumformer (inkl. Steuerungsgerät)</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_elektro_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_steuerung_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_elektro_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Qualitätsüberwachung*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_qualitaet"
		  	    value={this.props.values.ist_qualitaet}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Probenahmestellen, Panel-PC, Plausibilisierung der aktuellen Werte</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_qualitaet_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_qualitaet_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_qualitaet_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Geräte*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_geraete"
		  	    value={this.props.values.ist_geraete}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Probenahmestellen, Panel-PC, Plausibilisierung der aktuellen Werte</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_geraete_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_geraete_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_geraete_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">UV-Anlage*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_uv_anlage"
		  	    value={this.props.values.ist_uv_anlage}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Sichtkontrolle von Anlage, Grobcheck Display und anstehende Alarme am Steuerungs-schrank, Kontrolle Lagermaterial v. Ersatz UV-Strahler</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_uv_anlage_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_uv_anlage_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_uv_anlage_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Armaturen und Verrohrungen*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_armatur"
		  	    value={this.props.values.ist_armatur}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Sichtkontrolle, Dichtheit, Kondenswasser</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_armatur_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_armatur_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_armatur_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Brunnen*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_brunnen"
		  	    value={this.props.values.ist_brunnen}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Brunnenabdeckung, Beleuchtung, Filter, Sicht- und Geruchskontrolle, Ablagerungen, Trübung, Niveaukontrolle</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_brunnen_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_brunnen_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_brunnen_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Dokumentation*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_dokumentation"
		  	    value={this.props.values.ist_dokumentation}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Zutrittskontrolle auf spezielle Hinweise prüfen, Anlagendokumente auf Vollständigkeit und richtiger Platzierung prüfen</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_dokumentation_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_dokumentation_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_dokumentation_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Hygieneeinrichtungen*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_hygiene"
		  	    value={this.props.values.ist_hygiene}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Waschtrog, Abfallkübel leeren, Flüssigseife und Papierhandtücher nachfüllen</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_hygiene_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_hygiene_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_hygiene_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
      </StylesProvider>
    </>
	)
}
}

export default WeekControlForm;
