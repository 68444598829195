import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import projectReducer from "./projectReducer";
import objectReducer from "./objectReducer";
import uploadReducer from "./uploadReducer";
// Werke
import abwReducer from "./abwReducer";
import ewReducer from "./ewReducer";
import gasReducer from "./gasReducer";
import wasReducer from "./wasReducer";
import avReducer from "./avReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  projects: projectReducer,
  objects: objectReducer,
  uploads: uploadReducer,
  abw: abwReducer,
  av: avReducer,
  ew: ewReducer,
  gas: gasReducer,
  was: wasReducer
});