import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, FormGroup, FormLabel, RadioGroup, FormControlLabel, Radio, Switch, StylesProvider } from "@material-ui/core";
import ImageUploader from 'react-images-upload';

//import './Step.scss';

class AttributForm extends Component {
  
render() {
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Attribute Schacht</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                label="Bezeichnung"
                name="name_nummer"
                variant="outlined"
                required
                fullWidth
				value={this.props.values.name_nummer}
                onChange={this.props.handleChange}
				id={'name_nummer' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Feature-ID"
                name="fid"
                variant="outlined"
                fullWidth
				id={'fid' + this.props.values.id}
				value={this.props.values.fid}
                onChange={this.props.handleChange}
				className="step-input"
				inputProps={{ inputMode: 'numeric' }}
            />
        </Grid>
		<Grid item xs={12} sm={12}>
          <TextField
            label="Standort"
            name="standort"
            variant="outlined"
            fullWidth
		    id={'standort' + this.props.values.id}
			value={this.props.values.standort}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
			id={'bemerkung' + this.props.values.id}
            label="Bemerkung"
            name="bemerkung"
            variant="outlined"
            fullWidth
			value={this.props.values.bemerkung}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Objekt ist Unterirdisch?*</Typography>
        </Grid>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		  <FormGroup>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_unterirdisch"
			   value={this.props.values.ist_unterirdisch}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio required={true} /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                 control={<Radio required={true} /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
			 </RadioGroup>
			</FormGroup>
		  </Grid>
	    </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Unterstand?*</Typography>
        </Grid>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		  <FormGroup>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_unterstand"
			   value={this.props.values.ist_unterstand}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio required={true} /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                 control={<Radio required={true} /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
			 </RadioGroup>
			</FormGroup>
		  </Grid>
	    </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">übriger Gebäudedetail?*</Typography>
        </Grid>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		  <FormGroup>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_gebaeudedetail"
			   value={this.props.values.ist_gebaeudedetail}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio required={true} /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                 control={<Radio required={true} /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
			 </RadioGroup>
			</FormGroup>
		  </Grid>
	    </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Fotos</Typography>
        </Grid>
		<Grid item xs={12}>
           <ImageUploader
		       optimisticPreviews
               withIcon={true}
			   withPreview={true}
               buttonText='Bild Auswählen'
               onChange={this.props.onImgDrop}
               imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
               maxFileSize={10485760}
			   fileSizeError="die Datei ist zu gross"
           />
		 </Grid>
    </>
	)
}
}

export default AttributForm;
