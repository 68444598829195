import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, Checkbox, FormGroup, FormControlLabel, FormLabel, FormHelperText, RadioGroup, Radio, StylesProvider, Divider, withStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

import './Step.scss';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class QuaterControlForm extends Component {
  
render() {

    return (
	  <>
        <Grid item xs={12}>
          <Typography className="form-titel" variant="h6">Kontrolle - Quartal</Typography>
        </Grid>
		<StylesProvider injectFirst>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel" >Armaturen*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_q_armatur"
		  	    value={this.props.values.ist_q_armatur}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Funktionskontrolle aller Absperr- und Regelarmaturen, Schliessverhalten, Leichtgängigkeit, Vibrationen, Korrosions- und Farbschäden, Wasserstagnation</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_q_armatur_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_q_armatur_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_q_armatur_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
      </StylesProvider>
    </>
	)
}
}

export default QuaterControlForm;
