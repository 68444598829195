import React, { Component,  } from 'react';
import { connect } from "react-redux";
//import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider} from '@material-ui/core';

//Actions
import { uploadImg, createPhoto } from "../../../../actions/uploadActions";
import { updateWasserPumpwerkAttr, createWasserPumpwerkKontrolle, getWasserPumpwerkKontrolleTyp } from "../../../../actions/wasActions";

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import WeekControlForm from "./Steps/Step2";
import MonthControlForm from "./Steps/Step3";
import QuaterControlForm from "./Steps/Step4";
import YearControlForm from "./Steps/Step5";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleCheckChange, handleRadioChange, onImgDrop, values, wasPumpwerkKontrlTyp}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} values={values} wasPumpwerkKontrlTyp={wasPumpwerkKontrlTyp} onImgDrop={onImgDrop}/>;
        case 1:
            return <WeekControlForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 2:
            return <MonthControlForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 3:
            return <QuaterControlForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 4:
            return <YearControlForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        default:
            return <></>;
    }
}


class PumpwerkStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Stepper
  	  step: 1,
      activeStep: 0,
	  db_schema: this.props.objInfo.db_schema,
	  
	  //Photos
	  photos: [],
	  selectedImg: [],

	  //Pumpwerk
	  id: '',
	  fid: '',
	  name_nummer: '',
	  standort: '',
	  bemerkung: '',

      //Kontrolle Woche 
      ist_umgebung: null,
      ist_umgebung_bemerkung: '',
      ist_gebaeude: null,
      ist_gebaeude_bemerkung: '',
      ist_einbruch: null,
      ist_einbruch_bemerkung: '',
      ist_beleuchtung: null,
      ist_beleuchtung_bemerkung: '',
      ist_steuerung: null,
      ist_steuerung_bemerkung: '',
      ist_elektro: null,
      ist_elektro_bemerkung: '',
      ist_qualitaet: null,
      ist_qualitaet_bemerkung: '',
      ist_geraete: null,
      ist_geraete_bemerkung: '',
      ist_uv_anlage: null,
      ist_uv_anlage_bemerkung: '',
      ist_armatur: null,
      ist_armatur_bemerkung: '',
      ist_brunnen: null,
      ist_brunnen_bemerkung: '',
      ist_dokumentation: null,
      ist_dokumentation_bemerkung: '',
      ist_hygiene: null,
      ist_hygiene_bemerkung: '',
	  //Kontrolle Monat
      ist_monat_geraete: null,
      ist_monat_geraete_bemerkung: '',
      ist_monat_dokumentation: null,
      ist_monat_dokumentation_bemerkung: '',
	  //Kontrolle Quartal
      ist_q_armatur: null,
      ist_q_armatur_bemerkung: '',
	  //Kontrolle Jahr
      ist_jahr_dokumentation: null,
      ist_jahr_dokumentation_bemerkung: '',
      ist_jahr_notbeleuchtung: null,
      ist_jahr_notbeleuchtung_bemerkung: '',
      ist_jahr_feuerloescher: null,
      ist_jahr_feuerloescher_bemerkung: '',
      ist_jahr_reinigung: null,
      ist_jahr_reinigung_bemerkung: '',
      ist_jahr_hebezug: null,
      ist_jahr_hebezug_bemerkung: '',
      ist_jahr_armaturen: null,
      ist_jahr_armaturen_bemerkung: '',
      ist_jahr_risikoanalyse: null,
      ist_jahr_risikoanalyse_bemerkung: '',
      bemerkung_kontrolle: '',
      id_kontroll_typ: '',
      ist_monat_dok_zaehler: 0,
      ist_monat_dok_pumpstunden: 0,
	}
	this.handleChange = this.handleChange.bind(this);
	this.handleCheckChange = this.handleCheckChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

  componentDidMount() {
	  this.props.getWasserPumpwerkKontrolleTyp(this.props.objInfo.db_schema);
  }
  
  componentWillReceiveProps(nextProps) {
	  if ((nextProps.object.id !== this.props.object.id) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ id: nextProps.object.id }); }
	  if ((nextProps.object.fid !== this.props.object.fid && nextProps.object.fid !== null) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.object.fid }); }
	  if ((nextProps.object.name_nummer !== this.props.object.name_nummer && nextProps.object.name_nummer !== null) || (nextProps.object.name_nummer && this.state.name_nummer === '')) {this.setState({ name_nummer: nextProps.object.name_nummer }); }
	  if ((nextProps.object.standort !== this.props.object.standort && nextProps.object.standort !== null) || (nextProps.object.standort && this.state.standort === '')) {this.setState({ standort: nextProps.object.standort }); }
	  if ((nextProps.object.bemerkung !== this.props.object.bemerkung && nextProps.object.bemerkung !== null) || (nextProps.object.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.object.bemerkung }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }

  handleCheckChange(event) {
    const {name, checked} = event.target
    this.setState({
      [name]: checked
    })
  }
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }

  onImgDrop(photo) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(photo),
	  photos: this.state.photos.concat(photo),
    });
  }

  handleNext = () => {
	  
	if (this.state.activeStep === 0) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }
	  
	   // Update Attribute Pumpwerk
	  let EditData = {
		db_schema: this.props.objInfo.db_schema,
		id: this.state.id,
	    fid: this.state.fid,
	    name_nummer: this.state.name_nummer,
	    standort: this.state.standort,
	    bemerkung: this.state.bemerkung,
      };

      this.props.updateWasserPumpwerkAttr(EditData);  
      this.setState({ activeStep: this.state.activeStep + 1 });
	}

    if (this.state.activeStep === 1 && this.state.id_kontroll_typ === 1) {	
       // Kontrolle Erfassen (Woche)
	  let CreateData = this.state;
      this.props.createWasserPumpwerkKontrolle(CreateData);;
	  this.setState({ activeStep: 0 });
	  this.props.history.push("/map");
    }
    if (this.state.activeStep === 2 && this.state.id_kontroll_typ === 2) {	
      // Kontrolle Erfassen (Monat)
	  let CreateData = this.state;
      this.props.createWasserPumpwerkKontrolle(CreateData);;
	  this.setState({ activeStep: 0 });
	  this.props.history.push("/map");
    }
    if (this.state.activeStep === 3 && this.state.id_kontroll_typ === 3) {	
      // Kontrolle Erfassen (Quartal)
	  let CreateData = this.state;
      this.props.createWasserPumpwerkKontrolle(CreateData);;
	  this.setState({ activeStep: 0 });
	  this.props.history.push("/map");
    }
    if (this.state.activeStep === 4 && this.state.id_kontroll_typ === 4) {	
      // Kontrolle Erfassen (Jahr)
	  let CreateData = this.state;
      this.props.createWasserPumpwerkKontrolle(CreateData);;
	  this.setState({ activeStep: 0 });
	  this.props.history.push("/map");
    }	else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.goBack();
  }
  

  render() {
	let buttonNext;
    if ((this.state.activeStep === 1 && this.state.id_kontroll_typ === 1) || (this.state.activeStep === 2 && this.state.id_kontroll_typ === 2) || (this.state.activeStep === 3 && this.state.id_kontroll_typ === 3) || (this.state.activeStep === 4 && this.state.id_kontroll_typ === 4)) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}
	
	let StepperSteps;
	  if (this.state.id_kontroll_typ === 2) {
	    StepperSteps = [1,2,3]
	  }
	  else if (this.state.id_kontroll_typ === 3) {
	    StepperSteps = [1,2,3,4] 
      }
	  else if (this.state.id_kontroll_typ === 4) {
	    StepperSteps = [1,2,3,4,5]
      } else { 
	    StepperSteps = [1,2]
      } 
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {StepperSteps.map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleCheckChange={this.handleCheckChange}
						  handleRadioChange={this.handleRadioChange}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  wasPumpwerkKontrlTyp={this.props.wasPumpwerkKontrlTyp}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object,
  wasPumpwerkKontrlTyp: state.wasser.wasPumpwerkKontrlTyp,
  photos: state.photos
});

export default connect(
  mapStateToProps,
    { uploadImg,
	  createPhoto,
      updateWasserPumpwerkAttr,
	  createWasserPumpwerkKontrolle,
	  getWasserPumpwerkKontrolleTyp
	  }
)(PumpwerkStepper);