// Authentication
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Projects
export const GET_PROJECTS_OF_USER = "GET_PROJECTS_OF_USER";
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const GET_PROJECT_OF_USER = "GET_PROJECT_OF_USER";
export const PROJECT_LOADING = "PROJECT_LOADING";

//Objects
export const GET_OBJECTS = "GET_OBJECTS";
export const OBJECTS_LOADING = "OBJECTS_LOADING";
export const GET_OBJECT = "GET_OBJECT";
export const GET_OBJECT_DS = "GET_OBJECT_DS";
export const OBJECT_LOADING = "OBJECT_LOADING";
export const CREATE_OBJECT = "CREATE_OBJECT";
export const UPDATE_OBJECT_GEOM = "UPDATE_OBJECT_GEOM";
export const UPDATE_OBJECT_ATTR = "UPDATE_OBJECT_ATTR";
export const DELETE_OBJECT = "DELETE_OBJECT";

// Abwasser
export const CREATE_ABW_SCHACHT_KONTROLLE = "CREATE_ABW_SCHACHT_KONTROLLE";
export const UPDATE_ABW_SCHACHT_ATTR = "UPDATE_ABW_SCHACHT_ATTR";
export const UPDATE_ABW_SCHACHT_ZUGANG = "UPDATE_ABW_SCHACHT_ZUGANG";
export const UPDATE_ABW_SCHACHT_KEK = "UPDATE_ABW_SCHACHT_KEK";
export const GET_ABW_SCHACHT_MATERIAL = "GET_ABW_SCHACHT_MATERIAL";
export const GET_ABW_DECKEL_MATERIAL = "GET_ABW_DECKEL_MATERIAL";
export const GET_ABW_SCHACHT_FUNKTION = "GET_ABW_SCHACHT_FUNKTION";
export const GET_ABW_SCHACHT_FORM = "GET_ABW_SCHACHT_FORM";
export const GET_ABW_SCHACHT_NUTZUNG = "GET_ABW_SCHACHT_NUTZUNG";
export const GET_ABW_SCHACHT_STATUS = "GET_ABW_SCHACHT_STATUS";
export const GET_ABW_SCHACHT_OBERFLAECHENZULAUF = "GET_ABW_SCHACHT_OBERFLAECHENZULAUF";
export const GET_ABW_SCHACHT_EINSTIEGSHILFE = "GET_ABW_SCHACHT_EINSTIEGSHILFE";
export const GET_ABW_SCHACHT_ZUGANG = "GET_ABW_SCHACHT_ZUGANG";
export const GET_ABW_SCHACHT_LAGE = "GET_ABW_SCHACHT_LAGE";
export const GET_ABW_SCHACHT_SCHACHTBEREICH = "GET_ABW_SCHACHT_SCHACHTBEREICH";
export const GET_ABW_SCHACHT_SCHADENCODE = "GET_ABW_SCHACHT_SCHADENCODE";

//AV
export const GET_AV_FIXPUNKT = "GET_AV_FIXPUNKT";
export const GET_AV_EINZELOBJEKT = "GET_AV_EINZELOBJEKT";
export const CREATE_AV_FIXPUNKT_KONTROLLE = "CREATE_AV_FIXPUNKT_KONTROLLE";
export const UPDATE_AV_FIXPUNKT_ATTR = "UPDATE_AV_FIXPUNKT_ATTR";
export const UPDATE_AV_EINZELOBJEKT_ATTR = "UPDATE_AV_EINZELOBJEKT_ATTR";
export const GET_AV_FIXPUNKT_KATEGORIE = "GET_AV_FIXPUNKT_KATEGORIE";
export const GET_AV_FIXPUNKT_MARK = "GET_AV_FIXPUNKT_MARK";
export const GET_AV_FIXPUNKT_SCHUTZ = "GET_AV_FIXPUNKT_SCHUTZ";
export const GET_AV_FIXPUNKT_ZUGANG = "GET_AV_FIXPUNKT_ZUGANG";
export const GET_AV_FIXPUNKT_GEMEINDE = "GET_AV_FIXPUNKT_GEMEINDE";
export const GET_AV_EINZELOBJEKT_GEMEINDE = "GET_AV_EINZELOBJEKT_GEMEINDE";


//Elektro
export const CREATE_EW_BELEUCHTUNG_KONTROLLE = "CREATE_EW_BELEUCHTUNG_KONTROLLE";
export const CREATE_EW_SCHACHT_KONTROLLE = "CREATE_EW_SCHACHT_KONTROLLE";
export const CREATE_EW_STATION_KONTROLLE = "CREATE_EW_STATION_KONTROLLE";
export const CREATE_EW_KABINE_KONTROLLE = "CREATE_EW_KABINE_KONTROLLE";
export const UPDATE_EW_BELEUCHTUNG_ATTR = "UPDATE_EW_BELEUCHTUNG_ATTR";
export const UPDATE_EW_SCHACHT_ATTR = "UPDATE_EW_SCHACHT_ATTR";
export const UPDATE_EW_STATION_ATTR = "UPDATE_EW_STATION_ATTR";
export const UPDATE_EW_KABINE_ATTR = "UPDATE_EW_KABINE_ATTR";
export const GET_EW_SCHACHT_EIGENTUEMER = "GET_EW_SCHACHT_EIGENTUEMER";
export const GET_EW_SCHACHT_LAGEBESTIMMUNG = "GET_EW_SCHACHT_LAGEBESTIMMUNG";
export const GET_EW_SCHACHT_TYP = "GET_EW_SCHACHT_TYP";
export const GET_EW_SCHACHT_ZUGANG = "GET_EW_SCHACHT_ZUGANG";
export const GET_EW_ROHR_TYP = "GET_EW_ROHR_TYP";

//Gas
export const CREATE_GAS_SCHIEBER_KONTROLLE = "CREATE_GAS_SCHIEBER_KONTROLLE";
export const UPDATE_GAS_SCHIEBER_ATTR = "UPDATE_GAS_SCHIEBER_ATTR";
export const GET_GAS_SCHIEBER_ART = "GET_GAS_SCHIEBER_ART";
export const GET_GAS_SCHIEBER_BEURTEILUNG = "GET_GAS_SCHIEBER_BEURTEILUNG";
export const GET_GAS_SCHIEBER_DECKEL = "GET_GAS_SCHIEBER_DECKEL";
export const GET_GAS_SCHIEBER_INSPEKTION = "GET_GAS_SCHIEBER_INSPEKTION";
export const GET_GAS_SCHIEBER_MATERIAL = "GET_GAS_SCHIEBER_MATERIAL";
export const GET_GAS_SCHIEBER_SCHACHT = "GET_GAS_SCHIEBER_SCHACHT";
export const GET_GAS_SCHIEBER_SCHALTZUSTAND = "GET_GAS_SCHIEBER_SCHALTZUSTAND";
export const GET_GAS_SCHIEBER_STANGE = "GET_GAS_SCHIEBER_STANGE";
export const GET_GAS_SCHIEBER_TAFEL = "GET_GAS_SCHIEBER_TAFEL";
export const GET_GAS_SCHIEBER_ZUGANG = "GET_GAS_SCHIEBER_ZUGANG";
export const GET_GAS_DRUCKZONE = "GET_GAS_DRUCKZONE";
export const GET_GAS_EIGENTUEMER = "GET_GAS_EIGENTUEMER";
export const GET_GAS_GEMEINDE = "GET_GAS_GEMEINDE";
export const GET_GAS_STATUS = "GET_GAS_STATUS";

//Wasser
export const CREATE_WAS_ANLAGE_KONTROLLE = "CREATE_WAS_ANLAGE_KONTROLLE";
export const CREATE_WAS_HYDRANT_KONTROLLE = "CREATE_WAS_HYDRANT_KONTROLLE";
export const CREATE_WAS_RESERVOIR_KONTROLLE = "CREATE_WAS_RESERVOIR_KONTROLLE";
export const CREATE_WAS_SCHIEBER_KONTROLLE = "CREATE_WAS_SCHIEBER_KONTROLLE";
export const CREATE_WAS_PUMPWERK_KONTROLLE = "CREATE_WAS_PUMPWERK_KONTROLLE";
export const UPDATE_WAS_ANLAGE_ATTR = "UPDATE_WAS_ANLAGE_ATTR";
export const UPDATE_WAS_HYDRANT_ATTR = "UPDATE_WAS_HYDRANT_ATTR";
export const UPDATE_WAS_RESERVOIR_ATTR = "UPDATE_WAS_RESERVOIR_ATTR";
export const UPDATE_WAS_SCHIEBER_ATTR = "UPDATE_WAS_SCHIEBER_ATTR";
export const UPDATE_WAS_PUMPWERK_ATTR = "UPDATE_WAS_PUMPWERK_ATTR";
export const GET_WAS_ANLAGE_ART = "GET_WAS_ANLAGE_ART";
export const GET_WAS_ANLAGE_MATERIAL = "GET_WAS_ANLAGE_MATERIAL";
export const GET_WAS_ANLAGE_TYP = "GET_WAS_ANLAGE_TYP";
export const GET_WAS_HYDRANT_TYP = "GET_WAS_HYDRANT_TYP";
export const GET_WAS_RESERVOIR_TYP = "GET_WAS_RESERVOIR_TYP";
export const GET_WAS_SCHIEBER_MATERIAL = "GET_WAS_SCHIEBER_MATERIAL";
export const GET_WAS_SCHIEBER_TYP = "GET_WAS_SCHIEBER_TYP";
export const GET_WAS_PUMPWERK_KONTRL_TYP = "GET_WAS_PUMPWERK_KONTRL_TYP";

// Upload
export const UPLOAD_IMG = "UPLOAD_IMG";
export const UPLOAD_LOADING = "UPLOAD_LOADING";
export const CREATE_PHOTO = "CREATE_PHOTO";