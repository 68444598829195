import {
  GET_OBJECTS,
  OBJECTS_LOADING,
  GET_OBJECT,
  GET_OBJECT_DS,
  OBJECT_LOADING,
  CREATE_OBJECT,
  UPDATE_OBJECT_GEOM,
  DELETE_OBJECT
} from "../actions/types";

const initialState = {
  objects: [],
  object: {},
  newObject: {},
  objectsLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OBJECTS:
      return {
        ...state,
        objects: action.payload,
        objectsLoading: false
      };
    case OBJECTS_LOADING:
      return {
        ...state,
        objectsLoading: true
      };
    case GET_OBJECT:
      return {
        ...state,
        object: action.payload,
        objectLoading: false
      };
    case GET_OBJECT_DS:
      return {
        ...state,
        object: action.payload,
        objectLoading: false
      };
    case OBJECT_LOADING:
      return {
        ...state,
        objectLoading: true
      };
    case CREATE_OBJECT:
      return {
        ...state,
		newObject: action.payload,
        objects: [action.payload, ...state.objects]
      };
//    case UPDATE_OBJECT_GEOM:
//      let indexGeom = state.objects.findIndex(
//        object => object.id === action.payload.id
//      );
//	  console.log(action.payload)
//      console.log(indexGeom)
//	  console.log(state.objects)
//      state.objects.splice(indexGeom, 1);
//	  console.log(state.objects)
//
//      return {
//        ...state,
//        objects: [action.payload, ...state.objects]
//      };
    case DELETE_OBJECT:
      return {
        ...state,
        objects: state.objects.filter(
          object => object._id !== action.payload
        )
      };
    default:
      return state;
  }
}