import React, { Component } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider} from "@material-ui/core";

import './Step.scss';

class SubmitForm extends Component {

render() {
	let values = this.props.values
	let fidValue = '-'
	let nameNummerValue = '-'
	let bemerkungValue = '-'
	let hoeheValue = '-'
	let genHoeheValue = '-'
	let genLageValue = '-'
	let gemeindeValue = '-'
	let kategorieValue = '-'
	let vermarkungValue = '-'
	let schutzValue = '-'
	let zugangValue = '-'
	if (values.fid) { fidValue = values.fid }
	if (values.name_nummer) { nameNummerValue = values.name_nummer }
	if (values.bemerkung) { bemerkungValue = values.bemerkung }
	if (values.hoehe) { hoeheValue = values.hoehe }
	if (values.genauigkeit_hoehe) { genHoeheValue = values.genauigkeit_hoehe }
	if (values.genauigkeit_lage) { genLageValue = values.genauigkeit_lage }
	if (values.id_gemeinde) { gemeindeValue = this.props.avFixpunktGemeinde.filter(entity => entity.id === values.id_gemeinde)[0].value }
	if (values.id_kategorie) { kategorieValue = this.props.avFixpunktKategorie.filter(entity => entity.id === values.id_kategorie)[0].value }
	if (values.id_vermarkung) { vermarkungValue = this.props.avFixpunktMark.filter(entity => entity.id === values.id_vermarkung)[0].value }
	if (values.id_schutz) { schutzValue = this.props.avFixpunktSchutz.filter(entity => entity.id === values.id_schutz)[0].value }
	if (values.id_zugang) { zugangValue = this.props.avFixpunktZugang.filter(entity => entity.id === values.id_zugang)[0].value }

  
    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Übersicht</Typography>
              </Grid>
			  <Grid item xs={12} sm={6}>
			    <List>
                  <ListItem>
                    <ListItemText primary="Feature-ID" />
					<ListItemText primary={fidValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bezeichnung" />
					<ListItemText primary={nameNummerValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bemerkung" />
					<ListItemText primary={bemerkungValue} />
                  </ListItem>
                </List>
                <Divider />
			    <List>
                  <ListItem>
                    <ListItemText primary="Höhe" />
					<ListItemText primary={hoeheValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Gen. Höhe" />
					<ListItemText primary={genHoeheValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Gen. Lage" />
					<ListItemText primary={genLageValue} />
                  </ListItem>
                </List>
				<Divider />
			    <List>
                  <ListItem>
                    <ListItemText primary="Gemeinde" />
					<ListItemText primary={gemeindeValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Kategorie" />
					<ListItemText primary={kategorieValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Vermarkung" />
					<ListItemText primary={vermarkungValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Schutz" />
					<ListItemText primary={schutzValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Zugang" />
					<ListItemText primary={zugangValue} />
                  </ListItem>
                </List>
              </Grid>
			</>
        );
    }
}

export default SubmitForm;