import React, { Component,  } from 'react';
import { connect } from "react-redux";
//import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider} from '@material-ui/core';

//Actions
import { uploadImg, createPhoto } from "../../../../actions/uploadActions";
import { updateGasSchieberAttr, createGasSchieberKontrolle, getGasSchieberArt, getGasSchieberBeurteilung, getGasSchieberDeckel, getGasSchieberInspektion, getGasSchieberMaterial, getGasSchieberSchacht, getGasSchieberSchaltzustand, getGasSchieberStange, getGasSchieberTafel, getGasSchieberZugang, getGasDruckzone, getGasEigentuemer, getGasGemeinde, getGasStatus } from "../../../../actions/gasActions";

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import ControlOutsideForm from "./Steps/Step2";
import ControlInsideForm from "./Steps/Step3";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, onImgDrop, values, gasSchieberArt, gasSchieberBeurteilung, gasSchieberDeckel, gasSchieberInspektion, gasSchieberMaterial, gasSchieberSchacht, gasSchieberSchaltzustand, gasSchieberStange, gasSchieberTafel, gasSchieberZugang, gasDruckzone, gasEigentuemer, gasGemeinde, gasStatus}) => {
    switch (step) {
        case 0:
            return <AttributForm values={values} handleChange={handleChange} gasSchieberArt={gasSchieberArt} gasSchieberMaterial={gasSchieberMaterial} gasSchieberSchaltzustand={gasSchieberSchaltzustand} gasDruckzone={gasDruckzone} gasEigentuemer={gasEigentuemer} gasGemeinde={gasGemeinde} gasStatus={gasStatus} onImgDrop={onImgDrop}/>;
        case 1:
            return <ControlOutsideForm values={values} handleChange={handleChange} gasSchieberDeckel={gasSchieberDeckel}  gasSchieberTafel={gasSchieberTafel} gasSchieberZugang={gasSchieberZugang} />;
        case 2:
            return <ControlInsideForm values={values} handleChange={handleChange} gasSchieberBeurteilung={gasSchieberBeurteilung} gasSchieberInspektion={gasSchieberInspektion} gasSchieberSchacht={gasSchieberSchacht} gasSchieberStange={gasSchieberStange}/>;
        default:
            return <></>;
    }
}


class GasSchieberStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Stepper
  	  step: 1,
      activeStep: 0,
	  
	  //Photos
	  //photos: [],
	  selectedImg: [],

	  //Schieber
	  id: '',
	  fid: '',
	  name_nummer: '',
	  standort: '',
	  bemerkung: '',
      id_art: '',
      id_druckzone: '',
      id_schaltzustand: '',
      id_gemeinde: '',
      strasse: '',
      hausnr: '',
      id_status: '',
      id_material: '',
      id_eigentuemer: '',
      datum_erstellung: '',

      //Kontrolle
      id_zugang: '',
      zugang_bemerkung: '',
      id_tafel_status: '',
      tafel_bemerkung: '',
      id_deckel_status: '',
      deckel_beschriftung: '',
      deckel_bemerkung: '',
      id_schacht_status: '',
      schacht_niveau: '',
      schacht_bemerkung: '',
      id_stange_status: '',
      stange_niveau: '',
      stange_bemerkung: '',
      id_schieber_status: '',
      schieber_bemerkung: '',
      id_inspektion_status: '',
      inspektion_bemerkung: '',
      weg_umdrehungen: '',
      ueberdeckung: '',
      bemerkung_kontrolle: '',
	  kuerzel: ''
	}
	this.handleChange = this.handleChange.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

  componentDidMount() {
	  this.props.getGasSchieberArt(this.props.objInfo.db_schema);
	  this.props.getGasSchieberBeurteilung(this.props.objInfo.db_schema);
	  this.props.getGasSchieberDeckel(this.props.objInfo.db_schema);
	  this.props.getGasSchieberInspektion(this.props.objInfo.db_schema);
	  this.props.getGasSchieberMaterial(this.props.objInfo.db_schema);
	  this.props.getGasSchieberSchacht(this.props.objInfo.db_schema);
	  this.props.getGasSchieberSchaltzustand(this.props.objInfo.db_schema);
	  this.props.getGasSchieberStange(this.props.objInfo.db_schema);
	  this.props.getGasSchieberTafel(this.props.objInfo.db_schema);
	  this.props.getGasSchieberZugang(this.props.objInfo.db_schema);
	  this.props.getGasDruckzone(this.props.objInfo.db_schema);
	  this.props.getGasEigentuemer(this.props.objInfo.db_schema);
	  this.props.getGasGemeinde(this.props.objInfo.db_schema);
	  this.props.getGasStatus(this.props.objInfo.db_schema);
	  this.setState({ kuerzel: localStorage.getItem('gas_schieber_kuerzel') });
  }
  
  componentWillReceiveProps(nextProps) {
	  if ((nextProps.object.id !== this.props.object.id) || (nextProps.object.id !== null && this.state.id === '')) {this.setState({ id: nextProps.object.id }); }
	  if ((nextProps.object.fid !== this.props.object.fid && nextProps.object.fid !== null) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.object.fid }); }
	  if ((nextProps.object.name_nummer !== this.props.object.name_nummer && nextProps.object.name_nummer !== null) || (nextProps.object.name_nummer && this.state.name_nummer === '')) {this.setState({ name_nummer: nextProps.object.name_nummer }); }
	  if ((nextProps.object.standort !== this.props.object.standort && nextProps.object.standort !== null) || (nextProps.object.standort && this.state.standort === '')) {this.setState({ standort: nextProps.object.standort }); }
	  if ((nextProps.object.bemerkung !== this.props.object.bemerkung && nextProps.object.bemerkung !== null) || (nextProps.object.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.object.bemerkung }); }
	  if ((nextProps.object.id_material !== this.props.object.id_material && nextProps.object.id_material !== null) || (nextProps.object.id_material && this.state.id_material === '')) {this.setState({ id_material: nextProps.object.id_material }); }
	  if ((nextProps.object.id_druckzone !== this.props.object.id_druckzone && nextProps.object.id_druckzone !== null) || (nextProps.object.id_druckzone && this.state.id_druckzone === '')) {this.setState({ id_druckzone: nextProps.object.id_druckzone }); }
	  if ((nextProps.object.id_schaltzustand !== this.props.object.id_schaltzustand && nextProps.object.id_schaltzustand !== null) || (nextProps.object.id_schaltzustand && this.state.id_schaltzustand === '')) {this.setState({ id_schaltzustand: nextProps.object.id_schaltzustand }); }
	  if ((nextProps.object.id_gemeinde !== this.props.object.id_gemeinde && nextProps.object.id_gemeinde !== null) || (nextProps.object.id_gemeinde && this.state.id_gemeinde === '')) {this.setState({ id_gemeinde: nextProps.object.id_gemeinde }); }
	  if ((nextProps.object.id_art !== this.props.object.id_art && nextProps.object.id_art !== null) || (nextProps.object.id_art && this.state.id_art === '')) {this.setState({ id_art: nextProps.object.id_art }); }
	  if ((nextProps.object.id_status !== this.props.object.id_status && nextProps.object.id_status !== null) || (nextProps.object.id_status && this.state.id_status === '')) {this.setState({ id_status: nextProps.object.id_status }); }
	  if ((nextProps.object.id_eigentuemer !== this.props.object.id_eigentuemer && nextProps.object.id_eigentuemer !== null) || (nextProps.object.id_eigentuemer && this.state.id_eigentuemer === '')) {this.setState({ id_eigentuemer: nextProps.object.id_eigentuemer }); }
	  if ((nextProps.object.strasse !== this.props.object.strasse && nextProps.object.strasse !== null) || (nextProps.object.strasse && this.state.strasse === '')) {this.setState({ strasse: nextProps.object.strasse }); }
	  if ((nextProps.object.hausnr !== this.props.object.hausnr && nextProps.object.hausnr !== null) || (nextProps.object.hausnr && this.state.hausnr === '')) {this.setState({ hausnr: nextProps.object.hausnr }); }
	  if ((nextProps.object.datum_erstellung !== this.props.object.datum_erstellung && nextProps.object.datum_erstellung !== null) || (nextProps.object.datum_erstellung && this.state.datum_erstellung === '')) {this.setState({ datum_erstellung: nextProps.object.datum_erstellung }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }

  onImgDrop(photo) {
    this.setState({
      selectedImg: photo,
	  //photos: this.state.photos.concat(photo),
    });
  }

  handleNext = () => {
	  
	if (this.state.activeStep === 0) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
	}

    if (this.state.activeStep === 2) {

	  // Kontrolle
	  let CreateData = {

		db_schema: this.props.objInfo.db_schema,
        id_schieber: this.state.id,
        id_zugang: this.state.id_zugang,
        zugang_bemerkung: this.state.zugang_bemerkung,
        id_tafel_status: this.state.id_tafel_status,
        tafel_bemerkung: this.state.tafel_bemerkung,
        id_deckel_status: this.state.id_deckel_status,
        deckel_beschriftung: this.state.deckel_beschriftung,
        deckel_bemerkung: this.state.deckel_bemerkung,
        id_schacht_status: this.state.id_schacht_status,
        schacht_niveau: this.state.schacht_niveau,
        schacht_bemerkung: this.state.schacht_bemerkung,
        id_stange_status: this.state.id_stange_status,
        stange_niveau: this.state.stange_niveau,
        stange_bemerkung: this.state.stange_bemerkung,
        id_schieber_status: this.state.id_schieber_status,
        schieber_bemerkung: this.state.schieber_bemerkung,
        id_inspektion_status: this.state.id_inspektion_status,
        inspektion_bemerkung: this.state.inspektion_bemerkung,
        weg_umdrehungen: this.state.weg_umdrehungen,
        ueberdeckung: this.state.ueberdeckung,
        bemerkung_kontrolle: this.state.bemerkung_kontrolle,
		kuerzel: this.state.kuerzel
      };
	  
	  if (CreateData.id_zugang === '') {CreateData.id_zugang = null}
	  if (CreateData.id_tafel_status === '') {CreateData.id_tafel_status = null}
	  if (CreateData.id_deckel_status === '') {CreateData.id_deckel_status = null}
	  if (CreateData.id_schacht_status === '') {CreateData.id_schacht_status = null}
	  if (CreateData.id_stange_status === '') {CreateData.id_stange_status = null}
	  if (CreateData.id_schieber_status === '') {CreateData.id_schieber_status = null}
	  if (CreateData.id_inspektion_status === '') {CreateData.id_inspektion_status = null}
	  
      this.props.createGasSchieberKontrolle(CreateData);

	  this.setState({ activeStep: 0 })
	  this.props.history.push("/map");       
    } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.goBack();
  }
  

  render() {
	let buttonNext;
    if (this.state.activeStep === 2) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  gasSchieberArt={this.props.gasSchieberArt}
						  gasSchieberBeurteilung={this.props.gasSchieberBeurteilung}
						  gasSchieberDeckel={this.props.gasSchieberDeckel}
						  gasSchieberInspektion={this.props.gasSchieberInspektion}
						  gasSchieberMaterial={this.props.gasSchieberMaterial}
						  gasSchieberSchacht={this.props.gasSchieberSchacht}
						  gasSchieberSchaltzustand={this.props.gasSchieberSchaltzustand}
						  gasSchieberStange={this.props.gasSchieberStange}
						  gasSchieberTafel={this.props.gasSchieberTafel}
						  gasSchieberZugang={this.props.gasSchieberZugang}
						  gasDruckzone={this.props.gasDruckzone}
						  gasEigentuemer={this.props.gasEigentuemer}
						  gasGemeinde={this.props.gasGemeinde}
						  gasStatus={this.props.gasStatus}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object,
  gasSchieberArt: state.gas.gasSchieberArt,
  gasSchieberBeurteilung: state.gas.gasSchieberBeurteilung,
  gasSchieberDeckel: state.gas.gasSchieberDeckel,
  gasSchieberInspektion: state.gas.gasSchieberInspektion,
  gasSchieberMaterial: state.gas.gasSchieberMaterial,
  gasSchieberSchacht: state.gas.gasSchieberSchacht,
  gasSchieberSchaltzustand: state.gas.gasSchieberSchaltzustand,
  gasSchieberStange: state.gas.gasSchieberStange,
  gasSchieberTafel: state.gas.gasSchieberTafel,
  gasSchieberZugang: state.gas.gasSchieberZugang,
  gasDruckzone: state.gas.gasDruckzone,
  gasEigentuemer: state.gas.gasEigentuemer,
  gasGemeinde: state.gas.gasGemeinde,
  gasStatus: state.gas.gasStatus
});

export default connect(
  mapStateToProps,
    { uploadImg,
	  createPhoto,
	  updateGasSchieberAttr,
	  createGasSchieberKontrolle,
	  getGasSchieberArt,
	  getGasSchieberBeurteilung,
	  getGasSchieberDeckel,
	  getGasSchieberInspektion,
	  getGasSchieberMaterial,
	  getGasSchieberSchacht,
	  getGasSchieberSchaltzustand,
	  getGasSchieberStange,
	  getGasSchieberTafel,
	  getGasSchieberZugang,
	  getGasDruckzone,
	  getGasEigentuemer,
	  getGasGemeinde,
	  getGasStatus  }
)(GasSchieberStepper);