import {
  CREATE_ABW_SCHACHT_KONTROLLE,
  UPDATE_ABW_SCHACHT_ATTR,
  UPDATE_ABW_SCHACHT_ZUGANG,
  UPDATE_ABW_SCHACHT_KEK,
  GET_ABW_SCHACHT_MATERIAL,
  GET_ABW_DECKEL_MATERIAL,
  GET_ABW_SCHACHT_FUNKTION,
  GET_ABW_SCHACHT_FORM,
  GET_ABW_SCHACHT_NUTZUNG,
  GET_ABW_SCHACHT_STATUS,
  GET_ABW_SCHACHT_OBERFLAECHENZULAUF,
  GET_ABW_SCHACHT_ZUGANG,
  GET_ABW_SCHACHT_EINSTIEGSHILFE,
  GET_ABW_SCHACHT_LAGE,
  GET_ABW_SCHACHT_SCHACHTBEREICH,
  GET_ABW_SCHACHT_SCHADENCODE
} from "../actions/types";

const initialState = {
  abwSchachtZustandKontrolle: [],
  abwSchachtMaterial: [],
  abwDeckelMaterial: [],
  abwSchachtFunktion: [],
  abwSchachtForm: [],
  abwSchachtNutzung: [],
  abwSchachtStatus: [],
  abwSchachtOberflaechenzulauf: [],
  abwSchachtZugang: [],
  abwSchachtEinstiegshilfe: [],
  abwSchachtLage: [],
  abwSchachtSchachtbereich: [],
  abwSchachtSchadencode: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_ABW_SCHACHT_KONTROLLE:
      return {
        ...state,
		abwSchachtKontrolle: action.payload
      };
    case UPDATE_ABW_SCHACHT_ATTR:
      return {
        ...state,
		schacht: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_ABW_SCHACHT_ZUGANG:
      return {
        ...state,
		schacht: action.payload,
        objects: [action.payload, ...state.objects]
      };
	case UPDATE_ABW_SCHACHT_KEK:
      return {
        ...state,
		schacht: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case GET_ABW_SCHACHT_MATERIAL:
      return {
        ...state,
        abwSchachtMaterial: action.payload,
      };
    case GET_ABW_DECKEL_MATERIAL:
      return {
        ...state,
        abwDeckelMaterial: action.payload,
      };
    case GET_ABW_SCHACHT_FUNKTION:
      return {
        ...state,
        abwSchachtFunktion: action.payload,
      };
	case GET_ABW_SCHACHT_FORM:
      return {
        ...state,
        abwSchachtForm: action.payload,
      };
    case GET_ABW_SCHACHT_NUTZUNG:
      return {
        ...state,
        abwSchachtNutzung: action.payload,
      };
	case GET_ABW_SCHACHT_STATUS:
      return {
        ...state,
        abwSchachtStatus: action.payload,
      };
	case GET_ABW_SCHACHT_OBERFLAECHENZULAUF:
      return {
        ...state,
        abwSchachtOberflaechenzulauf: action.payload,
      };
	case GET_ABW_SCHACHT_ZUGANG:
      return {
        ...state,
        abwSchachtZugang: action.payload,
      };
	case GET_ABW_SCHACHT_EINSTIEGSHILFE:
      return {
        ...state,
        abwSchachtEinstiegshilfe: action.payload,
      };
	case GET_ABW_SCHACHT_LAGE:
      return {
        ...state,
        abwSchachtLage: action.payload,
      };
	case GET_ABW_SCHACHT_SCHACHTBEREICH:
      return {
        ...state,
        abwSchachtSchachtbereich: action.payload,
      };
	case GET_ABW_SCHACHT_SCHADENCODE:
      return {
        ...state,
        abwSchachtSchadencode: action.payload,
      };
    default:
      return state;
  }
}