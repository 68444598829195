import React, { Component,  } from 'react';
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import equal from 'fast-deep-equal'
//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider, Backdrop, Dialog, DialogTitle, Divider } from '@material-ui/core';
import Resizer from 'react-image-file-resizer';

//Actions
import { uploadImg, setUploadImgLoading, createPhoto } from "../../../../actions/uploadActions";
import { updateElektroSchachtAttr, createElektroSchachtKontrolle, getElektroSchachtEigentuemer, getElektroSchachtLagebestimmung, getElektroSchachtTyp, getElektroSchachtZugang, getElektroRohrTyp } from "../../../../actions/ewActions";

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import Side1Form from "./Steps/Step2";
import Side2Form from "./Steps/Step3";
import Side3Form from "./Steps/Step4";
import Side4Form from "./Steps/Step5";
import SubmitForm from "./Steps/Step6";

import SpinnerOverlay from "../../../layout/Spinner/SpinnerOverlay";
//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 
const StepContent = ({ step , handleChange, handleArrayChange, handleSizeChange, onImgDrop, values, ewSchachtEigentuemer, ewSchachtLagebestimmung, ewSchachtTyp, ewSchachtZugang, ewRohrTyp}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} values={values} ewSchachtEigentuemer={ewSchachtEigentuemer} ewSchachtLagebestimmung={ewSchachtLagebestimmung} ewSchachtTyp={ewSchachtTyp} ewSchachtZugang={ewSchachtZugang} onImgDrop={onImgDrop}/>;
        case 1:
            return <Side1Form values={values} handleChange={handleChange} handleSizeChange={handleSizeChange} handleArrayChange={handleArrayChange} ewRohrTyp={ewRohrTyp} onImgDrop={onImgDrop} />;
        case 2:
            return <Side2Form values={values} handleChange={handleChange} handleSizeChange={handleSizeChange} handleArrayChange={handleArrayChange} ewRohrTyp={ewRohrTyp} onImgDrop={onImgDrop} />;
        case 3:
            return <Side3Form values={values} handleChange={handleChange} handleSizeChange={handleSizeChange} handleArrayChange={handleArrayChange} ewRohrTyp={ewRohrTyp} onImgDrop={onImgDrop} />;
        case 4:
            return <Side4Form values={values} handleChange={handleChange} handleSizeChange={handleSizeChange} handleArrayChange={handleArrayChange} ewRohrTyp={ewRohrTyp} onImgDrop={onImgDrop} />;
        case 5:
            return <SubmitForm values={values} />;
        default:
            return <></>;
    }
}


const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 2000, 2000, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    },
    'file'
    );
});

const onImageChange = async (event) => {
    try {
        const file = event.target.files[0];
        const image = await resizeFile(file);
        console.log(image);
    } catch(err) {
        console.log(err);
    }

}

class EwSchachtStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Stepper
  	  step: 1,
      activeStep: 0,
	  
	  //Photos
	  photos: [],
	  selectedImg: [],

	  //Schacht
	  id: '',
	  fid: '',
	  name_nummer: '',
	  standort: '',
	  bemerkung: '',
      id_lagebestimmung: '',
	  id_zugang: '',
	  id_typ: '',
	  ueberdeckung: '',
	  dimension_1: '',
	  dimension_2: '',
	  baujahr: '',
	  id_eigentuemer: '',
	  // Rohr-Blöcke
	  seite1: [],
	  seite1_row: 0,
	  seite1_col: 0,
	  seite1_bemerkung: '',
	  seite2: [],
	  seite2_row: 0,
	  seite2_col: 0,
	  seite2_bemerkung: '',
	  seite3: [],
	  seite3_row: 0,
	  seite3_col: 0,
	  seite3_bemerkung: '',
	  seite4: [],
	  seite4_row: 0,
	  seite4_col: 0,
	  seite4_bemerkung: ''

	}
	this.handleChange = this.handleChange.bind(this);
	this.handleArrayChange = this.handleArrayChange.bind(this);
	this.handleSizeChange = this.handleSizeChange.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

  componentDidMount() {
	  this.props.getElektroSchachtEigentuemer(this.props.objInfo.db_schema);
	  this.props.getElektroSchachtLagebestimmung(this.props.objInfo.db_schema);
	  this.props.getElektroSchachtTyp(this.props.objInfo.db_schema);
	  this.props.getElektroSchachtZugang(this.props.objInfo.db_schema);
	  this.props.getElektroRohrTyp(this.props.objInfo.db_schema);
  }
  

  
  componentWillReceiveProps(nextProps) {
	  if ((nextProps.object.id !== this.props.object.id) || (nextProps.object.id !== null && this.state.id === '')) {this.setState({ id: nextProps.object.id }); }
	  if ((nextProps.object.fid !== this.props.object.fid && nextProps.object.fid !== null) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.object.fid }); }
	  if ((nextProps.object.name_nummer !== this.props.object.name_nummer && nextProps.object.name_nummer !== null) || (nextProps.object.name_nummer && this.state.name_nummer === '')) {this.setState({ name_nummer: nextProps.object.name_nummer }); }
	  if ((nextProps.object.standort !== this.props.object.standort && nextProps.object.standort !== null) || (nextProps.object.standort && this.state.standort === '')) {this.setState({ standort: nextProps.object.standort }); }
	  if ((nextProps.object.bemerkung !== this.props.object.bemerkung && nextProps.object.bemerkung !== null) || (nextProps.object.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.object.bemerkung }); }
	  if ((nextProps.object.id_lagebestimmung !== this.props.object.id_lagebestimmung && nextProps.object.id_lagebestimmung !== null) || (nextProps.object.id_lagebestimmung && this.state.id_lagebestimmung === '')) {this.setState({ id_lagebestimmung: nextProps.object.id_lagebestimmung }); }
	  if ((nextProps.object.id_zugang !== this.props.object.id_zugang && nextProps.object.id_zugang !== null) || (nextProps.object.id_zugang && this.state.id_zugang === '')) {this.setState({ id_zugang: nextProps.object.id_zugang }); }
	  if ((nextProps.object.id_typ !== this.props.object.id_typ && nextProps.object.id_typ !== null) || (nextProps.object.id_typ && this.state.id_typ === '')) {this.setState({ id_typ: nextProps.object.id_typ }); }
	  if ((nextProps.object.ueberdeckung !== this.props.object.ueberdeckung && nextProps.object.ueberdeckung !== null) || (nextProps.object.ueberdeckung && this.state.ueberdeckung === '')) {this.setState({ ueberdeckung: nextProps.object.ueberdeckung }); }
	  if ((nextProps.object.dimension_1 !== this.props.object.dimension_1 && nextProps.object.dimension_1 !== null) || (nextProps.object.dimension_1 && this.state.dimension_1 === '')) {this.setState({ dimension_1: nextProps.object.dimension_1 }); }
	  if ((nextProps.object.dimension_2 !== this.props.object.dimension_2 && nextProps.object.dimension_2 !== null) || (nextProps.object.dimension_2 && this.state.dimension_2 === '')) {this.setState({ dimension_2: nextProps.object.dimension_2 }); }
	  if ((nextProps.object.baujahr !== this.props.object.baujahr && nextProps.object.baujahr !== null) || (nextProps.object.baujahr && this.state.baujahr === '')) {this.setState({ baujahr: nextProps.object.baujahr }); }
	  if ((nextProps.object.id_eigentuemer !== this.props.object.id_eigentuemer && nextProps.object.id_eigentuemer !== null) || (nextProps.object.id_eigentuemer && this.state.id_eigentuemer === '')) {this.setState({ id_eigentuemer: nextProps.object.id_eigentuemer }); }
	  // Rohr-Blöcke
      if ((!equal(this.props.object.seite1, nextProps.object.seite1) && (nextProps.object.seite1)) || (this.state.seite1 && nextProps.object.seite1 && this.state.seite1.length === 0)) {this.setState({ seite1: nextProps.object.seite1 }); }
	  if ((nextProps.object.seite1_col !== this.props.object.seite1_col && nextProps.object.seite1_col !== null) || (nextProps.object.seite1_col && this.state.seite1_col === '')) {this.setState({ seite1_col: nextProps.object.seite1_col }); }
	  if ((nextProps.object.seite1_row !== this.props.object.seite1_row && nextProps.object.seite1_row !== null) || (nextProps.object.seite1_row && this.state.seite1_row === '')) {this.setState({ seite1_row: nextProps.object.seite1_row }); }
	  if ((nextProps.object.seite1_bemerkung !== this.props.object.seite1_bemerkung && nextProps.object.seite1_bemerkung !== null) || (nextProps.object.seite1_bemerkung && this.state.seite1_bemerkung === '')) {this.setState({ seite1_bemerkung: nextProps.object.seite1_bemerkung }); }
      if ((!equal(this.props.object.seite2, nextProps.object.seite2) && (nextProps.object.seite2)) || (this.state.seite2 && nextProps.object.seite2 && this.state.seite2.length === 0)) {this.setState({ seite2: nextProps.object.seite2 }); }
	  if ((nextProps.object.seite2_col !== this.props.object.seite2_col && nextProps.object.seite2_col !== null) || (nextProps.object.seite2_col && this.state.seite2_col === '')) {this.setState({ seite2_col: nextProps.object.seite2_col }); }
	  if ((nextProps.object.seite2_row !== this.props.object.seite2_row && nextProps.object.seite2_row !== null) || (nextProps.object.seite2_row && this.state.seite2_row === '')) {this.setState({ seite2_row: nextProps.object.seite2_row }); }
	  if ((nextProps.object.seite2_bemerkung !== this.props.object.seite2_bemerkung && nextProps.object.seite2_bemerkung !== null) || (nextProps.object.seite2_bemerkung && this.state.seite2_bemerkung === '')) {this.setState({ seite2_bemerkung: nextProps.object.seite2_bemerkung }); }
      if ((!equal(this.props.object.seite3, nextProps.object.seite3) && (nextProps.object.seite3)) || (this.state.seite3 && nextProps.object.seite3 && this.state.seite3.length === 0)) {this.setState({ seite3: nextProps.object.seite3 }); }
	  if ((nextProps.object.seite3_col !== this.props.object.seite3_col && nextProps.object.seite3_col !== null) || (nextProps.object.seite3_col && this.state.seite3_col === '')) {this.setState({ seite3_col: nextProps.object.seite3_col }); }
	  if ((nextProps.object.seite3_row !== this.props.object.seite3_row && nextProps.object.seite3_row !== null) || (nextProps.object.seite3_row && this.state.seite3_row === '')) {this.setState({ seite3_row: nextProps.object.seite3_row }); }
	  if ((nextProps.object.seite3_bemerkung !== this.props.object.seite3_bemerkung && nextProps.object.seite3_bemerkung !== null) || (nextProps.object.seite3_bemerkung && this.state.seite3_bemerkung === '')) {this.setState({ seite3_bemerkung: nextProps.object.seite3_bemerkung }); }
      if ((!equal(this.props.object.seite4, nextProps.object.seite4) && (nextProps.object.seite4)) || (this.state.seite4 && nextProps.object.seite4 && this.state.seite4.length === 0)) {this.setState({ seite4: nextProps.object.seite4 }); }
	  if ((nextProps.object.seite4_col !== this.props.object.seite4_col && nextProps.object.seite4_col !== null) || (nextProps.object.seite4_col && this.state.seite4_col === '')) {this.setState({ seite4_col: nextProps.object.seite4_col }); }
	  if ((nextProps.object.seite4_row !== this.props.object.seite4_row && nextProps.object.seite4_row !== null) || (nextProps.object.seite4_row && this.state.seite4_row === '')) {this.setState({ seite4_row: nextProps.object.seite4_row }); }
	  if ((nextProps.object.seite4_bemerkung !== this.props.object.seite4_bemerkung && nextProps.object.seite4_bemerkung !== null) || (nextProps.object.seite4_bemerkung && this.state.seite4_bemerkung === '')) {this.setState({ seite4_bemerkung: nextProps.object.seite4_bemerkung }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }

  handleArrayChange = (seiteNr, index) => event => {
	let sideName = 'seite' + [seiteNr]
    let rohre = this.state[sideName];
    const newRohr = rohre.slice(0); 
    newRohr[index][event.target.name] = event.target.value;
	// Set Druchmesser von Rohr
	if (event.target.value === 11) {
		newRohr[index].dm = 0;
	} else if ([1, 2, 6, 7].indexOf(event.target.value) >= 0) {
        newRohr[index].dm = 14;
	} else if ([3, 4, 8, 9].indexOf(event.target.value) >= 0) {
        newRohr[index].dm = 16;
	} else if ([5, 10].indexOf(event.target.value) >= 0) {
        newRohr[index].dm = 18;
	}
	// Set Farbe von Rohr
	if ([1, 2, 3, 4, 5].indexOf(event.target.value) >= 0) {
        newRohr[index].color = '#80d0f4';
	} else if ([6, 7, 8, 9, 10].indexOf(event.target.value) >= 0) {
        newRohr[index].color = '#ff9999';
	}

    this.setState({ [sideName]: newRohr });
  }
  
  
  handleSizeChange = seiteNr => event => {
    const {name, value} = event.target
	let nameSide = 'seite' + [seiteNr];
	let actRohre = this.state[nameSide];
	let lenSide = this.state[nameSide].length;
	
	let newSize;
	let actCol;
	let actRow;
	let actRohreCoord;
	
	let newRohr;
	let newCoord;
	let newCoords = [];
	let newRohre = [];
	let newRohreCoords = [];
    // Aktuelle Werte für Zeile und Kolone
    if (name.endsWith("row")) {
	  let nameAttr = 'seite' + [seiteNr] + '_col';
	  newSize = this.state[nameAttr] * value;
	  actCol = this.state[nameAttr];
	  actRow = value;
	} else {
	  let nameAttr = 'seite' + [seiteNr] + '_row';
	  newSize = this.state[nameAttr] * value;
	  actRow = this.state[nameAttr];
	  actCol = value;
	  
	};
    // Berechnung der Koordinaten
	for (let i=0; i < actRow; i++) {
		let stepRow = 160 / (Number(actRow)+1);
		let YRow = 160 - stepRow * (i+1);
		for (let j=0; j < actCol; j++) {
			let stepCol = 320 / (Number(actCol)+1);
			let XCol = stepCol * (j+1);
			newCoord = { x: XCol, y: YRow};
			newCoords.push( newCoord );
		}
	}
    // Zusammensetzen neuer Array für Rohre
	if (lenSide < newSize) {	
		for (let i=lenSide; i < newSize; i++) {
			newRohr = { nummer: i+1, id_typ: '', dm: 16, color: 'white'}
			actRohre.push( newRohr );
		}
		for (let j=0; j < actRohre.length; j++) {
			actRohreCoord = {nummer: actRohre[j].nummer, id_typ: actRohre[j].id_typ, dm: actRohre[j].dm, color: actRohre[j].color, x: newCoords[j].x, y: newCoords[j].y}
			newRohreCoords.push( actRohreCoord );
		}
		this.setState({
            [nameSide]: newRohreCoords
        })
	} else if (lenSide > newSize) {	
		let lenDiff = lenSide - newSize;
		let delRohre = this.state[nameSide];
		delRohre.splice(newSize, lenDiff)
		for (let j=0; j < delRohre.length; j++) {
			actRohreCoord = {nummer: delRohre[j].nummer, id_typ: delRohre[j].id_typ, dm: delRohre[j].dm, color: delRohre[j].color, x: newCoords[j].x, y: newCoords[j].y}
			newRohreCoords.push( actRohreCoord );
		}	
	    this.setState({
            [nameSide]: newRohreCoords
        })
    };

    this.setState({
      [name]: value
    })
  };
  
  onImgDrop(picture) {
//    let resize = [];
//    for (let i=0; i < picture.length; i++) {
//            resizeFile(picture[i])
//    		        .then(val => {
//				resize.push( val );
//            });;
//    }
	this.setState({selectedImg: picture});
  }

  handleNext = () => {
    if (this.state.activeStep === 5) {	
      // Anlage
	  let EditData = {
		db_schema: this.props.objInfo.db_schema,
		id: this.state.id,
	    fid: this.state.fid,
	    name_nummer: this.state.name_nummer,
	    standort: this.state.standort,
	    bemerkung: this.state.bemerkung,
	    id_lagebestimmung: this.state.id_lagebestimmung,
		id_zugang: this.state.id_zugang,
		id_typ: this.state.id_typ,
		ueberdeckung: this.state.ueberdeckung,
		dimension_1: this.state.dimension_1,
		dimension_2: this.state.dimension_2,
		baujahr: this.state.baujahr,
		id_eigentuemer: this.state.id_eigentuemer,
	    seite1: this.state.seite1,
	    seite1_row: this.state.seite1_row,
	    seite1_col: this.state.seite1_col,
	    seite1_bemerkung: this.state.seite1_bemerkung,
	    seite2: this.state.seite2,
	    seite2_row: this.state.seite2_row,
	    seite2_col: this.state.seite2_col,
	    seite2_bemerkung: this.state.seite2_bemerkung,
	    seite3: this.state.seite3,
	    seite3_row: this.state.seite3_row,
	    seite3_col: this.state.seite3_col,
	    seite3_bemerkung: this.state.seite3_bemerkung,
	    seite4: this.state.seite4,
	    seite4_row: this.state.seite4_row,
	    seite4_col: this.state.seite4_col,
	    seite4_bemerkung: this.state.seite4_bemerkung
      };
	  
	  if (EditData.id_lagebestimmung === '') {EditData.id_lagebestimmung = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  if (EditData.id_typ === '') {EditData.id_typ = null}
	  if (EditData.ueberdeckung === '') {EditData.ueberdeckung = null}
	  if (EditData.dimension_1 === '') {EditData.dimension_1 = null}
	  if (EditData.dimension_2 === '') {EditData.dimension_2 = null}
	  if (EditData.baujahr === '') {EditData.baujahr = null}
	  if (EditData.id_eigentuemer === '') {EditData.id_eigentuemer = null}
	  if (EditData.seite1_row === '') {EditData.seite1_row = null}
	  if (EditData.seite1_col === '') {EditData.seite1_col = null}
	  if (EditData.seite2_row === '') {EditData.seite2_row = null}
	  if (EditData.seite2_col === '') {EditData.seite2_col = null}
	  if (EditData.seite3_row === '') {EditData.seite3_row = null}
	  if (EditData.seite3_col === '') {EditData.seite3_col = null}
	  if (EditData.seite4_row === '') {EditData.seite4_row = null}
	  if (EditData.seite4_row === '') {EditData.seite4_row = null}
	  
      this.props.updateElektroSchachtAttr(EditData);  
	  
	  this.setState({ activeStep: 0 })
	  this.props.history.push("/map");       
    } else {
		
// Upload Fotos		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
		 this.props.setUploadImgLoading()
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
//	    imageFormObj.append('nummer', nr)
//		imageFormObj.append('date', newDate)
		// Verkleinern des Bildes
//        resizeFile(this.state.selectedImg[i])
//		  .then(val => {
//			 imageFormObj.append('file', val);
//			 this.props.uploadImg(imageFormObj);
//          });;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }
        this.setState({ activeStep: this.state.activeStep + 1, photos: this.state.photos.concat(this.state.selectedImg) });
		this.setState({ selectedImg: [] });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.goBack();
  }
  

  render() {
	  const { uploadLoading } = this.props.uploads;

	//Button
	let buttonNext;
    if (this.state.activeStep === 5) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}
	
// Overlay bei Photo-Upload	
	let photoIsUploading = (
        <>
	      <Dialog
		     disableBackdropClick
             disableEscapeKeyDown
             open={uploadLoading}
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Fotos werden Hochgeladen</DialogTitle>
			<Divider />
            <SpinnerOverlay />
          </Dialog>
        </>
      );
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3, 4, 5, 6].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
			{photoIsUploading}
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleArrayChange={this.handleArrayChange}
						  handleSizeChange={this.handleSizeChange}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  ewSchachtEigentuemer={this.props.ewSchachtEigentuemer}
						  ewSchachtLagebestimmung={this.props.ewSchachtLagebestimmung}
						  ewSchachtTyp={this.props.ewSchachtTyp}
						  ewSchachtZugang={this.props.ewSchachtZugang}
						  ewRohrTyp={this.props.ewRohrTyp}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object,
  uploads: state.uploads,
  ewSchachtEigentuemer: state.ew.ewSchachtEigentuemer,
  ewSchachtLagebestimmung: state.ew.ewSchachtLagebestimmung,
  ewSchachtTyp: state.ew.ewSchachtTyp,
  ewSchachtZugang: state.ew.ewSchachtZugang,
  ewRohrTyp: state.ew.ewRohrTyp
});

export default connect(
  mapStateToProps,
    { uploadImg,
	  setUploadImgLoading,
	  createPhoto,
      createElektroSchachtKontrolle,
      updateElektroSchachtAttr,
      getElektroSchachtEigentuemer,
      getElektroSchachtLagebestimmung,
      getElektroSchachtTyp,
      getElektroSchachtZugang,
	  getElektroRohrTyp

	  }
)(EwSchachtStepper);