import React, { Component } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider} from "@material-ui/core";

import './Step.scss';

class SubmitForm extends Component {

render() {
	let photos = this.props.values.photos;
	let values = this.props.values
 
    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Übersicht</Typography>
              </Grid>
			  <Grid item xs={12} sm={6}>
			    <List>
                  <ListItem>
                    <ListItemText primary="Bezeichnung" />
					<ListItemText primary={values.name_nummer} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Seite 1" />
					<ListItemText primary={values.seite1_row + ' x ' + values.seite1_col} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Seite 2" />
					<ListItemText primary={values.seite2_row + ' x ' + values.seite2_col} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Seite 3" />
					<ListItemText primary={values.seite3_row + ' x ' + values.seite3_col} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Seite 4" />
					<ListItemText primary={values.seite4_row + ' x ' + values.seite4_col} />
                  </ListItem>
                </List>
                <Divider />
              </Grid>
			  <Grid item xs={12}>
			  	<Typography variant="h6">Alle Fotos</Typography>
			  </Grid>
			  <Grid container spacing={1} item xs={12}  direction="row" id={'P4'} key={'P4'}>
			  {photos.map((x, index) => (
			  	<Grid key={'photo' + index} className="photo-grid" item xs={12} sm={6} md={4}>
			  		<img src={URL.createObjectURL(this.props.values.photos[index])} className="photo" alt={'preview' + index} />
			  	</Grid>
			  ))}
			  </Grid>
			</>
        );
    }
}

export default SubmitForm;