import axios from "axios";

import {
  CREATE_EW_BELEUCHTUNG_KONTROLLE,
  CREATE_EW_SCHACHT_KONTROLLE,
  CREATE_EW_STATION_KONTROLLE,
  CREATE_EW_KABINE_KONTROLLE,
  UPDATE_EW_BELEUCHTUNG_ATTR,
  UPDATE_EW_SCHACHT_ATTR,
  UPDATE_EW_STATION_ATTR,
  UPDATE_EW_KABINE_ATTR,
  GET_EW_SCHACHT_EIGENTUEMER,
  GET_EW_SCHACHT_LAGEBESTIMMUNG,
  GET_EW_SCHACHT_TYP,
  GET_EW_SCHACHT_ZUGANG,
  GET_EW_ROHR_TYP
} from "./types";

// Create Beleuchtung Kontrolle
export const createElektroBeleuchtungKontrolle = beleuchtungData => dispatch => {
  axios
    .post("/api/ew/beleuchtung/create/kontrolle", beleuchtungData)
    .then(res =>
      dispatch({
        type: CREATE_EW_BELEUCHTUNG_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Schacht Kontrolle
export const createElektroSchachtKontrolle = schachtData => dispatch => {
  axios
    .post("/api/ew/schacht/create/kontrolle", schachtData)
    .then(res =>
      dispatch({
        type: CREATE_EW_SCHACHT_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Station Kontrolle
export const createElektroStationKontrolle = stationData => dispatch => {
  axios
    .post("/api/ew/station/create/kontrolle", stationData)
    .then(res =>
      dispatch({
        type: CREATE_EW_STATION_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Kabine Kontrolle
export const createElektroKabineKontrolle = kabineData => dispatch => {
  axios
    .post("/api/ew/kabine/create/kontrolle", kabineData)
    .then(res =>
      dispatch({
        type: CREATE_EW_KABINE_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Beleuchtung Attribute
export const updateElektroBeleuchtungAttr = beleuchtungData => dispatch => {
  axios
    .patch("/api/ew/beleuchtung/update/attr", beleuchtungData)
    .then(res =>
      dispatch({
        type: UPDATE_EW_BELEUCHTUNG_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht Attribute
export const updateElektroSchachtAttr = schachtData => dispatch => {
  axios
    .patch("/api/ew/schacht/update/attr", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_EW_SCHACHT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Station Attribute
export const updateElektroStationAttr = stationData => dispatch => {
  axios
    .patch("/api/ew/station/update/attr", stationData)
    .then(res =>
      dispatch({
        type: UPDATE_EW_STATION_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Kabine Attribute
export const updateElektroKabineAttr = kabineData => dispatch => {
  axios
    .patch("/api/ew/kabine/update/attr", kabineData)
    .then(res =>
      dispatch({
        type: UPDATE_EW_KABINE_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// TBDs
//Schacht Eigentuemer
export const getElektroSchachtEigentuemer = (db_schema) => dispatch => {
  axios
    .get(`/api/ew/schacht/eigentuemer/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_EW_SCHACHT_EIGENTUEMER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_EW_SCHACHT_EIGENTUEMER,
        payload: null
      })
    );
};

//Schacht Lagebestimmung
export const getElektroSchachtLagebestimmung = (db_schema) => dispatch => {
  axios
    .get(`/api/ew/schacht/lagebestimmung/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_EW_SCHACHT_LAGEBESTIMMUNG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_EW_SCHACHT_LAGEBESTIMMUNG,
        payload: null
      })
    );
};

//Schacht Typ
export const getElektroSchachtTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/ew/schacht/typ/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_EW_SCHACHT_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_EW_SCHACHT_TYP,
        payload: null
      })
    );
};

//Schacht Typ
export const getElektroSchachtZugang = (db_schema) => dispatch => {
  axios
    .get(`/api/ew/schacht/zugang/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_EW_SCHACHT_ZUGANG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_EW_SCHACHT_ZUGANG,
        payload: null
      })
    );
};

//Rohr Typ
export const getElektroRohrTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/ew/schacht/rohrtyp/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_EW_ROHR_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_EW_ROHR_TYP,
        payload: null
      })
    );
};