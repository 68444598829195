import React, { Component } from "react";
import { connect } from "react-redux";
// Style
import "./MainContent.scss";
import "./Dashboard.scss";
// Material UI
import { AppBar, Toolbar, Typography, Grid, GridList, GridListTile,  Container, Paper, Box, StepLabel, StylesProvider} from "@material-ui/core";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
// Actions
import { getProjectOfUser } from "../../actions/projectActions";
//IMG
import logo from "../../img/logo_mobile.png";
//Icons
import { DonutLarge, Waves, Photo, Nature, FullscreenExit, Opacity, FlashOn, HelpOutline, Healing, LocalFlorist, Build, CheckCircle, TripOrigin, Map, List } from '@material-ui/icons';

import { ReactComponent as Valve } from '../../img/valve.svg';
import { ReactComponent as WaterPump } from '../../img/water-pump.svg';
import { ReactComponent as WaterTower } from '../../img/water-tower.svg';
import { ReactComponent as ValveGas } from '../../img/valve-gas.svg';
import { ReactComponent as Manhole } from '../../img/manhole.svg';
import { ReactComponent as Hydrant } from '../../img/hydrant.svg';
import { ReactComponent as EWStation } from '../../img/electricity-station.svg';
import { ReactComponent as EWCabine } from '../../img/electricity-cabine.svg';
import { ReactComponent as StreetLight } from '../../img/street-light.svg';
import { ReactComponent as WaterFacility } from '../../img/water-facility.svg';
import { ReactComponent as WaterSeepage } from '../../img/water-seepage.svg';


const icons = {
    1: <Manhole />,
    2: <WaterSeepage />,
    3: <FullscreenExit />,
	4: <EWStation />,
	5: <EWCabine />,
	6: <StreetLight />,
	7: <Manhole />,
	8: <Hydrant />,
	9: <Valve />,
	10: <WaterTower />,
	11: <WaterFacility />,
	12: <Healing />,
	13: <Valve />,
	14: <Valve />,
	15: <ValveGas />,
	16: <Valve />,
	17: <Manhole />,
	18: <Manhole />,
	19: <Manhole />,
	20: <Manhole />,
	
};


class Dashboard extends Component {
	
  handleMapClick = param => e => {
  //  e.stopPropagation()
  console.log(param)
	this.props.getProjectOfUser(param.user_id, param.project_id, param.object_id)
	this.props.history.push("/map")
  }
  
  handleTableClick = param => e => {
  //  e.stopPropagation()
	this.props.getProjectOfUser(param.user_id, param.project_id, param.object_id)
	this.props.history.push("/table")
  }

  render() {
	const { projects, projectsLoading } = this.props.projects;

	let projectData = projects.map(project => (
		<GridListTile cols={1} key={project.rownr}>
		  <StylesProvider injectFirst>
            <div key={project.rownr} className="object-panel" >
			  <div className="info-container" onClick={this.handleMapClick(project)} >
                <div className="object-icon">{icons[project.object_id]}</div>
			    <div className="object-name">{project.object_name}</div>
			    <div className="project-name">{project.project_name}</div>
			  </div>
            </div>
		  </StylesProvider>
		</GridListTile>
    ));
	
    let content;


    if (projects.length > 0) {
      // At least one project
      content = (
        <>
		  <GridList cellHeight={220} cols={0} >
            {projectData}
		  </GridList>
        </>
      );
    } else {
      // No projects
      content = (
        <>
          <div className="projects">
            <div className="no-projects">
              <h1 className="header">Keine Objekte zugewiesen</h1>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="main-content">
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>Projekte</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
		  <Box component="main" className="wf-box-wrapper">
            <Container maxWidth="md" className="wf-container">
              <Paper elevation={5}>
		        {content}
              </Paper>
            </Container>
          </Box>
	  </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects
});

export default connect(
  mapStateToProps,
  {getProjectOfUser}
)(Dashboard);