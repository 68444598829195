import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, Checkbox, FormGroup, FormControlLabel, FormLabel, RadioGroup, Radio, StylesProvider, Divider} from "@material-ui/core";

import './Step.scss';

class ControlInsideForm extends Component {
  
render() {
    return (
	  <>
        <Grid item xs={12}>
          <Typography variant="h6">Kontrolle Schieber</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'id_schacht_status' + this.props.values.id}
            select
            label="Schacht Status"
		    name="id_schacht_status"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_schacht_status}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberSchacht.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Schacht Niveau"
                name="schacht_niveau"
                variant="outlined"
                fullWidth
				value={this.props.values.schacht_niveau}
                onChange={this.props.handleChange}
				id={'schacht_niveau' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Schacht Bemerkung"
                name="schacht_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.schacht_bemerkung}
                onChange={this.props.handleChange}
				id={'schacht_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
        <Grid item xs={12} sm={6}>
          <TextField
            id={'id_stange_status' + this.props.values.id}
            select
            label="Stange Status"
		    name="id_stange_status"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_stange_status}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberStange.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Stange Niveau"
                name="stange_niveau"
                variant="outlined"
                fullWidth
				value={this.props.values.stange_niveau}
                onChange={this.props.handleChange}
				id={'stange_niveau' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Stange Bemerkung"
                name="stange_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.stange_bemerkung}
                onChange={this.props.handleChange}
				id={'stange_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
        <Grid item xs={12} sm={6}>
          <TextField
            id={'id_schieber_status' + this.props.values.id}
            select
            label="Schieber Status"
		    name="id_schieber_status"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_schieber_status}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberBeurteilung.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Schieber Bemerkung"
                name="schieber_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.schieber_bemerkung}
                onChange={this.props.handleChange}
				id={'schieber_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
        <Grid item xs={12} sm={6}>
          <TextField
            id={'id_inspektion_status' + this.props.values.id}
            select
            label="Inspektion Status"
		    name="id_inspektion_status"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_inspektion_status}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberInspektion.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Kürzel"
                name="kuerzel"
                variant="outlined"
                fullWidth
				value={this.props.values.kuerzel}
                onChange={this.props.handleChange}
				id={'kuerzel' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Inspektion Bemerkung"
                name="inspektion_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.inspektion_bemerkung}
                onChange={this.props.handleChange}
				id={'inspektion_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
    </>
	)
}
}

export default ControlInsideForm;
