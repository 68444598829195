import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Dialog, DialogTitle, DialogActions, Divider, Button, FormGroup, FormControlLabel, Checkbox, Box } from "@material-ui/core";
import { DoubleArrow } from '@material-ui/icons';

import './Sheet.scss';

class AVEinzelobjektSheet extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  isDialogControlOpen: false,
	  control: {}
	}
  }
  
  
  handleDialogControlOpen = (kontr) => {
	let control = this.props.data.kontrolle.find(item=>item.id === kontr.id);
    this.setState({
      control: control,
      isDialogControlOpen: true
    });
  }
	
  handleDialogControlClose = () => {
    this.setState({
      isDialogControlOpen: false
    });
  }
  
  render() {
	let objectList1;
	let objectList2;
	let photoList;
	
	if(typeof this.props.data !== "undefined" && typeof this.props.objInfo !== "undefined"){
	  const data = this.props.data;
	  const photos = this.props.data.photos;
	  // Attribute
	  let erledigtValue;
	  let unterirdischValue;
	  let unterstandValue;
	  let gebDetailValue;

	  if (data.erledigt === true) {erledigtValue = 'Ja'} else {erledigtValue = 'Nein'}
	  if (data.ist_unterirdisch === true) {unterirdischValue = 'Ja'} else {unterirdischValue = 'Nein'}
	  if (data.ist_unterstand === true) {unterstandValue = 'Ja'} else {unterstandValue = 'Nein'}
	  if (data.ist_gebaeudedetail === true) {gebDetailValue = 'Ja'} else {gebDetailValue = 'Nein'}

	  let dataJSON1 = {
	    ID: data.id,
		Bezeichnung: data.name_nummer,
		Standort: data.standort,
		Erledigt: erledigtValue,
	  };
	  
	  let dataJSON2 = {
		Unterirdisch: unterirdischValue,
		Unterstand: unterstandValue,
		Gebäudedetail: gebDetailValue,
		Bemerkung: data.bemerkung,
	  };

	  
	  objectList1 = Object.keys(dataJSON1).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON1[key]} />
                </ListItem>
      );
	  objectList2 = Object.keys(dataJSON2).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON2[key]} />
                </ListItem>
      );

      // Photos
	  if (typeof photos !== "undefined" && photos !== null && photos.length > 0) {
		  photoList =  photos.map(photo => (
             <Grid key={photo.upload_at} className="photo-grid" item xs={12} sm={6} md={4}>
			   <img src={photo.pfad + '/' + photo.name} className="photo" alt={photo.name_orig} />
			 </Grid>
		  ));
	  }
	}
	  
	return (
        <>
          <Grid className="object-container" container spacing={2}>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Attribute
              </Typography>
              <Grid className="item-container" container spacing={2}>
			    <Grid item xs={12} sm={6} md={6}>
                  <List dense={true}>
            	  	{objectList1}
                  </List>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
                  <List dense={true}>
            	  	{objectList2}
                  </List>
				</Grid>
              </Grid>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Fotos
              </Typography>
              <Grid className="photo-container" container spacing={1}>
                {photoList}
              </Grid>
            </Grid>
          </Grid>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
    {  }
)(AVEinzelobjektSheet);