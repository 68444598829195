import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjectsOfUser, getProjectOfUser } from "../../actions/projectActions";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";

import Spinner from "./Spinner/Spinner";
import SideNav from "./SideNav/SideNav";
import TopNav from "./TopNav/TopNav";
import Dashboard from "../dashboard/Dashboard";
import Karte from "../map/Map";
import Tabelle from "../table/Table";
import Workflow from "../workflow/Workflow";
import Datasheet from "../datasheet/Datasheet";
import NotFound from "../404/404";

import "./Layout.scss";

class Layout extends Component {

  componentDidMount() {
    this.props.getProjectsOfUser(this.props.auth.user.user_id);
  }
  
  render() {
    
    const { projects, projectsLoading } = this.props.projects;
	let layoutContent;
	
    if (projects === null || projectsLoading) {
      layoutContent = <Spinner />;
    } else if (projects.length > 0) {
      layoutContent = (
        <>
		  <SideNav projects={projects} />
          <div className="right no-side">
            <TopNav />
			<Switch>
              <Route
                exact
                path="/dashboard"
                projects={projects}
                component={Dashboard}
              />
              <Route
                exact
                path="/map"
                component={Karte}
              />
              <Route
                exact
                path="/table"
                component={Tabelle}
              />
			  <Route exact path="/workflow/:category/:object/:db_schema/:id" component={Workflow} />
			  <Route exact path="/datasheet/:category/:object/:db_schema/:id" component={Datasheet} />
              <Route component={NotFound} />
            </Switch>
          </div>

        </>
      );
    } else {
      layoutContent = (
        <>
		  <SideNav projects={projects} />
		  <div className="right">
            <TopNav />
			<Switch>
              <Route
                exact
                path="/dashboard"
                projects={[]}
                component={Dashboard}
              />
              <Route
                exact
                path="/map"
                component={Karte}
              />
              <Route
                exact
                path="/table"
                component={Tabelle}
              />
			  <Route exact path="/workflow/:category/:object/:db_schema/:id" component={Workflow} />
			  <Route exact path="/datasheet/:category/:object/:db_schema/:id" component={Datasheet} />
              <Route component={NotFound} />
            </Switch>
          </div>

        </>
      );
    }


    return (
      <Router>
        <div className="wrapper">{layoutContent}</div>
      </Router>
    );
  }
}

Layout.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  projects: state.projects
});

export default withRouter(
  connect(
    mapStateToProps,
    { getProjectsOfUser,
      getProjectOfUser	}
  )(Layout)
);