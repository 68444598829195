import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Dialog, DialogTitle, DialogActions, Divider, Button, FormGroup, FormControlLabel, Checkbox, Box } from "@material-ui/core";
import { DoubleArrow } from '@material-ui/icons';

//Actions
import { getWasserAnlageArt, getWasserAnlageMaterial, getWasserAnlageTyp } from "../../../../actions/wasActions";

import './Sheet.scss';

class AnlageSheet extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  isDialogControlOpen: false,
	  control: {}
	}
  }
  
  componentDidMount() {
	  this.props.getWasserAnlageArt(this.props.objInfo.db_schema);
	  this.props.getWasserAnlageMaterial(this.props.objInfo.db_schema);
	  this.props.getWasserAnlageTyp(this.props.objInfo.db_schema);
  }
  
  handleDialogControlOpen = (kontr) => {
	let control = this.props.data.kontrolle.find(item=>item.id === kontr.id);
    this.setState({
      control: control,
      isDialogControlOpen: true
    });
  }
	
  handleDialogControlClose = () => {
    this.setState({
      isDialogControlOpen: false
    });
  }
  
  render() {
	let objectList;
	let photoList;
	let controlContainer;
	let controlDialog;
	
	if(typeof this.props.data !== "undefined" && typeof this.props.objInfo !== "undefined"){
	  const data = this.props.data;
	  const photos = this.props.data.photos;
	  const kontrolle = this.props.data.kontrolle;
	  // Attribute
	  let erledigtValue;
	  let artValue;
	  let materialValue;
	  let typValue;
	  if (data.erledigt === true) {erledigtValue = 'Ja'} else {erledigtValue = 'Nein'}
	  if (data.id_material && this.props.wasAnlageMaterial.length > 0) { materialValue = this.props.wasAnlageMaterial.filter(entity => entity.id === data.id_material)[0].value }
	  if (data.id_typ && this.props.wasAnlageTyp.length > 0) { typValue = this.props.wasAnlageTyp.filter(entity => entity.id === data.id_typ)[0].value }
	  if (data.id_art && this.props.wasAnlageArt.length > 0) { artValue = this.props.wasAnlageArt.filter(entity => entity.id === data.id_art)[0].value }
	  
	  let dataJSON = {
	    ID: data.id,
		Bezeichnung: data.name_nummer,
		Standort: data.standort,
		Leistung: data.leistung,
		Erledigt: erledigtValue,
		Bemerkung : data.bemerkung,
		Material: materialValue,
		Art: artValue,
		Typ: typValue
	  };
	  
	  objectList = Object.keys(dataJSON).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON[key]} />
                </ListItem>
      );

	  // Kontrolle
	  if (kontrolle && kontrolle.length > 0) {
		let controlList = [];
		for (let i=0; i < kontrolle.length; i++){
		  //Datum
		  let controlDate = new Date(kontrolle[i].created_at);
	      let newControlDate = ("0"+(String(controlDate.getDate()))).slice(-2) + '.' + ("0"+(String(controlDate.getMonth()+1))).slice(-2) + '.' + String(controlDate.getFullYear());

     	  controlList[i] = {
			"id": kontrolle[i].id,
			"datum": newControlDate
          };
        }

	    controlContainer = controlList.map(kontr => (
           <ListItem key={kontr.id} className="list-line">
             <ListItemText className="list-bez" primary="Datum" />
		 	 <ListItemText className="list-attr" primary={kontr.datum} />
             <ListItem button onClick={() => this.handleDialogControlOpen(kontr)} className="list-button">
               <ListItemIcon>
                 <DoubleArrow />
               </ListItemIcon>
             </ListItem>
           </ListItem>
		));
		
		if(Object.keys(this.state.control).length > 0 && this.state.control.constructor === Object) {
			
		  let currentControl = this.state.control;
		  //Values
		  let maengelValue;
		  let einstiegshilfeValue;
		  let sanierungValue;
		  let massnahmenValue;
		  if (currentControl.ist_maengel === true) {maengelValue = 'Ja'} else {maengelValue = 'Nein'};
		  if (currentControl.ist_einstiegshilfe === true) {einstiegshilfeValue = 'Ja'} else {einstiegshilfeValue = 'Nein'};
		  if (currentControl.ist_sanierung === true) {sanierungValue = 'Ja'} else {sanierungValue = 'Nein'};
		  if (currentControl.ist_massnahmen === true) {massnahmenValue = 'Ja'} else {massnahmenValue = 'Nein'};
		  let controlDate = new Date(currentControl.created_at);
	      let newControlDate = ("0"+(String(controlDate.getDate()))).slice(-2) + '.' + ("0"+(String(controlDate.getMonth()+1))).slice(-2) + '.' + String(controlDate.getFullYear());
			
	
          let maengelSchacht
          if (currentControl.ist_maengel === true) {
            maengelSchacht = (
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <FormControlLabel
					disabled
					className="control-checkbox-label"
                    control={
                   	  <Checkbox
                   		id={'ist_maengel_defekt' + currentControl.id}
                   		name="ist_maengel_defekt"
                   		checked={currentControl.ist_maengel_defekt}
                      />
                   	}
                    label="Defekt"
                  />
                  <FormControlLabel
					disabled
					className="control-checkbox-label"
                    control={
                   	  <Checkbox
                   		id={'ist_maengel_klemmt' + currentControl.id}
                   		name="ist_maengel_klemmt"
                   		checked={currentControl.ist_maengel_klemmt}
                   		color="default"
                      />
                   	}
                    label="Klemmt"
                  />
                  <FormControlLabel
					disabled
					className="control-checkbox-label"
                    control={
                   	  <Checkbox
                   		id={'ist_maengel_verschaubt' + currentControl.id}
                   		name="ist_maengel_verschaubt"
                   		checked={currentControl.ist_maengel_verschaubt}
                      />
                   	}
                    label="Verschraubt"
                  />
                  <FormControlLabel
					disabled
					className="control-checkbox-label"
                    control={
                   	  <Checkbox
                   	    id={'ist_maengel_korrodiert' + currentControl.id}
                   		name="ist_maengel_korrodiert"
                   		checked={currentControl.ist_maengel_korrodiert}
                      />
                   	}
                    label="Korrodiert"
                  />
                </FormGroup>
              </Grid>
		    )
		  } else {
            maengelSchacht = null
          }
          
          let optionEinstieghilfe
          if (currentControl.ist_einstiegshilfe === true) {
            optionEinstieghilfe = (
              <Grid item xs={12} sm={6}>
          	    <FormGroup>
          	      <FormControlLabel
			        disabled
					className="control-checkbox-label"
					label="verostet"
                    control={
          	          <Checkbox
          			    id={'ist_einstiegshilfe_verrostet' + currentControl.id}
          			    name="ist_einstiegshilfe_verrostet"
          			    checked={currentControl.ist_einstiegshilfe_verrostet}
                      />
          	        }
                  />
          	      <FormControlLabel
                    disabled
					className="control-checkbox-label"
					label="in schlechtem Zustand"
					control={
          	          <Checkbox
          			    id={'ist_einstiegshilfe_zustand' + currentControl.id}
          			    name="ist_einstiegshilfe_zustand"
          			    checked={currentControl.ist_einstiegshilfe_zustand}
                      />
          	        }
                  />
                </FormGroup>
              </Grid>
          	)
          } else if (currentControl.ist_einstiegshilfe === false) {
            optionEinstieghilfe = (
              <Grid item xs={12} sm={6}>
          	    <FormGroup>
          	      <FormControlLabel
				    disabled
					className="control-checkbox-label"
					label="notwendig?"
                    control={
          	          <Checkbox
          			    id={'ist_einstiegshilfe_notwendig' + currentControl.id}
          			    name="ist_einstiegshilfe_notwendig"
          			    checked={currentControl.ist_einstiegshilfe_notwendig}
                      />
          	        }
                  />
                </FormGroup>
              </Grid>
          	)
          } else {	
          	optionEinstieghilfe = null
          }
		  // Ueberlagerung Kontrolle
		  controlDialog = (
            <>
	          <Dialog
                open={this.state.isDialogControlOpen}
				disableBackdropClick
				fullWidth={true}
				maxWidth={'sm'}
                onClose={this.handleDialogControlClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Kontrolle vom {newControlDate}</DialogTitle>
		     	<Divider />
				<Grid container item xs={12}>
		          <Grid item xs={12} sm={6}>
				    <Typography className="control-value" variant="h6" component='div'>Mängel vorhanden: <Box fontWeight={700} display='inline'>{maengelValue}</Box></Typography>
  		          </Grid>
                  {maengelSchacht}
	            </Grid>
		        <Divider />
				<Grid container item xs={12}>
		          <Grid item xs={12} sm={6}>
				    <Typography className="control-value" variant="h6" component='div'>Einstiegshilfe: <Box fontWeight={700} display='inline'>{einstiegshilfeValue}</Box></Typography>
  		          </Grid>
                  {optionEinstieghilfe}
	            </Grid>
				<Divider />
				<Grid container item xs={12}>
		          <Grid item xs={12} sm={6}>
				    <Typography className="control-value" variant="h6" component='div'>Sanierung: <Box fontWeight={700} display='inline'>{sanierungValue}</Box></Typography>
  		          </Grid>
	            </Grid>
				<Divider />
				<Grid container item xs={12}>
		          <Grid item xs={12} sm={6}>
				    <Typography className="control-value" variant="h6" component='div'>Massnahmen: <Box fontWeight={700} display='inline'>{massnahmenValue}</Box></Typography>
  		          </Grid>
	            </Grid>
				<Divider />
		        <Grid item xs={12} sm={12}>
                  <Typography className="control-value" variant="h6" component='div'>Bemerkung:</Typography>
				  <Typography className="control-value" variant="body1" component='div'>{currentControl.bemerkung}</Typography>
		        </Grid>
				<Divider />
                <DialogActions>
                  <Button onClick={this.handleDialogControlClose} variant="contained" size="small">
                    Zurück
                  </Button>
                </DialogActions>
              </Dialog>
             </>
		  );
		}
	  }

      // Photos
	  if (typeof photos !== "undefined" && photos !== null && photos.length > 0) {
		  photoList =  photos.map(photo => (
             <Grid key={photo.upload_at} className="photo-grid" item xs={12} sm={6} md={4}>
			   <img src={photo.pfad + '/' + photo.upload_at + '-' + photo.nummer + '-' + photo.name} className="photo" alt={photo.name} />
			 </Grid>
		  ));
	  }
	}
	  
	return (
        <>
          <Grid className="object-container" container spacing={2}>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={6}>
              <Typography variant="h6" className="item-title">
                Attribute
              </Typography>
              <div className="item-container">
                <List dense={true}>
            		{objectList}
                </List>
          	  <Link to={`http://fme.rswag.ch/fmedatastreaming/RSW/Report_Schachtprotokoll_RSW.fmw?token=49639a184be5e3f58c223a657d66cced7976cff5&id="{object.ID}`}>
          	    <Button size="small" variant="contained">
          	      Protokoll
                  </Button>
          	  </Link>
              </div>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={5}>
              <Typography variant="h6" className="item-title">
                Kontrolle
              </Typography>
              <div className="item-container">
                <List dense={true}>
            		{controlContainer}
                </List>
              </div>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Fotos
              </Typography>
              <Grid className="photo-container" container spacing={1}>
                {photoList}
              </Grid>
            </Grid>
          </Grid>
		  <div>{controlDialog}</div>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  wasAnlageArt: state.wasser.wasAnlageArt,
  wasAnlageMaterial: state.wasser.wasAnlageMaterial,
  wasAnlageTyp: state.wasser.wasAnlageTyp,
});

export default connect(
  mapStateToProps,
    { getWasserAnlageArt,
	  getWasserAnlageMaterial,
	  getWasserAnlageTyp,
	  }
)(AnlageSheet);