import React, { Component } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider} from "@material-ui/core";

import './Step.scss';

class SubmitForm extends Component {

render() {
	let values = this.props.values
	let fidValue = '-'
	let nameNummerValue = '-'
	let standortValue = '-'
	let bemerkungValue = '-'
	let typValue = '-'
	let ueberlaufhoeheValue = '-'
	let fassungsvermoegenValue = '-'
	let brauchwasserreserveValue = '-'
	let loeschwasserreserveValue = '-'
	if (values.fid) { fidValue = values.fid }
	if (values.name_nummer) { nameNummerValue = values.name_nummer }
	if (values.standort) { standortValue = values.standort }
	if (values.bemerkung) { bemerkungValue = values.bemerkung }
	if (values.id_typ) { typValue = this.props.wasReservoirTyp.filter(entity => entity.id === values.id_typ)[0].value }
	if (values.ueberlaufhoehe) { ueberlaufhoeheValue = values.ueberlaufhoehe }
	if (values.fassungsvermoegen) { fassungsvermoegenValue = values.fassungsvermoegen }
	if (values.brauchwasserreserve) { brauchwasserreserveValue = values.brauchwasserreserve }
	if (values.loeschwasserreserve) { loeschwasserreserveValue = values.loeschwasserreserve }

  
    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Übersicht</Typography>
              </Grid>
			  <Grid item xs={12} sm={6}>
			    <List>
                  <ListItem>
                    <ListItemText primary="Feature-ID" />
					<ListItemText primary={fidValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bezeichnung" />
					<ListItemText primary={nameNummerValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Standort" />
					<ListItemText primary={standortValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bemerkung" />
					<ListItemText primary={bemerkungValue} />
                  </ListItem>
                </List>
                <Divider />
			    <List>
                  <ListItem>
                    <ListItemText primary="Typ" />
					<ListItemText primary={typValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Überlaufhöhe" />
					<ListItemText primary={ueberlaufhoeheValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Fassungsvermögen" />
					<ListItemText primary={fassungsvermoegenValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Brauchwasserreserve" />
					<ListItemText primary={brauchwasserreserveValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Löschwasserreserve" />
					<ListItemText primary={loeschwasserreserveValue} />
                  </ListItem>
                </List>
              </Grid>
			</>
        );
    }
}

export default SubmitForm;