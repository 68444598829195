// React
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link} from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Actions
import { getObjects } from "../../actions/objectActions";

//Material UI
import { TableContainer, Tooltip, TableSortLabel, Table, TableBody, TableRow, TableCell, TableHead, AppBar, Toolbar, Typography, Container, Paper, Box, Grid} from "@material-ui/core";
import { AddBox } from '@material-ui/icons';

import equal from 'fast-deep-equal'

// Style
import './Table.css';

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name_nummer', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'standort', numeric: false, disablePadding: false, label: 'Standort' },
  { id: 'typ', numeric: false, disablePadding: false, label: 'Typ' },
  { id: 'datum_letzte', numeric: true, disablePadding: false, label: 'Letzte Kontrolle' },
  { id: 'kontrolle', numeric: true, disablePadding: false, label: 'Kontrolle' },
];


class Tabelle extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  db_schema: '',
	  project_id: null,
	  object_id: null,
	  object_table: '',
	  object_url: '',
	  category_short: ''
	}
  }

  componentDidMount() {
    if (typeof this.props.project.db_schema == "undefined" && typeof this.props.project.project_id == "undefined" && typeof this.props.project.object_id == "undefined") {
	  this.props.getObjects(localStorage.getItem('db_schema'), localStorage.getItem('project_id'), localStorage.getItem('object_id'));
	  this.setState({
		  db_schema: localStorage.getItem('db_schema'),
		  project_id: localStorage.getItem('project_id'),
		  object_id: localStorage.getItem('object_id'),
		  object_table: localStorage.getItem('object_table'),
		  object_url: localStorage.getItem('object_url'),
		  category_short: localStorage.getItem('category_short'),
		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
	  });
	}
	else if (this.state.db_schema === '' && this.state.project_id === null && this.state.object_id === null) {
	  this.props.getObjects(this.props.project.db_schema, this.props.project.project_id, this.props.project.object_id);
	  this.setState({
		  db_schema: localStorage.getItem('db_schema'),
		  project_id: localStorage.getItem('project_id'),
		  object_id: localStorage.getItem('object_id'),
		  object_table: localStorage.getItem('object_table'),
		  object_url: localStorage.getItem('object_url'),
		  category_short: localStorage.getItem('category_short'),
		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
	  });
    }
	let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.remove("top-nav-invisibile"); }
  }
  
  componentDidUpdate(prevProps) {
	if(!equal(this.props.projects.project, prevProps.projects.project)) {
	  this.props.getObjects(this.props.project.db_schema, this.props.project.project_id, this.props.project.object_id);
	  this.setState({
		  db_schema: localStorage.getItem('db_schema'),
		  project_id: localStorage.getItem('project_id'),
		  object_id: localStorage.getItem('object_id'),
		  object_table: localStorage.getItem('object_table'),
		  object_url: localStorage.getItem('object_url'),
		  category_short: localStorage.getItem('category_short'),
		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
	  });
    }
  } 

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;
 
  render() {

    const { objects, objectsLoading } = this.props.objects;
	const objectValueCap = this.state.object_url.charAt(0).toUpperCase() + this.state.object_url.slice(1)
	const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;


    return (
	      <div className="main-content">
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>{objectValueCap}</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
		  <Box component="main" className="wf-box-wrapper">
            <Container maxWidth="md" className="wf-container">
              <Paper elevation={5}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                       {rows.map(
                         row => (
                           <TableCell
                             key={row.id}
                             align={row.numeric ? 'right' : 'left'}
                             sortDirection={orderBy === row.id ? order : false}
                           >
                             <Tooltip
                               title="Sort"
                               placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                               enterDelay={300}
                             >
                               <TableSortLabel
                                 active={orderBy === row.id}
                                 direction={order}
                                 onClick={this.createSortHandler(row.id)}
                               >
                                 {row.label}
                               </TableSortLabel>
                             </Tooltip>
                           </TableCell>
                         ),
                         this,
                       )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {objects.map((object) => (
                        <TableRow key={object.id}>
                          <TableCell component="th" scope="row" align="left">
							<Link to={`/datasheet/${this.state.category_short}/${this.state.object_url}/${this.state.db_schema}/${object.id}`}>
                              {object.name_nummer}
							</Link>
                          </TableCell>
                          <TableCell align="left">{object.standort}</TableCell>
                          <TableCell align="left">{object.typ}</TableCell>
						  <TableCell align="right">{object.letzte_kontrolle}</TableCell>
						  <TableCell align="right" className="list-button">
						  <Link to={`/workflow/${this.state.category_short}/${this.state.object_url}/${this.state.db_schema}/${object.id}`}>
                            <AddBox />
						  </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Container>
          </Box>
	  </div>

    );
  }
}

Tabelle.propTypes = {
  onRequestSort: PropTypes.func.isRequired

};

const mapStateToProps = state => ({
  projects: state.projects,	
  project: state.projects.project,	
  objects: state.objects,
  object: state.objects.object
});

export default
  connect(
    mapStateToProps,
    { getObjects }
  )(Tabelle);