import React, { Component } from "react";
import { TextField, Grid, Typography, MenuItem, Button } from "@material-ui/core";
import { Stage, Layer, Text, Group, Circle, Ellipse, Path} from 'react-konva';
import CanvasDraw from 'react-canvas-draw';
import Konva from 'konva';
import { AddCircleOutline, HighlightOff } from '@material-ui/icons';

import './Step.scss';

class Architecture extends Component {
	
render() {
	const schachtForm = this.props.values.id_form;
    const einlaeufe = this.props.values.einlauf;
	const auslaeufe = this.props.values.auslauf;
	let drawElement;
	
	if (schachtForm === 2) {
	  drawElement = (
          <Stage width={320} height={320}>
                  <Layer>
				    <Group
                      x={0}
                      y={0}
					>
                      <Ellipse
                        x={160}
                        y={160}
						radiusX={100*this.props.values.scale}
						radiusY={140*this.props.values.scale}
                        stroke='black'
                        strokeWidth={5}
                      />
					</Group>
                  </Layer>
                  <Layer>
				    <Group
                      x={160}
                      y={160}
					>
					  <Group
                          x={this.props.values.deckel_x}
                          y={this.props.values.deckel_y}
						  draggable={true}
						  dragBoundFunc={this.props.dragBoundFuncEllipseDeckel}
						  onDragEnd={this.props.handleDragEndDeckel}
					  >
                        <Circle
                          x={0}
                          y={0}
						  radius={60*this.props.values.scale}
                          stroke='black'
                          strokeWidth={3}
						  fill='#E0E0E0'
                        />
					    <Text 
				          text={'Deckel'}
	                      x={-22}
                          y={-8}
                          fontSize={14}
						  fill='#808080'
					      fontStyle='italic'
				        />
				      </Group>
					</Group>
                  </Layer>
                  <Layer>
				  {einlaeufe.slice(0).reverse().map((einlauf, index) => (
				    <Group
					  key={'GrE' + index}
                      id={'GrE' + einlauf.nummer}
					  name="einlauf"
                      x={160}
                      y={160}
					>
				      <Group
					    key={index}
                        id={einlauf.nummer}
					    name={einlauf.name}
                        x={einlauf.x}
                        y={einlauf.y}
                        draggable={true}
                        dragBoundFunc={this.props.dragBoundFuncEllipse}
					    onDragMove={this.props.handleDragChange}
					    onDragEnd={this.props.handleDragEnd}
		               >
                         <Circle
                           x={0}
                           y={0}
                           radius={20*this.props.values.scale}
                           fill='white'
                           shadowBlur={5}
                         />
					     <Text 
				           text={einlauf.name}
	                       x={-9}
                           y={-7}
                           fontSize={16}
					  	   fontStyle='bold'
				         />
					  </Group>
					</Group>
					))}
                  </Layer>
                  <Layer>
				  {auslaeufe.slice(0).reverse().map((auslauf, index) => (
				  	<Group
					  key={'GrA' + index}
                      id={'GrA' + auslauf.nummer}
					  name="auslauf"
                      x={160}
                      y={160}
					>
				      <Group
					    key={index}
                        id={auslauf.nummer}
					    name={auslauf.name}
                        x={auslauf.x}
                        y={auslauf.y}
                        draggable={true}
                        dragBoundFunc={this.props.dragBoundFuncEllipse}
					    onDragEnd={this.props.handleDragEnd}
					    onDragMove={this.props.handleDragChange}
		               >
                        <Circle
                          x={0}
                          y={0}
                          radius={20*this.props.values.scale}
                          fill='white'
                          shadowBlur={5}
                        />
					    <Text 
				          text={auslauf.name}
	                      x={-9}
                          y={-7}
                          fontSize={16}
					  	  fontStyle='bold'
				        />
					  </Group>
					</Group>
					))}
                  </Layer>
                </Stage>
				)
			   } else if (schachtForm === 1) {
			    drawElement = (
				<Stage width={320} height={320}>
                  <Layer>
				    <Group
                      x={0}
                      y={0}
					>
                      <Circle
                        x={160}
                        y={160}
						radius={140}
                        stroke='black'
                        strokeWidth={5}
                      />
					</Group>
                  </Layer>
                  <Layer>
				    <Group
                      x={160}
                      y={160}
					>
                      <Group
                        x={this.props.values.deckel_x}
                        y={this.props.values.deckel_y}
						draggable={true}
						dragBoundFunc={this.props.dragBoundFuncCircleDeckel}
						onDragEnd={this.props.handleDragEndDeckel}
                      >
					    <Circle
                          x={0}
                          y={0}
						  radius={60}
                          stroke='black'
                          strokeWidth={3}
						  fill='#E0E0E0'
                        />
					    <Text 
				          text={'Deckel'}
	                      x={-22}
                          y={-8}
                          fontSize={14}
						  fill='#808080'
					      fontStyle='italic'
				        />
					  </Group>
					</Group>
                  </Layer>
                  <Layer>
				  {einlaeufe.slice(0).reverse().map((einlauf, index) => (
				    <Group
					  key={'GrE' + index}
                      id={'GrE' + einlauf.nummer}
					  name="einlauf"
                      x={160}
                      y={160}
					>
				      <Group
					    key={index}
                        id={einlauf.nummer}
					    name={einlauf.name}
                        x={einlauf.x}
                        y={einlauf.y}
                        draggable={true}
                        dragBoundFunc={this.props.dragBoundFuncCircle}
						onDragMove={this.props.handleDragChange}
					    onDragEnd={this.props.handleDragEnd}
		              >
                       <Circle
					     x={0}
                         y={0}
                         radius={20}
                         fill='white'
                         shadowBlur={5}
                       />
					   <Text 
				         text={einlauf.name}
					     x={-9}
                         y={-7}
                         fontSize={16}
					  	 fontStyle='bold'
				       />
					  </Group>
					</Group>
					))}
                  </Layer>
                  <Layer>
				  {auslaeufe.slice(0).reverse().map((auslauf, index) => (
				    <Group
					  key={'GrA' + index}
                      id={'GrA' + auslauf.nummer}
					  name="auslauf"
                      x={160}
                      y={160}
					>
				    <Group
					  key={index}
                      id={auslauf.nummer}
					  name={auslauf.name}
                      x={auslauf.x}
                      y={auslauf.y}
                      draggable={true}
                      dragBoundFunc={this.props.dragBoundFuncCircle}
					  onDragEnd={this.props.handleDragEnd}
					  onDragMove={this.props.handleDragChange}
		             >
                      <Circle
                        x={0}
                        y={0}
                        radius={20}
                        fill='white'
                        shadowBlur={5}
                      />
					  <Text 
				        text={auslauf.name}
	                    x={-9}
                        y={-7}
                        fontSize={16}
						fontStyle='bold'
				      />
					</Group>
					</Group>
					))}
                  </Layer>
                </Stage>
                )				
			   } else {
		         drawElement = (
				 <>
                   <Grid item xs={12} sm={12}>
                     <div className="tools">
                       <Button variant="contained" size="small"
                         onClick={(e) => {
	              		   e.preventDefault();
                           this.props.values.canvasObject.clear();
                         }}
                       >
                         Löschen
                       </Button>
                       <Button variant="contained" size="small"
                         onClick={(e) => {
	              		  e.preventDefault();
                           this.props.values.canvasObject.undo();
                         }}
                       >
                         Rückgängig
                       </Button>
                     </div>
					 </Grid>
					<Grid item xs={12} sm={12}>
	              	<CanvasDraw
                       ref={canvasDraw => (this.props.values.canvasObject = canvasDraw)}
					   onChange={this.props.handleCanvasChange}
                       brushColor="#000000"
                       brushRadius={2}
                       lazyRadius={4}
                       canvasWidth={this.props.values.canvasWidth}
                       canvasHeight={320}
                     />
					 </Grid>
	               </>
		         )
              }   


    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Architektur</Typography>
              </Grid>
			  <Grid
                spacing={1}
                justify="space-around"
                alignItems="center"
				container
			   >
                {drawElement}
              </Grid>
		      <Grid container item xs={12} sm={12} >
			    <Grid item xs={2} sm={1} >
                  <Typography variant="h6">Auslauf</Typography>
				</Grid>
				<Grid item xs={2} sm={1} >
				  <Button size="small" onClick={() => this.props.addArrayElemet('auslauf')}>
				     <AddCircleOutline style={{fill: "green"}}/>
				  </Button>
				</Grid>
              </Grid>
		      {auslaeufe.map((x, index) => (
                 <Grid container spacing={1} item direction="row" id={'A' + index} key={'A' + index}>
                  <Grid item xs={12} sm={1}>
                    <TextField
                      label="Name"
                      name="name"
                      variant="outlined"
                      fullWidth
		              value={this.props.values.auslauf[index].name}
                      onChange={this.props.handleArrayChangeAuslauf(index)}
		            	id={'auslauf-name' + index}
				  	    key={'auslauf-name' + index}
		            	className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      id={'material' + this.props.values.id}
                      select
					  required
                      label="Material"
		              name="id_material"
		              variant="outlined"
		              fullWidth
		              className="step-input"
                      value={this.props.values.auslauf[index].id_material}
                      onChange={this.props.handleArrayChangeAuslauf(index)}
                    >
                      {this.props.materialSchacht.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Kaliber [mm]"
                      name='kaliber'
                      variant="outlined"
					  type="number"
					  InputProps={{ inputProps: { min: 0, max: 10000 }, inputMode: 'numeric' }}
                      fullWidth
					  value={this.props.values.auslauf[index].kaliber}
                      onChange={this.props.handleArrayChangeAuslauf(index)}
		              id={'kaliber' + index}
					  key={'kaliber' + index}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Abstich [m]"
                      name="abstich"
                      variant="outlined"
					  type="number"
					  InputProps={{ inputProps: { min: 0.000, max: 20.000, step: 0.001}, inputMode: 'numeric' }}
                      fullWidth
					  value={this.props.values.auslauf[index].abstich}
                      onChange={this.props.handleArrayChangeAuslauf(index)}
		              id={'einlauf['+ index}
				  	  key={'abstich' + index}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Richtung"
                      variant="outlined"
					  type="number"
					  required
                      fullWidth
					  disabled
					  value={this.props.values.auslauf[index].richtung}
					  key={index}
                      id={'' + index}
					  name={x.name}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Bemerkung"
                      name="bemerkung"
                      variant="outlined"
					  size='small'
                      fullWidth
					  value={this.props.values.auslauf[index].bemerkung}
                      onChange={this.props.handleArrayChangeAuslauf(index)}
		              id={'bemerkung' + index}
				  	  key={'bemerkung' + index}
		              className="step-input"
                    />
                  </Grid>
				  <Grid item xs={12} sm={1}>
				    <Button variant="outlined" size="large" onClick={() => this.props.deleteArrayElemet('auslauf', index)}>
				      <HighlightOff style={{fill: "red"}}/>
				    </Button>
                  </Grid>
				</Grid>
			   ))}
		      <Grid container item xs={12} sm={12} >
			    <Grid item xs={2} sm={1} >
                 <Typography variant="h6">Einlauf</Typography>
				 </Grid>
				 <Grid item xs={2} sm={1} >
				 <Button size="small" onClick={() => this.props.addArrayElemet('einlauf')}>
				   <AddCircleOutline style={{fill: "green"}}/>
				 </Button>
				 </Grid>
              </Grid>
		      {einlaeufe.map((x, index) => (
                 <Grid container spacing={1} item direction="row" id={'E' + index} key={'E' + index}>
                  <Grid item xs={12} sm={1}>
                    <TextField
                      label="Name"
                      name="name"
                      variant="outlined"
                      fullWidth
		              value={this.props.values.einlauf[index].name}
                      onChange={this.props.handleArrayChangeEinlauf(index)}
		            	id={'name' + index}
				  	    key={'name' + index}
		            	className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      id={'material' + this.props.values.id}
                      select
					  required
                      label="Material"
		              name="id_material"
		              variant="outlined"
		              fullWidth
		              className="step-input"
                      value={this.props.values.einlauf[index].id_material}
                      onChange={this.props.handleArrayChangeEinlauf(index)}
                    >
                      {this.props.materialSchacht.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Kaliber [mm]"
                      name='kaliber'
                      variant="outlined"
					  type="number"
					  InputProps={{ inputProps: { min: 0, max: 10000 }, inputMode: 'numeric' }}
                      fullWidth
					  value={this.props.values.einlauf[index].kaliber}
                      onChange={this.props.handleArrayChangeEinlauf(index)}
		              id={'kaliber' + index}
					  key={'kaliber' + index}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Abstich [m]"
                      name="abstich"
                      variant="outlined"
					  type="number"
					  InputProps={{ inputProps: { min: 0.000, max: 20.000, step: 0.001}, inputMode: 'numeric' }}
                      fullWidth
					  value={this.props.values.einlauf[index].abstich}
                      onChange={this.props.handleArrayChangeEinlauf(index)}
		              id={'einlauf['+ index}
				  	  key={'abstich' + index}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Richtung"
                      variant="outlined"
                      fullWidth
					  required
					  type="number"
					  disabled
					  value={this.props.values.einlauf[index].richtung}
					  key={index}
                      id={'' + index}
					  name={x.name}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Bemerkung"
                      name="bemerkung"
                      variant="outlined"
                      fullWidth
					  value={this.props.values.einlauf[index].bemerkung}
                      onChange={this.props.handleArrayChangeEinlauf(index)}
		              id={'bemerkung' + index}
				  	  key={'bemerkung' + index}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
				    <Button variant="outlined" size="large" onClick={() => this.props.deleteArrayElemet('einlauf', index)}>
				      <HighlightOff style={{fill: "red"}}/>
				    </Button>
                  </Grid>
				</Grid>
			   ))}

			</>
        );
    }
}

export default Architecture;