import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { AppBar, Toolbar, Typography, Grid, Container, Paper, Box, List, ListItem, ListItemText, ListItemIcon, Card, CardMedia, IconButton, CardHeader, Avatar, Button } from "@material-ui/core";
import { DoubleArrow } from '@material-ui/icons';

//Actions
import { getWasserPumpwerkKontrolleTyp } from "../../../../actions/wasActions";

import './Sheet.scss';
 
class PumpwerkSheet extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
      isDialogControlOpen: false,
	  control: {}
	}
  }

  componentDidMount() {
	  this.props.getWasserPumpwerkKontrolleTyp(this.props.objInfo.db_schema);
  }
  
  handleDialogControlOpen = (kontr) => {
	let control = this.props.data.kontrolle.find(item=>item.id === kontr.id);
    this.setState({
      control: control,
      isDialogControlOpen: true
    });
  }
	
  handleDialogControlClose = () => {
    this.setState({
      isDialogControlOpen: false
    });
  }

  render() {
	let objectList;
	let photoList;
	let controlContainer;
	let controlDialog;
	
	if(typeof this.props.data !== "undefined" && typeof this.props.objInfo !== "undefined"){
	  const data = this.props.data;
	  const photos = this.props.data.photos;
	  const kontrolle = this.props.data.kontrolle;
	  //Attribute
	  let dataJSON = {
	    ID: data.id,
		Bezeichnung: data.name_nummer,
		Standort: data.standort,
		Bemerkung : data.bemerkung
	  };
      if (kontrolle) {
		let controlWeek = kontrolle.filter(function (kontr) {
          return kontr.id_typ === 1;
        });
		let controlMonth = kontrolle.filter(function (kontr) {
          return kontr.id_typ === 2;
        });
		let controlQuater = kontrolle.filter(function (kontr) {
          return kontr.id_typ === 3;
        });
		let controlYear = kontrolle.filter(function (kontr) {
          return kontr.id_typ === 4;
        });
      }
	  
	  objectList = Object.keys(dataJSON).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON[key]} />
                </ListItem>
      );
	  
	  	  // Kontrolle
	  if (kontrolle && kontrolle.length > 0) {
		let controlList = [];
		for (let i=0; i < kontrolle.length; i++){
		  //Datum
		  let controlDate = new Date(kontrolle[i].created_at);
	      let newControlDate = ("0"+(String(controlDate.getDate()))).slice(-2) + '.' + ("0"+(String(controlDate.getMonth()+1))).slice(-2) + '.' + String(controlDate.getFullYear());
          //Typ
		  let controlTyp
		  if (kontrolle[i].id_typ === 2) {
			  controlTyp = 'Monatskontrolle'
		  } else if (kontrolle[i].id_typ === 3) {
			  controlTyp = 'Quuartalskontrolle'
		  } else if (kontrolle[i].id_typ === 4) { 
              controlTyp = 'Jahreskontrolle'
		  } else { controlTyp = 'Wochenkontrolle' }
		  
     	  controlList[i] = {
			"id": kontrolle[i].id,
			"datum": newControlDate,
			"typ": controlTyp
          };
        }

	    controlContainer = controlList.map(kontr => (
           <ListItem key={kontr.id} className="list-line">
             <ListItemText className="list-bez" primary={kontr.typ} />
		 	 <ListItemText className="list-attr" primary={kontr.datum} />
             <ListItem button onClick={() => this.handleDialogControlOpen(kontr)} className="list-button">
               <ListItemIcon>
                 <DoubleArrow />
               </ListItemIcon>
             </ListItem>
           </ListItem>
		));
	  }
	  if(Object.keys(this.state.control).length > 0 && this.state.control.constructor === Object) {
		  
      }
      //Photos
	  if (typeof photos !== "undefined" && photos !== null && photos.length > 0) {
		  photoList =  photos.map(photo => (
             <Grid key={photo.upload_at} className="photo-grid" item xs={12} sm={6} md={4}>
			   <img src={photo.pfad + '/' + photo.upload_at + '-' + photo.nummer + '-' + photo.name} className="photo" alt={photo.name} />
			 </Grid>
		  ));
	  }
	}
	  
	return (
        <>
          <Grid className="object-container" container spacing={2}>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={6}>
              <Typography variant="h6" className="item-title">
                Attribute
              </Typography>
              <div className="item-container">
                <List dense={true}>
            		{objectList}
                </List>
          	  <Link to={`http://fme.rswag.ch/fmedatastreaming/RSW/Report_Schachtprotokoll_RSW.fmw?token=49639a184be5e3f58c223a657d66cced7976cff5&id="{object.ID}`}>
          	    <Button size="small" variant="contained">
          	      Protokoll
                  </Button>
          	  </Link>
              </div>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={5}>
              <Typography variant="h6" className="item-title">
                Kontrolle
              </Typography>
              <div className="item-container">
                <List dense={true}>
            		{controlContainer}
                </List>
              </div>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Fotos
              </Typography>
              <Grid className="photo-container" container spacing={1}>
                {photoList}
              </Grid>
            </Grid>
          </Grid>
		  <div>{controlDialog}</div>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  wasPumpwerkKontrlTyp: state.wasser.wasPumpwerkKontrlTyp
});

export default connect(
  mapStateToProps,
    { getWasserPumpwerkKontrolleTyp }
)(PumpwerkSheet);