import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, Checkbox, FormGroup, FormControlLabel, FormLabel, RadioGroup, Radio, StylesProvider, Divider} from "@material-ui/core";

import './Step.scss';

class ControlOutsideForm extends Component {
  
render() {
    return (
	  <>
        <Grid item xs={12}>
          <Typography variant="h6">Kontrolle Aussen</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Umdrehungen"
                name="weg_umdrehungen"
                variant="outlined"
                fullWidth
				value={this.props.values.weg_umdrehungen}
                onChange={this.props.handleChange}
				id={'weg_umdrehungen' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Überdeckung"
                name="ueberdeckung"
                variant="outlined"
                fullWidth
				value={this.props.values.ueberdeckung}
                onChange={this.props.handleChange}
				id={'ueberdeckung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
        <Grid item xs={12} sm={6}>
          <TextField
            id={'zugang' + this.props.values.id}
            select
            label="Zugang"
		    name="id_zugang"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_zugang}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberZugang.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Zugang Bemerkung"
                name="zugang_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.zugang_bemerkung}
                onChange={this.props.handleChange}
				id={'zugang_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
        <Grid item xs={12} sm={6}>
          <TextField
            id={'id_tafel_status' + this.props.values.id}
            select
            label="Tafel Status"
		    name="id_tafel_status"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_tafel_status}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberTafel.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Tafel Bemerkung"
                name="tafel_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.tafel_bemerkung}
                onChange={this.props.handleChange}
				id={'tafel_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
        <Grid item xs={12} sm={6}>
          <TextField
            id={'id_deckel_status' + this.props.values.id}
            select
            label="Deckel Status"
		    name="id_deckel_status"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_deckel_status}
            onChange={this.props.handleChange}
          >
            {this.props.gasSchieberDeckel.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Deckel Beschriftung"
                name="deckel_beschriftung"
                variant="outlined"
                fullWidth
				value={this.props.values.deckel_beschriftung}
                onChange={this.props.handleChange}
				id={'deckel_beschriftung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Deckel Bemerkung"
                name="deckel_bemerkung"
                variant="outlined"
                fullWidth
				value={this.props.values.deckel_bemerkung}
                onChange={this.props.handleChange}
				id={'deckel_bemerkung' + this.props.values.id}
				className="step-input"
            />
        </Grid>
		<Divider />
		<Grid item xs={12} sm={12}>
		  <TextField
            id={'bemerkung' + this.props.values.id}
		    name='bemerkung_kontrolle'
            label="Bemerkung"
            multiline
			fullWidth
			rows={4}
            value={this.props.values.bemerkung_kontrolle}
			onChange={this.props.handleChange}
			variant="outlined"
          />
		</Grid>
    </>
	)
}
}

export default ControlOutsideForm;
