import {
  CREATE_WAS_ANLAGE_KONTROLLE,
  CREATE_WAS_HYDRANT_KONTROLLE,
  CREATE_WAS_RESERVOIR_KONTROLLE,
  CREATE_WAS_SCHIEBER_KONTROLLE,
  CREATE_WAS_PUMPWERK_KONTROLLE,
  UPDATE_WAS_ANLAGE_ATTR,
  UPDATE_WAS_HYDRANT_ATTR,
  UPDATE_WAS_RESERVOIR_ATTR,
  UPDATE_WAS_SCHIEBER_ATTR,
  UPDATE_WAS_PUMPWERK_ATTR,
  GET_WAS_ANLAGE_ART,
  GET_WAS_ANLAGE_MATERIAL,
  GET_WAS_ANLAGE_TYP,
  GET_WAS_HYDRANT_TYP,
  GET_WAS_RESERVOIR_TYP,
  GET_WAS_SCHIEBER_MATERIAL,
  GET_WAS_SCHIEBER_TYP,
  GET_WAS_PUMPWERK_KONTRL_TYP
} from "../actions/types";

const initialState = {
  wasAnlageKontrolle: [],
  wasHydrantKontrolle: [],
  wasReservoirKontrolle: [],
  wasSchieberKontrolle: [],
  wasPumpwerkKontrolle: [],
  wasAnlageTyp: [],
  wasAnlageMaterial: [],
  wasAnlageArt: [],
  wasHydrantTyp: [],
  wasReservoirTyp: [],
  wasSchieberTyp: [],
  wasSchieberMaterial: [],
  wasPumpwerkKontrlTyp: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_WAS_ANLAGE_KONTROLLE:
      return {
        ...state,
		wasAnlageKontrolle: action.payload
      };
    case CREATE_WAS_HYDRANT_KONTROLLE:
      return {
        ...state,
		wasHydrantKontrolle: action.payload
      };
    case CREATE_WAS_RESERVOIR_KONTROLLE:
      return {
        ...state,
		wasReservoirKontrolle: action.payload
      };
    case CREATE_WAS_SCHIEBER_KONTROLLE:
      return {
        ...state,
		wasSchieberKontrolle: action.payload
      };
    case CREATE_WAS_PUMPWERK_KONTROLLE:
      return {
        ...state,
		wasPumpwerkKontrolle: action.payload
      };
    case UPDATE_WAS_ANLAGE_ATTR:
      return {
        ...state,
		anlage: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_WAS_HYDRANT_ATTR:
      return {
        ...state,
		hydrant: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_WAS_RESERVOIR_ATTR:
      return {
        ...state,
		reservoir: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_WAS_SCHIEBER_ATTR:
      return {
        ...state,
		schieber: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_WAS_PUMPWERK_ATTR:
      return {
        ...state,
		pumpwerk: action.payload
      };
    case GET_WAS_ANLAGE_ART:
      return {
        ...state,
        wasAnlageArt: action.payload,
      };
    case GET_WAS_ANLAGE_MATERIAL:
      return {
        ...state,
        wasAnlageMaterial: action.payload,
      };
    case GET_WAS_ANLAGE_TYP:
      return {
        ...state,
        wasAnlageTyp: action.payload,
      };
    case GET_WAS_HYDRANT_TYP:
      return {
        ...state,
        wasHydrantTyp: action.payload,
      };
    case GET_WAS_RESERVOIR_TYP:
      return {
        ...state,
        wasReservoirTyp: action.payload,
      };
    case GET_WAS_SCHIEBER_TYP:
      return {
        ...state,
        wasSchieberTyp: action.payload,
      };
    case GET_WAS_SCHIEBER_MATERIAL:
      return {
        ...state,
        wasSchieberMaterial: action.payload,
      };
    case GET_WAS_PUMPWERK_KONTRL_TYP:
      return {
        ...state,
        wasPumpwerkKontrlTyp: action.payload,
      };
    default:
      return state;
  }
}