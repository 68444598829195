import React, { Component,  } from 'react';
import { connect } from "react-redux";
//import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider} from '@material-ui/core';

//Actions
import { uploadImg, createPhoto } from "../../../../actions/uploadActions";
import { createAVFixpunktKontrolle, updateAVFixpunktAttr, getAVFixpunktKategorie, getAVFixpunktMark, getAVFixpunktSchutz, getAVFixpunktZugang, getAVFixpunktGemeinde } from "../../../../actions/avActions";

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import ControlForm from "./Steps/Step2";
import SubmitForm from "./Steps/Step3";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleCheckChange, handleRadioChange, onImgDrop, values, avFixpunktKategorie, avFixpunktMark, avFixpunktSchutz, avFixpunktZugang, avFixpunktGemeinde}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} values={values} avFixpunktKategorie={avFixpunktKategorie} avFixpunktMark={avFixpunktMark} avFixpunktSchutz={avFixpunktSchutz} avFixpunktGemeinde={avFixpunktGemeinde} avFixpunktZugang={avFixpunktZugang} onImgDrop={onImgDrop}/>;
        case 1:
            return <ControlForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 2:
            return <SubmitForm values={values} avFixpunktKategorie={avFixpunktKategorie} avFixpunktMark={avFixpunktMark} avFixpunktSchutz={avFixpunktSchutz} avFixpunktGemeinde={avFixpunktGemeinde} avFixpunktZugang={avFixpunktZugang} />;
        default:
            return <></>;
    }
}


class AnlageStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Stepper
  	  step: 1,
      activeStep: 0,
	  
	  //Photos
	  photos: [],
	  selectedImg: [],

	  //Fixpunkt
	  id: '',
	  fid: '',
	  name_nummer: '',
	  bemerkung: '',
	  hoehe: '',
	  genauigkeit_hoehe: '',
	  genauigkeit_lage: '',
	  id_gemeinde: '',
	  id_kategorie: '',
	  id_vermarkung: '',
	  id_schutz: '',
	  id_zugang: '',

      //Kontrolle
      ist_maengel: null,
      ist_maengel_defekt: false,
      ist_maengel_klemmt: false,
      ist_maengel_verschaubt: false,
      ist_maengel_korrodiert: false,
      ist_einstiegshilfe: null,
      ist_einstiegshilfe_notwendig: false,
      ist_einstiegshilfe_verrostet: false,
      ist_einstiegshilfe_zustand: false,
      ist_sanierung: null,
      ist_massnahmen: null,
      bemerkung_kontrolle: ''
	}
	this.handleChange = this.handleChange.bind(this);
	this.handleCheckChange = this.handleCheckChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

  componentDidMount() {
	  this.props.getAVFixpunktKategorie();
	  this.props.getAVFixpunktMark();
	  this.props.getAVFixpunktSchutz();
	  this.props.getAVFixpunktZugang();
	  this.props.getAVFixpunktGemeinde();
  }
  
  componentWillReceiveProps(nextProps) {
	  if ((nextProps.fixpunkt.id !== this.props.fixpunkt.id) || (nextProps.fixpunkt.fid !== null && this.state.fid === '')) {this.setState({ id: nextProps.fixpunkt.id }); }
	  if ((nextProps.fixpunkt.fid !== this.props.fixpunkt.fid && nextProps.fixpunkt.fid !== null) || (nextProps.fixpunkt.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.fixpunkt.fid }); }
	  if ((nextProps.fixpunkt.name_nummer !== this.props.fixpunkt.name_nummer && nextProps.fixpunkt.name_nummer !== null) || (nextProps.fixpunkt.name_nummer && this.state.name_nummer === '')) {this.setState({ name_nummer: nextProps.fixpunkt.name_nummer }); }
	  if ((nextProps.fixpunkt.bemerkung !== this.props.fixpunkt.bemerkung && nextProps.fixpunkt.bemerkung !== null) || (nextProps.fixpunkt.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.fixpunkt.bemerkung }); }
	  if ((nextProps.fixpunkt.hoehe !== this.props.fixpunkt.hoehe && nextProps.fixpunkt.hoehe !== null) || (nextProps.fixpunkt.hoehe && this.state.hoehe === '')) {this.setState({ hoehe: nextProps.fixpunkt.hoehe }); }
	  if ((nextProps.fixpunkt.genauigkeit_hoehe !== this.props.fixpunkt.genauigkeit_hoehe && nextProps.fixpunkt.genauigkeit_hoehe !== null) || (nextProps.fixpunkt.genauigkeit_hoehe && this.state.genauigkeit_hoehe === '')) {this.setState({ genauigkeit_hoehe: nextProps.fixpunkt.genauigkeit_hoehe }); }
	  if ((nextProps.fixpunkt.genauigkeit_lage !== this.props.fixpunkt.genauigkeit_lage && nextProps.fixpunkt.genauigkeit_lage !== null) || (nextProps.fixpunkt.genauigkeit_lage && this.state.genauigkeit_lage === '')) {this.setState({ genauigkeit_lage: nextProps.fixpunkt.genauigkeit_lage }); }
	  if ((nextProps.fixpunkt.id_gemeinde !== this.props.fixpunkt.id_gemeinde && nextProps.fixpunkt.id_gemeinde !== null) || (nextProps.fixpunkt.id_gemeinde && this.state.id_gemeinde === '')) {this.setState({ id_gemeinde: nextProps.fixpunkt.id_gemeinde }); }
	  if ((nextProps.fixpunkt.id_kategorie !== this.props.fixpunkt.id_kategorie && nextProps.fixpunkt.id_kategorie !== null) || (nextProps.fixpunkt.id_kategorie && this.state.id_kategorie === '')) {this.setState({ id_kategorie: nextProps.fixpunkt.id_kategorie }); }
	  if ((nextProps.fixpunkt.id_vermarkung !== this.props.fixpunkt.id_vermarkung && nextProps.fixpunkt.id_vermarkung !== null) || (nextProps.fixpunkt.id_vermarkung && this.state.id_vermarkung === '')) {this.setState({ id_vermarkung: nextProps.fixpunkt.id_vermarkung }); }
	  if ((nextProps.fixpunkt.id_schutz !== this.props.fixpunkt.id_schutz && nextProps.fixpunkt.id_schutz !== null) || (nextProps.fixpunkt.id_schutz && this.state.id_schutz === '')) {this.setState({ id_schutz: nextProps.fixpunkt.id_schutz }); }
	  if ((nextProps.fixpunkt.id_zugang !== this.props.fixpunkt.id_zugang && nextProps.fixpunkt.id_zugang !== null) || (nextProps.fixpunkt.id_zugang && this.state.id_zugang === '')) {this.setState({ id_zugang: nextProps.fixpunkt.id_zugang }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }

  handleCheckChange(event) {
    const {name, checked} = event.target
    this.setState({
      [name]: checked
    })
  }
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }

  onImgDrop(photo) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(photo),
	  photos: this.state.photos.concat(photo),
    });
  }
  
  windowGoBack() {
    window.history.back();
  }

  handleNext = () => {
	  
	if (this.state.activeStep === 0) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
	}

    if (this.state.activeStep === 1) {	

	  let CreateData = {
		// Kontrolle
        id_fixpunkt: this.state.id,
        ist_maengel: this.state.ist_maengel,
        ist_maengel_defekt: this.state.ist_maengel_defekt,
        ist_maengel_klemmt: this.state.ist_maengel_klemmt,
        ist_maengel_verschaubt: this.state.ist_maengel_verschaubt,
        ist_maengel_korrodiert: this.state.ist_maengel_korrodiert,
        ist_einstiegshilfe: this.state.ist_einstiegshilfe,
        ist_einstiegshilfe_notwendig: this.state.ist_einstiegshilfe_notwendig,
        ist_einstiegshilfe_verrostet: this.state.ist_einstiegshilfe_verrostet,
        ist_einstiegshilfe_zustand: this.state.ist_einstiegshilfe_zustand,
        ist_sanierung: this.state.ist_sanierung,
        ist_massnahmen: this.state.ist_massnahmen,
        bemerkung_kontrolle: this.state.bemerkung_kontrolle
      };
	  
      this.props.createAVFixpunktKontrolle(CreateData);
      this.setState({ activeStep: this.state.activeStep + 1 });
	  
    }
    if (this.state.activeStep === 2) {	
      // Anlage
	  let EditData = {
		id: this.state.id,
	    fid: this.state.fid,
	    name_nummer: this.state.name_nummer,
	    bemerkung: this.state.bemerkung,
		hoehe: this.state.hoehe,
		genauigkeit_hoehe: this.state.genauigkeit_hoehe,
		genauigkeit_lage: this.state.genauigkeit_lage,
		id_gemeinde: this.state.id_gemeinde,
		id_kategorie: this.state.id_kategorie,
		id_vermarkung: this.state.id_vermarkung,
		id_schutz: this.state.id_schutz,
		id_zugang: this.state.id_zugang,
      };
	  
	  if (EditData.name_nummer === '') {EditData.name_nummer = null}
	  if (EditData.hoehe === '') {EditData.hoehe = null}
	  if (EditData.genauigkeit_hoehe === '') {EditData.genauigkeit_hoehe = null}
	  if (EditData.genauigkeit_lage === '') {EditData.genauigkeit_lage = null}
	  if (EditData.id_gemeinde === '') {EditData.id_gemeinde = null}
	  if (EditData.id_kategorie === '') {EditData.id_kategorie = null}
	  if (EditData.id_vermarkung === '') {EditData.id_vermarkung = null}
	  if (EditData.id_schutz === '') {EditData.id_schutz = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  
      this.props.updateAVFixpunktAttr(EditData);  
	  this.setState({ activeStep: 0 })
	  this.windowGoBack();   
    } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.goBack();
  }
  

  render() {
	let buttonNext;
    if (this.state.activeStep === 2) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleCheckChange={this.handleCheckChange}
						  handleRadioChange={this.handleRadioChange}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  avFixpunktKategorie={this.props.avFixpunktKategorie}
						  avFixpunktMark={this.props.avFixpunktMark}
						  avFixpunktSchutz={this.props.avFixpunktSchutz}
						  avFixpunktZugang={this.props.avFixpunktZugang}
						  avFixpunktGemeinde={this.props.avFixpunktGemeinde}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  fixpunkt: state.av.avFixpunkt,
  avFixpunktKategorie: state.av.avFixpunktKategorie,
  avFixpunktMark: state.av.avFixpunktMark,
  avFixpunktSchutz: state.av.avFixpunktSchutz,
  avFixpunktZugang: state.av.avFixpunktZugang,
  avFixpunktGemeinde: state.av.avFixpunktGemeinde,
  photos: state.photos
});

export default connect(
  mapStateToProps,
    { uploadImg,
	  createPhoto,
      createAVFixpunktKontrolle,
	  updateAVFixpunktAttr,
	  getAVFixpunktKategorie,
	  getAVFixpunktMark,
	  getAVFixpunktSchutz,
	  getAVFixpunktZugang,
	  getAVFixpunktGemeinde
	  }
)(AnlageStepper);