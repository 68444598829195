import {
  GET_AV_FIXPUNKT,
  GET_AV_EINZELOBJEKT,
  CREATE_AV_FIXPUNKT_KONTROLLE,
  UPDATE_AV_FIXPUNKT_ATTR,
  UPDATE_AV_EINZELOBJEKT_ATTR,
  GET_AV_FIXPUNKT_KATEGORIE,
  GET_AV_FIXPUNKT_MARK,
  GET_AV_FIXPUNKT_SCHUTZ,
  GET_AV_FIXPUNKT_ZUGANG,
  GET_AV_FIXPUNKT_GEMEINDE,
  GET_AV_EINZELOBJEKT_GEMEINDE,
} from "../actions/types";

const initialState = {
  avFixpunkt: [],
  avEinzelobjekt: [],
  avFixpunktKontrolle: [],
  avFixpunktKategorie: [],
  avFixpunktMark: [],
  avFixpunktSchutz: [],
  avFixpunktZugang: [],
  avFixpunktGemeinde: [],
  avGemeinde: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_AV_FIXPUNKT:
      return {
        ...state,
        avFixpunkt: action.payload,
      };
    case GET_AV_EINZELOBJEKT:
      return {
        ...state,
        avEinzelobjekt: action.payload,
      };
    case CREATE_AV_FIXPUNKT_KONTROLLE:
      return {
        ...state,
		avFixpunktKontrolle: action.payload
      };
    case GET_AV_FIXPUNKT_KATEGORIE:
      return {
        ...state,
        avFixpunktKategorie: action.payload,
      };
    case GET_AV_FIXPUNKT_MARK:
      return {
        ...state,
        avFixpunktMark: action.payload,
      };
    case GET_AV_FIXPUNKT_SCHUTZ:
      return {
        ...state,
        avFixpunktSchutz: action.payload,
      };
    case GET_AV_FIXPUNKT_ZUGANG:
      return {
        ...state,
        avFixpunktZugang: action.payload,
      };
    case GET_AV_FIXPUNKT_GEMEINDE:
      return {
        ...state,
        avFixpunktGemeinde: action.payload,
      };
    case GET_AV_EINZELOBJEKT_GEMEINDE:
      return {
        ...state,
        avGemeinde: action.payload,
      };
    default:
      return state;
  }
}