import axios from "axios";

import {
  GET_AV_FIXPUNKT,
  GET_AV_EINZELOBJEKT,
  CREATE_AV_FIXPUNKT_KONTROLLE,
  UPDATE_AV_FIXPUNKT_ATTR,
  UPDATE_AV_EINZELOBJEKT_ATTR,
  GET_AV_FIXPUNKT_KATEGORIE,
  GET_AV_FIXPUNKT_MARK,
  GET_AV_FIXPUNKT_SCHUTZ,
  GET_AV_FIXPUNKT_ZUGANG,
  GET_AV_FIXPUNKT_GEMEINDE,
  GET_AV_EINZELOBJEKT_GEMEINDE
} from "./types";

// GET alle Objekte für Karte und Tabelle
export const getAVFixpunkt = id => dispatch => {
  axios
    .get(`/api/av/fixpunkt/${id}`)
    .then(res =>
      dispatch({
        type: GET_AV_FIXPUNKT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_FIXPUNKT,
        payload: null
      })
    );
};

export const getAVEinzelobjekt = id => dispatch => {
  axios
    .get(`/api/av/einzelobjekt/${id}`)
    .then(res =>
      dispatch({
        type: GET_AV_EINZELOBJEKT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_EINZELOBJEKT,
        payload: null
      })
    );
};

// Create Fixpunkt Kontrolle
export const createAVFixpunktKontrolle = fixpunktData => dispatch => {
  axios
    .post("/api/av/fixpunkt/create/kontrolle", fixpunktData)
    .then(res =>
      dispatch({
        type: CREATE_AV_FIXPUNKT_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Fixpunkt
export const updateAVFixpunktAttr = fixpunktData => dispatch => {
  axios
    .patch("/api/av/fixpunkt/update/attr", fixpunktData)
    .then(res =>
      dispatch({
        type: UPDATE_AV_FIXPUNKT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

export const updateAVEinzelobjektAttr = einzelobjektData => dispatch => {
  axios
    .patch("/api/av/einzelobjekt/update/attr", einzelobjektData)
    .then(res =>
      dispatch({
        type: UPDATE_AV_EINZELOBJEKT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

export const getAVFixpunktKategorie = (db_schema) => dispatch => {
  axios
    .get("/api/av/fixpunkt/kategorie", db_schema)
    .then(res =>
      dispatch({
        type: GET_AV_FIXPUNKT_KATEGORIE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_FIXPUNKT_KATEGORIE,
        payload: null
      })
    );
};

export const getAVFixpunktMark = (db_schema) => dispatch => {
  axios
    .get("/api/av/fixpunkt/mark", db_schema)
    .then(res =>
      dispatch({
        type: GET_AV_FIXPUNKT_MARK,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_FIXPUNKT_MARK,
        payload: null
      })
    );
};

export const getAVFixpunktSchutz = (db_schema) => dispatch => {
  axios
    .get("/api/av/fixpunkt/schutz", db_schema)
    .then(res =>
      dispatch({
        type: GET_AV_FIXPUNKT_SCHUTZ,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_FIXPUNKT_SCHUTZ,
        payload: null
      })
    );
};

export const getAVFixpunktZugang = (db_schema) => dispatch => {
  axios
    .get("/api/av/fixpunkt/zugang", db_schema)
    .then(res =>
      dispatch({
        type: GET_AV_FIXPUNKT_ZUGANG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_FIXPUNKT_ZUGANG,
        payload: null
      })
    );
};

export const getAVFixpunktGemeinde = (db_schema) => dispatch => {
  axios
    .get("/api/av/fixpunkt/gemeinde", db_schema)
    .then(res =>
      dispatch({
        type: GET_AV_FIXPUNKT_GEMEINDE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_FIXPUNKT_GEMEINDE,
        payload: null
      })
    );
};

export const getAVEinzelobjektGemeinde = (db_schema) => dispatch => {
  axios
    .get("/api/av/einzelobjekt/gemeinde", db_schema)
    .then(res =>
      dispatch({
        type: GET_AV_EINZELOBJEKT_GEMEINDE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AV_EINZELOBJEKT_GEMEINDE,
        payload: null
      })
    );
};