import React, { Component } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider} from "@material-ui/core";

import './Step.scss';

class SubmitForm extends Component {

render() {
	let values = this.props.values
	let fidValue = '-'
	let nameNummerValue = '-'
	let standortValue = '-'
	let bemerkungValue = '-'
	let materialValue = '-'
	let typValue = '-'
	let nennweiteValue = '-'
	if (values.fid) { fidValue = values.fid }
	if (values.name_nummer) { nameNummerValue = values.name_nummer }
	if (values.standort) { standortValue = values.standort }
	if (values.bemerkung) { bemerkungValue = values.bemerkung }
	if (values.id_material) { materialValue = this.props.wasSchieberMaterial.filter(entity => entity.id === values.id_material)[0].value }
	if (values.id_typ) { typValue = this.props.wasSchieberTyp.filter(entity => entity.id === values.id_typ)[0].value }
	if (values.nennweite) { nennweiteValue = values.nennweite }

  
    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Übersicht</Typography>
              </Grid>
			  <Grid item xs={12} sm={6}>
			    <List>
                  <ListItem>
                    <ListItemText primary="Feature-ID" />
					<ListItemText primary={fidValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bezeichnung" />
					<ListItemText primary={nameNummerValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Standort" />
					<ListItemText primary={standortValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bemerkung" />
					<ListItemText primary={bemerkungValue} />
                  </ListItem>
                </List>
                <Divider />
			    <List>
                  <ListItem>
                    <ListItemText primary="Material" />
					<ListItemText primary={materialValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Typ" />
					<ListItemText primary={typValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Nennweite" />
					<ListItemText primary={nennweiteValue} />
                  </ListItem>
                </List>
              </Grid>
			</>
        );
    }
}

export default SubmitForm;