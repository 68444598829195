import {
  GET_PROJECTS_OF_USER,
  PROJECTS_LOADING,
  GET_PROJECT_OF_USER,
  PROJECT_LOADING
} from "../actions/types";

const initialState = {
  projects: [],
  projectsLoading: false,
  project: [],
  projectLoading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_OF_USER:
      return {
        ...state,
        project: action.payload,
        projectLoading: false
      };
    case GET_PROJECTS_OF_USER:
      return {
        ...state,
        projects: action.payload,
        projectsLoading: false
      };
    case PROJECT_LOADING:
      return {
        ...state,
        projectLoading: true
      };
    case PROJECTS_LOADING:
      return {
        ...state,
        projectsLoading: true
      };
    default:
      return state;
  }
}