import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem } from "@material-ui/core";
import ImageUploader from 'react-images-upload';

import './Step.scss';

class AttributForm extends Component {
  
render() {
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Attribute</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                label="Bezeichnung"
                name="name_nummer"
                variant="outlined"
                required
                fullWidth
				value={this.props.values.name_nummer}
                onChange={this.props.handleChange}
				id={'name_nummer' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Feature-ID"
                name="fid"
                variant="outlined"
                fullWidth
				id={'fid' + this.props.values.id}
				value={this.props.values.fid}
                onChange={this.props.handleChange}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id={'gemeinde' + this.props.values.id}
            select
            label="Gemeinde"
            name="id_gemeinde"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_gemeinde}
            onChange={this.props.handleChange}
          >
            {this.props.avFixpunktGemeinde.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'kategorie' + this.props.values.id}
            select
            label="Kategorie"
            name="id_kategorie"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_kategorie}
            onChange={this.props.handleChange}
          >
            {this.props.avFixpunktKategorie.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'vermarkung' + this.props.values.id}
            select
            label="Vermarkung"
            name="id_vermarkung"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_vermarkung}
            onChange={this.props.handleChange}
          >
            {this.props.avFixpunktMark.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'schutz' + this.props.values.id}
            select
            label="Schutz"
            name="id_schutz"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_schutz}
            onChange={this.props.handleChange}
          >
            {this.props.avFixpunktSchutz.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'zugang' + this.props.values.id}
            select
            label="Zugang"
            name="id_zugang"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_zugang}
            onChange={this.props.handleChange}
          >
            {this.props.avFixpunktZugang.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Höhe"
                name="hoehe"
                variant="outlined"
                fullWidth
				id={'hoehe' + this.props.values.id}
				value={this.props.values.hoehe}
                onChange={this.props.handleChange}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Genauigkeit Höhe"
                name="genauigkeit_hoehe"
                variant="outlined"
                fullWidth
				id={'genauigkeit_hoehe' + this.props.values.id}
				value={this.props.values.genauigkeit_hoehe}
                onChange={this.props.handleChange}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Genauigkeit Lage"
                name="genauigkeit_lage"
                variant="outlined"
                fullWidth
				id={'genauigkeit_lage' + this.props.values.id}
				value={this.props.values.genauigkeit_lage}
                onChange={this.props.handleChange}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
			id={'bemerkung' + this.props.values.id}
            label="Bemerkung"
            name="bemerkung"
            variant="outlined"
            fullWidth
			value={this.props.values.bemerkung}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Fotos</Typography>
        </Grid>
		<Grid item xs={12}>
           <ImageUploader
               withIcon={true}
			   withPreview={true}
               buttonText='Choose images'
               onChange={this.props.onImgDrop}
               imgExtension={['.jpg', '.gif', '.png', '.gif']}
               maxFileSize={5242880}
           />
		 </Grid>
    </>
	)
}
}

export default AttributForm;
