import React, { Component } from 'react';
import { TextField, Grid, Typography, Checkbox, FormGroup, FormControlLabel, FormLabel, Button, RadioGroup, Radio, StylesProvider, Divider, MenuItem} from "@material-ui/core";
import { Stage, Layer, Text, Group, Circle, Rect, Path} from 'react-konva';

import ImageUploader from 'react-images-upload';

import './Step.scss';

class Side1Form extends Component {
  
render() {
	let rohre = this.props.values.seite1;
	let photos = this.props.values.photos;
	let seiteNr = 1;
	let drawElement;
		drawElement = (
          <Stage width={320} height={160}>
                  <Layer>
				    <Group
                      x={0}
                      y={0}
					>
                      <Rect
                        x={0}
                        y={0}
						width={320}
						height={160}
                        stroke='black'
                        strokeWidth={5}
                      />
					</Group>
                  </Layer>
                  <Layer>
				  {rohre.slice(0).reverse().map((rohr, index) => (
				    <Group
					  key={'GrE' + index}
                      id={'GrE' + rohr.nummer}
					  name="rohr"
                      x={0}
                      y={0}
					>
				      <Group
					    key={index}
                        id={rohr.nummer}
					    name={'Rohr' + rohr.nummer}
                        x={rohr.x}
                        y={rohr.y}
                        draggable={false}
		               >
                         <Circle
                           x={0}
                           y={0}
                           radius={rohr.dm}
                           fill={rohr.color}
                           stroke='black'
						   strokeWidth={2}
                         />
					     <Text 
						   x={-8}
                           y={-8}
                           width={16}
                           height={16}
				           text={rohr.nummer}
						   align='center'
						   verticalAlign='middle'
                           fontSize={14}
					  	   fontStyle='bold'
				         />
					  </Group>
					</Group>
					))}
                  </Layer>
                </Stage>
				)
	
    return (
	  <>
        <Grid item xs={12}>
            <Typography variant="h6">Seite 1</Typography>
        </Grid>
		<Grid item xs={12}>
           <ImageUploader
		       optimisticPreviews
               withIcon={true}
			   withPreview={true}
               buttonText='Bild Auswählen'
               onChange={this.props.onImgDrop}
               imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
			   fileTypeError="Der Datei-Typ wird nicht unterstützt"
               maxFileSize={10485760}
			   fileSizeError="die Datei ist zu gross"
			   label="Bild Seite 1 aufnehmen"
           />
		</Grid>
        <Grid item xs={6} >
          <TextField
            label="Reihen"
            name='seite1_row'
            variant="outlined"
	        type="number"
	        InputProps={{ inputProps: { min: 0, max: 10 } }}
            fullWidth
	        value={this.props.values.seite1_row}
            onChange={this.props.handleSizeChange(seiteNr)}
	        id={'seite1_row'}
	        key={'seite1_row'}
	        className="step-input"
          />
        </Grid>
        <Grid item xs={6} >
          <TextField
            label="Zeilen"
            name='seite1_col'
            variant="outlined"
		    type="number"
		    InputProps={{ inputProps: { min: 0, max: 10 } }}
            fullWidth
		    value={this.props.values.seite1_col}
            onChange={this.props.handleSizeChange(seiteNr)}
		    id={'seite1_col'}
		    key={'seite1_col'}
		    className="step-input"
          />
        </Grid>
		<Grid
          justify="space-around"
          alignItems="center"
		  className="canvas-container"
		  container

		>
          {drawElement}
        </Grid>
		  {rohre.map((x, index) => (
             <Grid container spacing={1} item xs={12} sm={4} md={3} lg={3} direction="row" id={'S1' + index} key={'S1' + index}>
              <Grid item xs={2} sm={4}>
                <TextField
                  id={'nummer_S1' + this.props.values.id}
		          disabled
                  label="Nr"
		          name="nummer"
		          variant="outlined"
		          fullWidth
		          className="step-input"
                  value={this.props.values.seite1[index].nummer}
                >
                </TextField>
              </Grid>
              <Grid item xs={10} sm={8}>
                <TextField
                  id={'typ_S1' + this.props.values.id}
                  select
		          required
                  label="Typ"
		          name="id_typ"
		          variant="outlined"
		          fullWidth
		          className="step-input"
                  value={this.props.values.seite1[index].id_typ}
                  onChange={this.props.handleArrayChange(seiteNr, index)}
                >
                  {this.props.ewRohrTyp.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
			</Grid>
		 ))}
		 <Grid item xs={12} sm={12}>
          <TextField
			id={'seite1_bemerkung' + this.props.values.id}
            label="Bemerkung Seite 1"
            name="seite1_bemerkung"
            variant="outlined"
            fullWidth
			value={this.props.values.seite1_bemerkung}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6">Alle Fotos</Typography>
        </Grid>
		<Grid container spacing={1} item xs={12}  direction="row" id={'P1'} key={'P1'}>
		  {photos.map((x, index) => (
               <Grid key={'photo' + index} className="photo-grid" item xs={12} sm={6} md={4}>
			     <img src={URL.createObjectURL(this.props.values.photos[index])} className="photo" alt={'preview' + index} />
			   </Grid>
		   ))}
		 </Grid>
    </>
	)
}
}

export default Side1Form;
