import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, Checkbox, FormGroup, FormControlLabel, FormLabel, RadioGroup, Radio, StylesProvider, Divider} from "@material-ui/core";

import './Step.scss';

class ControlForm extends Component {
  
render() {
	
	let maengelObject
	if (this.props.values.ist_maengel === true) {
	maengelObject = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_maengel_defekt' + this.props.values.id}
				name="ist_maengel_defekt"
				checked={this.props.values.ist_maengel_defekt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Defekt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_maengel_klemmt' + this.props.values.id}
				name="ist_maengel_klemmt"
				checked={this.props.values.ist_maengel_klemmt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Klemmt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_maengel_verschaubt' + this.props.values.id}
				name="ist_maengel_verschaubt"
				checked={this.props.values.ist_maengel_verschaubt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Verschraubt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_maengel_korrodiert' + this.props.values.id}
				name="ist_maengel_korrodiert"
				checked={this.props.values.ist_maengel_korrodiert}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Korrodiert"
           />
	    </FormGroup>
        </Grid>
		)
	} else {
		maengelObject = null
	}
	
    let optionEinstieghilfe
	if (this.props.values.ist_einstiegshilfe === true) {
	optionEinstieghilfe = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_einstiegshilfe_verrostet' + this.props.values.id}
				name="ist_einstiegshilfe_verrostet"
				checked={this.props.values.ist_einstiegshilfe_verrostet}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="verostet"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_einstiegshilfe_zustand' + this.props.values.id}
				name="ist_einstiegshilfe_zustand"
				checked={this.props.values.ist_einstiegshilfe_zustand}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="in schlechtem Zustand"
           />
	    </FormGroup>
        </Grid>
		)
	} else if (this.props.values.ist_einstiegshilfe === false) {
	optionEinstieghilfe = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_einstiegshilfe_notwendig' + this.props.values.id}
				name="ist_einstiegshilfe_notwendig"
				checked={this.props.values.ist_einstiegshilfe_notwendig}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="notwendig?"
           />
	    </FormGroup>
        </Grid>
		)	
		
	} else {	
		optionEinstieghilfe = null
	}

    return (
	  <>
        <Grid item xs={12}>
          <Typography variant="h6">Kontrolle</Typography>
        </Grid>
		<StylesProvider injectFirst>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend">Mängel vorhanden*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_maengel"
		  	    value={this.props.values.ist_maengel}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<Radio required={true} /> }
		  	      label="Ja"
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<Radio required={true} /> }
		  	      label="Nein"
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			</FormGroup>
		  </Grid>
          {maengelObject}
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend">Einstiegshilfe vorhanden (Tiefe > 1.2m zwingend)*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_einstiegshilfe"
		  	    value={this.props.values.ist_einstiegshilfe}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<Radio required={true} /> }
		  	      label="Ja"
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<Radio required={true} /> }
		  	      label="Nein"
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			</FormGroup>
		  </Grid>
          {optionEinstieghilfe}
	    </Grid>
		<Divider />
		<Grid container item xs={12} spacing={1}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Sanierung notwendig?</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_sanierung"
		  	   value={this.props.values.ist_sanierung}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		       <FormControlLabel
		  	     value={true}
                 control={<Radio /> }
		  	     label="Ja"
		  	     labelPlacement="start"
               />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
		</Grid>
		<Divider />
		<Grid container item xs={12} spacing={1}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Sofortmassnahmen notwendig?</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_massnahmen"
		  	   value={this.props.values.ist_massnahmen}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		       <FormControlLabel
		  	     value={true}
                 control={<Radio /> }
		  	     label="Ja"
		  	     labelPlacement="start"
               />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
		</Grid>
		</StylesProvider>
		<Grid item xs={12} sm={12}>
		  <TextField
            id={'bemerkung' + this.props.values.id}
		    name='bemerkung_kontrolle'
            label="Bemerkung"
            multiline
			fullWidth
			rows={4}
            value={this.props.values.bemerkung_kontrolle}
			onChange={this.props.handleChange}
			variant="outlined"
          />
		</Grid>
    </>
	)
}
}

export default ControlForm;
