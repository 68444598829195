import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider, Divider} from '@material-ui/core';

//Actions
import { uploadImg, createPhoto } from "../../../../actions/uploadActions";
import { updateAbwasserSchachtAttr, updateAbwasserSchachtZugang, createAbwasserSchachtKontrolle } from "../../../../actions/abwActions";
import { getAbwasserMaterialSchacht, getAbwasserMaterialDeckel, getAbwasserSchachtFunktion, getAbwasserSchachtForm, getAbwasserSchachtNutzung, getAbwasserSchachtStatus, getAbwasserSchachtOberflaechenzulauf, getAbwasserSchachtZugang, getAbwasserSchachtEinstiegshilfe, getAbwasserSchachtLage } from "../../../../actions/abwActions";

import equal from 'fast-deep-equal'

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import ArchitectureForm from "./Steps/Step2";
import Architecture from "./Steps/Step3";
import MaintenanceCoverForm from "./Steps/Step4";
import MaintenanceManholeForm from "./Steps/Step5";
import SubmitForm from "./Steps/Step6";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleCheckChange, handleRadioChange, handleCanvasChange, dragBoundFuncCircle, dragBoundFuncCircleDeckel, dragBoundFuncEllipse, dragBoundFuncEllipseDeckel, handleArrayChangeEinlauf, handleArrayChangeAuslauf, handleDragEnd, handleDragEndDeckel, handleDragChange, addArrayElemet, deleteArrayElemet, onImgDrop, values, materialSchacht, materialDeckel, funktion, zugang, form, status, nutzung, lage, einstiegshilfe, einlauf, auslauf}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} handleRadioChange={handleRadioChange} values={values} materialSchacht={materialSchacht} funktion={funktion} lage={lage}  zugang={zugang} status={status} nutzung={nutzung} onImgDrop={onImgDrop}/>;
        case 1:
            return <ArchitectureForm handleChange={handleChange} values={values} materialDeckel={materialDeckel} form={form}/>;
        case 2:
            return <Architecture handleChange={handleChange} handleCanvasChange={handleCanvasChange} dragBoundFuncCircle={dragBoundFuncCircle} dragBoundFuncCircleDeckel={dragBoundFuncCircleDeckel} dragBoundFuncEllipse={dragBoundFuncEllipse} dragBoundFuncEllipseDeckel={dragBoundFuncEllipseDeckel} handleArrayChangeEinlauf={handleArrayChangeEinlauf} handleArrayChangeAuslauf={handleArrayChangeAuslauf} handleDragEnd={handleDragEnd} handleDragEndDeckel={handleDragEndDeckel} handleDragChange={handleDragChange} addArrayElemet={addArrayElemet} deleteArrayElemet={deleteArrayElemet} values={values} einlauf={einlauf} auslauf={auslauf} materialSchacht={materialSchacht}/>;
        case 3:
            return <MaintenanceCoverForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 4:
            return <MaintenanceManholeForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} materialDeckel={materialDeckel} einstiegshilfe={einstiegshilfe}/>;
        case 5:
            return <SubmitForm values={values} materialSchacht={materialSchacht} zugang={zugang} funktion={funktion} status={status} nutzung={nutzung} form={form} />;
        default:
            return <></>;
    }
}


class AbwSchachtStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Layout
  	  step: 1,
      activeStep: 0,
	  width: 0, 
	  height: 0,
	  scale: 1,
	  canvasWidth: 860,
	  dependZugang: true,
	  withControl: true,
	  
	  // Schacht
	  id: '',
	  fid: this.props.object.fid,
	  name_nummer: '',
	  id_material: '',
	  id_function: '',
	  id_form: '',
	  id_zugang: '',
	  id_nutzung: '',
	  id_status: '',
	  id_oberflaechenzulauf: '',
	  id_lage: '',
	  dimension_1: '',
	  dimension_2: '',
	  depth: '',
	  standort: '',
	  bemerkung: '',
	  einlauf: [],
	  einlauf_cnt: 1,
	  einlauf_exist: false,
	  auslauf: [],
	  auslauf_cnt: 1,
	  auslauf_exist: false,
	  pictures: [],
	  selectedImg: [],
	  canvasObject: {},
	  canvasArray: {},
	  deckel_x: 0,
	  deckel_y: 0,
	  id_deckel_material: '',
      id_deckel_form: '',
      dimension_1_deckel: '',
      dimension_2_deckel: '',
	  
	  // Kontrolle
	  ist_deckel_ersetze: null,
      ist_deckel_maengel: null,
      ist_deckel_defekt: false,
      ist_deckel_korrodiert: false,
      ist_deckel_klemmt: false,
      ist_deckel_verschraubt: false,
      ist_deckel_belueftung: null,
      ist_deckel_verschluss: null,
      ist_deckel_rahmen_ersetzte: null,
      ist_deckel_rahmen_lose: false,
      ist_deckel_rahmen_maengel: null,
      ist_deckel_rahmen_mangel_unterbetoniert: false,
      bemerkung_deckel: '',
      ist_schacht_maengel: null,
      ist_schacht_einstiegshilfe: null,
      ist_schacht_undicht: null,
      ist_schacht_bankett: null,
      ist_schacht_sanierung: null,
      ist_schacht_massnahmen: null,
      ist_schacht_maengel_schachthals: false,
      ist_schacht_maengel_schachtrohrfugen: false,
      ist_schacht_maengel_seitl_anschl_einfuhr: false,
      ist_schacht_maengel_seitl_anschl_verputzt: false,
      ist_schacht_maengel_einlauf_verputzt: false,
      ist_schacht_maengel_auslauf_verputzt: false,
      ist_schacht_einstiegshilfe_notwendig: false,
      ist_schacht_einstiegshilfe_verrostet: false,
      ist_schacht_einstiegshilfe_zustand: false,
      ist_schacht_undicht_schachtrohr: false,
      ist_schacht_undicht_konus: false,
      ist_schacht_durchlaufrinne: null,
      ist_schacht_durchlaufrinne_ablagerung: false,
      ist_schacht_durchlaufrinne_ausgebildet: false,
	  ist_schacht_durchlaufrinne_notwendig: false,
      ist_schacht_grundwassereintritt: null,
	  ist_schacht_tauchbogen: null,
	  ist_schacht_tauchbogen_notwendig: false,
	  ist_schacht_tauchbogen_entfernen: false,
	  ist_schacht_tauchbogen_defekt: false,
      id_schacht_einstiegshilfe_typ: '',
      bemerkung_schacht: '',


	}
	this.handleChange = this.handleChange.bind(this);
	this.handleCheckChange = this.handleCheckChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.handleArrayChangeEinlauf = this.handleArrayChangeEinlauf.bind(this);
	this.handleArrayChangeAuslauf = this.handleArrayChangeAuslauf.bind(this);
	this.handleCanvasChange = this.handleCanvasChange.bind(this);
	this.handleDragChange = this.handleDragChange.bind(this);
	this.handleDragEnd = this.handleDragEnd.bind(this);
	this.handleDragEndDeckel = this.handleDragEndDeckel.bind(this);
	this.dragBoundFuncCircle = this.dragBoundFuncCircle.bind(this);
	this.dragBoundFuncCircleDeckel = this.dragBoundFuncCircleDeckel.bind(this);
	this.dragBoundFuncEllipse = this.dragBoundFuncEllipse.bind(this);
	this.dragBoundFuncEllipseDeckel = this.dragBoundFuncEllipseDeckel.bind(this);
	this.addArrayElemet = this.addArrayElemet.bind(this);
	this.deleteArrayElemet = this.deleteArrayElemet.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
	this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

  componentDidMount() {
	  this.props.getAbwasserMaterialSchacht(this.props.objInfo.db_schema);
	  this.props.getAbwasserMaterialDeckel(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtFunktion(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtForm(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtNutzung(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtStatus(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtOberflaechenzulauf(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtZugang(this.props.objInfo.db_schema);
	  this.props.getAbwasserSchachtEinstiegshilfe(this.props.objInfo.db_schema);
      this.props.getAbwasserSchachtLage(this.props.objInfo.db_schema);
	  
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 1, canvasWidth: 860 });
	  if (window.innerWidth > 600 && window.innerWidth <= 970) {
	      this.setState({ width: window.innerWidth, height: window.innerHeight, canvasWidth: 500 });
	  } else if (window.innerWidth <= 600) {
		  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 0.8, canvasWidth: 360 });
	  } else if (window.innerWidth <= 450) {
		  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 0.8, canvasWidth: 280 });
	  } else {
		  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 1, canvasWidth: 860 });
	  }
  }

  componentWillReceiveProps(nextProps) {
	  if ((nextProps.object.id !== this.props.object.id) || (nextProps.object.id !== null && this.state.id === '')) {this.setState({ id: nextProps.object.id }); }
	  if ((nextProps.object.fid !== this.props.object.fid && nextProps.object.fid !== null) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.object.fid }); }
	  if ((nextProps.object.name_nummer !== this.props.object.name_nummer && nextProps.object.name_nummer !== null) || (nextProps.object.name_nummer && this.state.name_nummer === '')) {this.setState({ name_nummer: nextProps.object.name_nummer }); }
	  if ((nextProps.object.id_oberflaechenzulauf !== this.props.object.id_oberflaechenzulauf && nextProps.object.id_oberflaechenzulauf !== null) || (nextProps.object.id_oberflaechenzulauf !== null && this.state.id_oberflaechenzulauf === '')) {this.setState({ id_oberflaechenzulauf: nextProps.object.id_oberflaechenzulauf }); }
	  if ((nextProps.object.id_material !== this.props.object.id_material && nextProps.object.id_material !== null) || (nextProps.object.id_material && this.state.id_material === '')) {this.setState({ id_material: nextProps.object.id_material }); }
	  if ((nextProps.object.id_function !== this.props.object.id_function && nextProps.object.id_function !== null) || (nextProps.object.id_function && this.state.id_function === '')) {this.setState({ id_function: nextProps.object.id_function }); }
	  if ((nextProps.object.id_nutzung !== this.props.object.id_nutzung && nextProps.object.id_nutzung !== null) || (nextProps.object.id_nutzung && this.state.id_nutzung === '')) {this.setState({ id_nutzung: nextProps.object.id_nutzung }); }
	  if ((nextProps.object.id_status !== this.props.object.id_status && nextProps.object.id_status !== null) || (nextProps.object.id_status && this.state.id_status === '')) {this.setState({ id_status: nextProps.object.id_status }); }
	  if ((nextProps.object.id_form !== this.props.object.id_form && nextProps.object.id_form !== null) || (nextProps.object.id_form && this.state.id_form === '')) {this.setState({ id_form: nextProps.object.id_form }); }
	  if ((nextProps.object.id_lage !== this.props.object.id_lage && nextProps.object.id_lage !== null) || (nextProps.object.id_lage && this.state.id_lage === '')) {this.setState({ id_lage: nextProps.object.id_lage }); }
	  if ((nextProps.object.dimension_1 !== this.props.object.dimension_1 && nextProps.object.dimension_1 !== null) || (nextProps.object.dimension_1 && this.state.dimension_1 === '')) {this.setState({ dimension_1: nextProps.object.dimension_1 }); }
	  if ((nextProps.object.dimension_2 !== this.props.object.dimension_2 && nextProps.object.dimension_2 !== null) || (nextProps.object.dimension_2 && this.state.dimension_2 === '')) {this.setState({ dimension_2: nextProps.object.dimension_2 }); }
	  if ((nextProps.object.depth !== this.props.object.depth && nextProps.object.depth !== null) || (nextProps.object.depth && this.state.depth === '')) {this.setState({ depth: nextProps.object.depth }); }
	  if ((nextProps.object.standort !== this.props.object.standort && nextProps.object.standort !== null) || (nextProps.object.standort && this.state.standort === '')) {this.setState({ standort: nextProps.object.standort }); }
	  if ((nextProps.object.bemerkung !== this.props.object.bemerkung && nextProps.object.bemerkung !== null) || (nextProps.object.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.object.bemerkung }); }
      //Zugang
	  if ((nextProps.object.id_zugang !== this.props.object.id_zugang && nextProps.object.id_zugang !== null) || (nextProps.object.id_zugang && this.state.id_zugang === '')) {this.setState({ id_zugang: nextProps.object.id_zugang }); }
	  if (nextProps.object.id_zugang === 1) {this.setState({ dependZugang: false }); }
      // Einlauf
      if ((!equal(this.props.object.einlauf, nextProps.object.einlauf) && (nextProps.object.einlauf)) || (this.state.einlauf && nextProps.object.einlauf && this.state.einlauf.length === 0)) {this.setState({ einlauf: nextProps.object.einlauf }); }
      if (this.state.einlauf && nextProps.object.einlauf && this.state.einlauf.length > 0) {this.setState({ einlauf_cnt: nextProps.object.einlauf.length, einlauf_exist: true }); }
      // Auslauf
      if ((!equal(this.props.object.auslauf, nextProps.object.auslauf) && (nextProps.object.auslauf)) || (this.state.auslauf && nextProps.object.auslauf && this.state.auslauf.length === 0)) {this.setState({ auslauf: nextProps.object.auslauf }); }
      if (this.state.auslauf && nextProps.object.auslauf && this.state.auslauf.length > 0) {this.setState({ auslauf_cnt: nextProps.object.auslauf.length, auslauf_exist: true }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
	// Zugang frei zum Schacht
	if (name === 'id_zugang' && value === 1) {
	  this.setState({ dependZugang: false });
	} else if (name === 'id_zugang' && value !== 1) {
      this.setState({ dependZugang: true });
    }
  }
  
  handleArrayChangeEinlauf = index => event => {
    const { einlauf } = this.state;
    const newEinlauf = einlauf.slice(0); 
    newEinlauf[index][event.target.name] = event.target.value;
    this.setState({ einlauf: newEinlauf });
  }
  
  handleArrayChangeAuslauf = index => event => {
    const { auslauf } = this.state;
    const newAuslauf = auslauf.slice(0); 
    newAuslauf[index][event.target.name] = event.target.value;
    this.setState({ auslauf: newAuslauf });
  }

  handleCheckChange(event) {
    const {name, checked} = event.target
    this.setState({
      [name]: checked
    })
  }
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }
  
  handleCanvasChange(event) {
    this.setState({
      canvasArray: this.state.canvasObject.getSaveData()
    })
  }


  handleDragChange = (e) => {
    // Change Angle
	let radians = Math.atan2(e.target.y(), e.target.x()) + (Math.PI/2);
	if ( radians < 0 ) {
      radians += (2 * Math.PI);
    }
	let angle = Math.round( radians * (200/Math.PI));
	// Save to state
	let name = e.target.parent.attrs.name;
	let objs = this.state[name];
	let obj = objs[e.target.id()-1];
	obj.richtung = angle;
//	obj.y = e.target.y();
	objs[e.target.id()-1] = obj;
	this.setState({objs});
  };
  
  handleDragEnd(e) {
    let name = e.target.parent.attrs.name;
	let objs = this.state[name];
	let obj = objs[e.target.id()-1];
	obj.x = Math.round(e.target.x());
	obj.y = Math.round(e.target.y());
	objs[e.target.id()-1] = obj;
	this.setState({objs});
  };
  
   handleDragEndDeckel(e) {
	this.setState({
      deckel_x: Math.round(e.target.x()),
	  deckel_y: Math.round(e.target.y()),
    })
  };
  
  addArrayElemet(name) {
    let objName = name
	let nameCnt = [objName] + '_cnt'
    let objCnt = this.state[nameCnt]
	let objCntNew = +objCnt + 1;
	let shortValue = objName.charAt(0).toUpperCase()
	let objs = this.state[objName];
	let obj = {	name: shortValue + objCntNew, nummer: objCntNew, kaliber: '', abstich: '', bemerkung: '', id_material: '', richtung: '', x: 0, y: 0}
	objs.push( obj );
	this.setState({objs});
    this.setState({[nameCnt]: objCntNew})
  };
  
  deleteArrayElemet(name, index) {
	let objName = name
	let nameCnt = [objName] + '_cnt'
    let objCnt = this.state[nameCnt]
	let objCntNew = +objCnt - 1;
	let objs = this.state[objName];
    objs.splice(index, 1)
    this.setState({objs});
	this.setState({[nameCnt]: objCntNew})
  };
  
  dragBoundFuncCircle(pos) {

    var x = 160;
    var y = 160;
    var radius = 140;
    var scale = radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
		
    if (scale < 0.99 || scale > 1.01)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }
  
  dragBoundFuncCircleDeckel(pos) {

    var x = 160;
    var y = 160;
    var radius = 80;
    var scale = radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
		
    if (scale < 1)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }

  dragBoundFuncEllipse(pos) {
    var x = 160;
    var y = 160;
	var a = 100;
	var b = 140;
	let radians = Math.atan2(pos.y - y, pos.x - x);
	var ellipse_x = a * Math.cos(radians);
	var ellipse_y = b * Math.sin(radians);
	var distance = Math.sqrt(Math.pow(ellipse_x, 2) + Math.pow(ellipse_y, 2))
    var scale = distance / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
	
    if (scale < 0.99 || scale > 1.01)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }
  
  dragBoundFuncEllipseDeckel(pos) {
    var x = 160;
    var y = 160;
	var a = 40;
	var b = 80;
	let radians = Math.atan2(pos.y - y, pos.x - x);
	var ellipse_x = a * Math.cos(radians);
	var ellipse_y = b * Math.sin(radians);
	var distance = Math.sqrt(Math.pow(ellipse_x, 2) + Math.pow(ellipse_y, 2))
    var scale = distance / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
	
    if (scale < 1)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }

  onImgDrop(picture) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(picture),
	  pictures: this.state.pictures.concat(picture),
    });
  }

  windowGoBack() {
    window.history.back();
  }

  handleNext = () => {
	  
    if (this.state.activeStep === 0 && this.state.id_zugang !== 1) {
		
	  let EditData = {
		db_schema: this.props.objInfo.db_schema,
        id: this.state.id,
		fid: this.state.fid,
        name_nummer: this.state.name_nummer,
        id_zugang: this.state.id_zugang,
		id_lage: this.state.id_lage,
		standort: this.state.standort,
		bemerkung: this.state.bemerkung
      };
	  
	  if (EditData.id_lage === '') {EditData.id_lage = null}
	  if (EditData.fid === '') {EditData.fid = null}
      this.props.updateAbwasserSchachtZugang(EditData);
	  
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }
      this.setState({ loading: false, activeStep: 0 })
	  this.windowGoBack(); 
	}
	  
	if (this.state.activeStep === 0 && this.state.id_zugang === 1) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
	}
	if (this.state.activeStep === 1) {
		
		if (!this.state.einlauf_exist) {
		  let ein
		  let einlauf = []
		  for (let i=1; i <= this.state.einlauf_cnt; i++) {
			ein = { name: 'E' + i, nummer: i, kaliber: '', abstich: '', bemerkung: '', id_material: '', richtung: '', x: 0, y: 0}
			einlauf.push( ein );
		  }
		  this.setState({
            einlauf: this.state.einlauf.concat(einlauf),
			einlauf_exist: true
          })
        }
		if (!this.state.auslauf_exist) {
		  let aus
		  let auslauf = []
		  for (let i=1; i <= this.state.auslauf_cnt; i++) {
			aus = {	name: 'A' + i, nummer: i, kaliber: '', abstich: '', bemerkung: '', id_material: '', richtung: 0, x: 0, y: -140}
			auslauf.push( aus );
		  }
		  this.setState({
            auslauf: this.state.auslauf.concat(auslauf),
			auslauf_exist: true
          })
		}
        this.setState({ activeStep: this.state.activeStep + 1 });
	}
	
	if (this.state.activeStep === 2 && !this.state.withControl) {
	  let EditData = {
		db_schema: this.props.objInfo.db_schema,
        id: this.state.id,
		fid: this.state.fid,
        name_nummer: this.state.name_nummer,
        id_material: this.state.id_material,
        id_function: this.state.id_function,
        id_form: this.state.id_form,
        id_zugang: this.state.id_zugang,
        id_nutzung: this.state.id_nutzung,
        id_status: this.state.id_status,
        id_oberflaechenzulauf: this.state.id_oberflaechenzulauf,
		id_lage: this.state.id_lage,
        dimension_1: this.state.dimension_1,
        dimension_2: this.state.dimension_2,
        depth: this.state.depth,
		standort: this.state.standort,
		bemerkung: this.state.bemerkung,
		deckel_x: this.state.deckel_x,
		deckel_y: this.state.deckel_y,
		einlauf: this.state.einlauf,
		auslauf: this.state.auslauf,
		canvas_img: this.state.canvasArray,
		id_deckel_material: this.state.id_deckel_material,
		id_deckel_form: this.state.id_deckel_form,
		dimension_1_deckel: this.state.dimension_1_deckel,
		dimension_2_deckel: this.state.dimension_2_deckel
      };
	  
	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.id_material === '') {EditData.id_material = null}
	  if (EditData.id_function === '') {EditData.id_function = null}
	  if (EditData.id_form === '') {EditData.id_form = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  if (EditData.id_nutzung === '') {EditData.id_nutzung = null}
	  if (EditData.id_status === '') {EditData.id_status = null}
	  if (EditData.id_oberflaechenzulauf === '') {EditData.id_oberflaechenzulauf = null}
	  if (EditData.id_lage === '') {EditData.id_lage = null}
	  if (EditData.dimension_1 === '') {EditData.dimension_1 = null}
	  if (EditData.dimension_2 === '') {EditData.dimension_2 = null}
	  if (EditData.depth === '') {EditData.depth = null}
	  if (EditData.standort === '') {EditData.standort = null}
	  if (EditData.bemerkung === '') {EditData.bemerkung = null}
	  if (EditData.deckel_x === '') {EditData.deckel_x = null}
	  if (EditData.deckel_y === '') {EditData.deckel_y = null}
	  if (!EditData.einlauf) {EditData.einlauf = []}
	  if (!EditData.auslauf) {EditData.auslauf = []}
	  if (!EditData.canvas_img) {EditData.canvas_img = []}
	  if (EditData.id_deckel_material === '') {EditData.id_deckel_material = null}
	  if (EditData.id_deckel_form === '') {EditData.id_deckel_form = null}
	  if (EditData.dimension_1_deckel === '') {EditData.dimension_1_deckel = null}
	  if (EditData.dimension_2_deckel === '') {EditData.dimension_2_deckel = null}
	  
      this.props.updateAbwasserSchachtAttr(EditData);
	  
	  this.setState({ activeStep: 0 })
	  this.windowGoBack(); 
	}

    if (this.state.activeStep === 4) {	

	  let CreateData = {
		  
		// Zustand Schacht
		db_schema: this.props.objInfo.db_schema,
	    id_schacht: this.state.id,
		ist_deckel_ersetze: this.state.ist_deckel_ersetze,
		ist_deckel_maengel: this.state.ist_deckel_maengel,
		ist_deckel_defekt: this.state.ist_deckel_defekt,
		ist_deckel_korrodiert: this.state.ist_deckel_korrodiert,
		ist_deckel_klemmt: this.state.ist_deckel_klemmt,
		ist_deckel_verschraubt: this.state.ist_deckel_verschraubt,
		ist_deckel_belueftung: this.state.ist_deckel_belueftung,
		ist_deckel_verschluss: this.state.ist_deckel_verschluss,
		ist_deckel_rahmen_ersetzte: this.state.ist_deckel_rahmen_ersetzte,
		ist_deckel_rahmen_lose: this.state.ist_deckel_rahmen_lose,
		ist_deckel_rahmen_maengel: this.state.ist_deckel_rahmen_maengel,
		ist_deckel_rahmen_mangel_unterbetoniert: this.state.ist_deckel_rahmen_mangel_unterbetoniert,
		bemerkung_deckel: this.state.bemerkung_deckel,
		ist_schacht_maengel: this.state.ist_schacht_maengel,
		ist_schacht_einstiegshilfe: this.state.ist_schacht_einstiegshilfe,
		ist_schacht_undicht: this.state.ist_schacht_undicht,
		ist_schacht_bankett: this.state.ist_schacht_bankett,
		ist_schacht_sanierung: this.state.ist_schacht_sanierung,
		ist_schacht_massnahmen: this.state.ist_schacht_massnahmen,
		ist_schacht_maengel_schachthals: this.state.ist_schacht_maengel_schachthals,
		ist_schacht_maengel_schachtrohrfugen: this.state.ist_schacht_maengel_schachtrohrfugen,
		ist_schacht_maengel_seitl_anschl_einfuhr: this.state.ist_schacht_maengel_seitl_anschl_einfuhr,
		ist_schacht_maengel_seitl_anschl_verputzt: this.state.ist_schacht_maengel_seitl_anschl_verputzt,
		ist_schacht_maengel_einlauf_verputzt: this.state.ist_schacht_maengel_einlauf_verputzt,
		ist_schacht_maengel_auslauf_verputzt: this.state.ist_schacht_maengel_auslauf_verputzt,
		ist_schacht_einstiegshilfe_notwendig: this.state.ist_schacht_einstiegshilfe_notwendig,
		ist_schacht_einstiegshilfe_verrostet: this.state.ist_schacht_einstiegshilfe_verrostet,
		ist_schacht_einstiegshilfe_zustand: this.state.ist_schacht_einstiegshilfe_zustand,
		ist_schacht_undicht_schachtrohr: this.state.ist_schacht_undicht_schachtrohr,
		ist_schacht_undicht_konus: this.state.ist_schacht_undicht_konus,
		ist_schacht_durchlaufrinne: this.state.ist_schacht_durchlaufrinne,
		ist_schacht_durchlaufrinne_ablagerung: this.state.ist_schacht_durchlaufrinne_ablagerung,
		ist_schacht_durchlaufrinne_ausgebildet: this.state.ist_schacht_durchlaufrinne_ausgebildet,
		ist_schacht_durchlaufrinne_notwendig: this.state.ist_schacht_durchlaufrinne_notwendig,
		ist_schacht_grundwassereintritt: this.state.ist_schacht_grundwassereintritt,
		ist_schacht_tauchbogen: this.state.ist_schacht_tauchbogen,
		ist_schacht_tauchbogen_notwendig: this.state.ist_schacht_tauchbogen_notwendig,
		ist_schacht_tauchbogen_entfernen: this.state.ist_schacht_tauchbogen_entfernen,
		ist_schacht_tauchbogen_defekt: this.state.ist_schacht_tauchbogen_defekt,
		id_schacht_einstiegshilfe_typ: this.state.id_schacht_einstiegshilfe_typ,
		bemerkung_schacht: this.state.bemerkung_schacht,
      };
	  
	  if (CreateData.id_schacht_einstiegshilfe_typ === '') {CreateData.id_schacht_einstiegshilfe_typ = null}
	  
      this.props.createAbwasserSchachtKontrolle(CreateData);
      this.setState({ activeStep: this.state.activeStep + 1 });
	  
    }
    if (this.state.activeStep === 5) {	

	  let EditData = {
		db_schema: this.props.objInfo.db_schema,
        id: this.state.id,
		fid: this.state.fid,
        name_nummer: this.state.name_nummer,
        id_material: this.state.id_material,
        id_function: this.state.id_function,
        id_form: this.state.id_form,
        id_zugang: this.state.id_zugang,
        id_nutzung: this.state.id_nutzung,
        id_status: this.state.id_status,
        id_oberflaechenzulauf: this.state.id_oberflaechenzulauf,
		id_lage: this.state.id_lage,
        dimension_1: this.state.dimension_1,
        dimension_2: this.state.dimension_2,
        depth: this.state.depth,
		standort: this.state.standort,
		bemerkung: this.state.bemerkung,
		deckel_x: this.state.deckel_x,
		deckel_y: this.state.deckel_y,
		einlauf: this.state.einlauf,
		auslauf: this.state.auslauf,
		canvas_img: this.state.canvasArray,
		id_deckel_material: this.state.id_deckel_material,
		id_deckel_form: this.state.id_deckel_form,
		dimension_1_deckel: this.state.dimension_1_deckel,
		dimension_2_deckel: this.state.dimension_2_deckel
      };
	  
	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.id_material === '') {EditData.id_material = null}
	  if (EditData.id_function === '') {EditData.id_function = null}
	  if (EditData.id_form === '') {EditData.id_form = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  if (EditData.id_nutzung === '') {EditData.id_nutzung = null}
	  if (EditData.id_status === '') {EditData.id_status = null}
	  if (EditData.id_oberflaechenzulauf === '') {EditData.id_oberflaechenzulauf = null}
	  if (EditData.id_lage === '') {EditData.id_lage = null}
	  if (EditData.dimension_1 === '') {EditData.dimension_1 = null}
	  if (EditData.dimension_2 === '') {EditData.dimension_2 = null}
	  if (EditData.depth === '') {EditData.depth = null}
	  if (EditData.standort === '') {EditData.standort = null}
	  if (EditData.bemerkung === '') {EditData.bemerkung = null}
	  if (EditData.deckel_x === '') {EditData.deckel_x = null}
	  if (EditData.deckel_y === '') {EditData.deckel_y = null}
	  if (!EditData.einlauf) {EditData.einlauf = []}
	  if (!EditData.auslauf) {EditData.auslauf = []}
	  if (!EditData.canvas_img) {EditData.canvas_img = []}
	  if (EditData.id_deckel_material === '') {EditData.id_deckel_material = null}
	  if (EditData.id_deckel_form === '') {EditData.id_deckel_form = null}
	  if (EditData.dimension_1_deckel === '') {EditData.dimension_1_deckel = null}
	  if (EditData.dimension_2_deckel === '') {EditData.dimension_2_deckel = null}
	  
      this.props.updateAbwasserSchachtAttr(EditData);
	  
	  this.setState({ activeStep: 0 })
	  this.windowGoBack(); 
       
    } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.goBack();
  }
  

  render() {
    const object = this.props.object;
	let buttonNext;
    if ((this.state.activeStep === 0 && this.state.id_zugang !== 1 ) || (this.state.activeStep === 2 && !this.state.withControl) || (this.state.activeStep === 5)) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3, 4, 5, 6].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleCheckChange={this.handleCheckChange}
						  handleRadioChange={this.handleRadioChange}
						  handleArrayChangeEinlauf={this.handleArrayChangeEinlauf}
						  handleArrayChangeAuslauf={this.handleArrayChangeAuslauf}
						  handleCanvasChange={this.handleCanvasChange}
						  handleDragChange={this.handleDragChange}
						  handleDragEnd={this.handleDragEnd}
						  handleDragEndDeckel={this.handleDragEndDeckel}
						  dragBoundFuncCircle={this.dragBoundFuncCircle}
						  dragBoundFuncCircleDeckel={this.dragBoundFuncCircleDeckel}
						  dragBoundFuncEllipse={this.dragBoundFuncEllipse}
						  dragBoundFuncEllipseDeckel={this.dragBoundFuncEllipseDeckel}
						  addArrayElemet={this.addArrayElemet}
						  deleteArrayElemet={this.deleteArrayElemet}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  materialSchacht={this.props.abwSchachtMaterial}
						  materialDeckel={this.props.abwDeckelMaterial}
						  funktion={this.props.abwSchachtFunktion}
						  einstiegshilfe={this.props.abwSchachtEinstiegshilfe}
						  form={this.props.abwSchachtForm}
						  nutzung={this.props.abwSchachtNutzung}
						  status={this.props.abwSchachtStatus}
						  oberflaechenzulauf={this.props.abwSchachtOberflaechenzulauf}
						  lage={this.props.abwSchachtLage}
						  zugang={this.props.abwSchachtZugang}
						  einlauf={this.state.einlauf}
						  auslauf={this.state.einlauf}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						<Divider />
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={2} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={10} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button-back" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object,
  uploads: state.uploads,
  abwSchachtMaterial: state.abw.abwSchachtMaterial,
  abwDeckelMaterial: state.abw.abwDeckelMaterial,
  abwSchachtFunktion: state.abw.abwSchachtFunktion,
  abwSchachtForm: state.abw.abwSchachtForm,
  abwSchachtNutzung: state.abw.abwSchachtNutzung,
  abwSchachtStatus: state.abw.abwSchachtStatus,
  abwSchachtOberflaechenzulauf: state.abw.abwSchachtOberflaechenzulauf,
  abwSchachtZugang: state.abw.abwSchachtZugang,
  abwSchachtEinstiegshilfe: state.abw.abwSchachtEinstiegshilfe,
  abwSchachtLage: state.abw.abwSchachtLage

});

export default connect(
  mapStateToProps,
    { uploadImg,
	  createPhoto,
      updateAbwasserSchachtAttr,
	  updateAbwasserSchachtZugang,
      createAbwasserSchachtKontrolle,
      getAbwasserMaterialSchacht,
	  getAbwasserMaterialDeckel,
	  getAbwasserSchachtFunktion,
	  getAbwasserSchachtForm,
	  getAbwasserSchachtNutzung,
	  getAbwasserSchachtStatus,
	  getAbwasserSchachtOberflaechenzulauf,
	  getAbwasserSchachtZugang,
	  getAbwasserSchachtEinstiegshilfe,
      getAbwasserSchachtLage	  }
)(AbwSchachtStepper);