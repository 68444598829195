import axios from "axios";

import {
  CREATE_ABW_SCHACHT_KONTROLLE,
  UPDATE_ABW_SCHACHT_ATTR,
  UPDATE_ABW_SCHACHT_ZUGANG,
  UPDATE_ABW_SCHACHT_KEK,
  GET_ABW_SCHACHT_MATERIAL,
  GET_ABW_DECKEL_MATERIAL,
  GET_ABW_SCHACHT_FUNKTION,
  GET_ABW_SCHACHT_FORM,
  GET_ABW_SCHACHT_NUTZUNG,
  GET_ABW_SCHACHT_STATUS,
  GET_ABW_SCHACHT_OBERFLAECHENZULAUF,
  GET_ABW_SCHACHT_ZUGANG,
  GET_ABW_SCHACHT_EINSTIEGSHILFE,
  GET_ABW_SCHACHT_LAGE,
  GET_ABW_SCHACHT_SCHACHTBEREICH,
  GET_ABW_SCHACHT_SCHADENCODE
} from "./types";

// Create Schacht Kontrolle
export const createAbwasserSchachtKontrolle = schachtData => dispatch => {
  axios
    .post("/api/abw/schacht/create/kontrolle", schachtData)
    .then(res =>
      dispatch({
        type: CREATE_ABW_SCHACHT_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht
export const updateAbwasserSchachtAttr = schachtData => dispatch => {
  axios
    .patch("/api/abw/schacht/update/attr", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_ABW_SCHACHT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht Zugang
export const updateAbwasserSchachtZugang = schachtData => dispatch => {
  axios
    .patch("/api/abw/schacht/update/zugang", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_ABW_SCHACHT_ZUGANG,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht KEK
export const updateAbwasserSchachtKEK = schachtData => dispatch => {
  axios
    .patch("/api/abw/schacht/update/kek", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_ABW_SCHACHT_KEK,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

export const getAbwasserMaterialSchacht = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/material/schacht/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_MATERIAL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_MATERIAL,
        payload: null
      })
    );
};

export const getAbwasserMaterialDeckel = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/material/deckel/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_DECKEL_MATERIAL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_DECKEL_MATERIAL,
        payload: null
      })
    );
};

export const getAbwasserSchachtFunktion = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/funktion/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_FUNKTION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_FUNKTION,
        payload: null
      })
    );
};

export const getAbwasserSchachtForm = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/form/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_FORM,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_FORM,
        payload: null
      })
    );
};

export const getAbwasserSchachtNutzung = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/nutzung/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_NUTZUNG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_NUTZUNG,
        payload: null
      })
    );
};

export const getAbwasserSchachtStatus = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/status/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_STATUS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_STATUS,
        payload: null
      })
    );
};

export const getAbwasserSchachtOberflaechenzulauf = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/oberflaechenzulauf/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_OBERFLAECHENZULAUF,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_OBERFLAECHENZULAUF,
        payload: null
      })
    );
};

export const getAbwasserSchachtZugang = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/zugang/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_ZUGANG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_ZUGANG,
        payload: null
      })
    );
};

export const getAbwasserSchachtEinstiegshilfe = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/einstiegshilfe/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_EINSTIEGSHILFE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_EINSTIEGSHILFE,
        payload: null
      })
    );
};

export const getAbwasserSchachtLage = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/lage/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_LAGE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_LAGE,
        payload: null
      })
    );
};


export const getAbwasserSchachtSchachtbereich = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/schachtbereich/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_SCHACHTBEREICH,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_SCHACHTBEREICH,
        payload: null
      })
    );
};

export const getAbwasserSchachtSchadencode = (db_schema) => dispatch => {
  axios
    .get(`/api/abw/schacht/schadencode/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_ABW_SCHACHT_SCHADENCODE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ABW_SCHACHT_SCHADENCODE,
        payload: null
      })
    );
};
