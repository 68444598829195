import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, useHistory, Link} from "react-router-dom";

//Actions
import { getObjects, createObject, updateObjectGeom, updateObjectAttr, deleteObject } from "../../actions/objectActions";
//Material UI
import { TextField, TableContainer, Table, TableBody, TableRow, TableCell, Button, StylesProvider, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@material-ui/core";

import equal from 'fast-deep-equal'

// Karte
import { LayersControl, LayerGroup, Map, TileLayer, WMSTileLayer, CircleMarker, Popup, ZoomControl, FeatureGroup} from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import Control from 'react-leaflet-control';
import LocateControl from './LocateControl';
import { PixiOverlay } from 'react-leaflet-pixi-overlay';

import { EditControl } from "react-leaflet-draw";
import drawLocal from './drawLocal';
import Spinner from "../layout/Spinner/Spinner";

// Icons
import { IconContext } from "react-icons";
import { FaPlus } from 'react-icons/fa';

// Style
import 'leaflet-draw/dist/leaflet.draw.css'
import 'react-leaflet-markercluster/dist/styles.min.css';
import './Map.css';



const { BaseLayer, Overlay } = LayersControl;

type Position = [number, number]

type Props = {|
  content: string,
  position: Position,
  radius: number,
|}

type MarkerData = {| ...Props, key: string |}

const MyPopupMarker = ({ content, position }: Props) => (
  <CircleMarker id={content.id} center={position} color={content.color} fillColor="white" fillOpacity={0.8} radius={8}>
    <Popup>
	  <h4><b>{content.object_name} (ID = {content.id})</b></h4>
	  <StylesProvider injectFirst>
	  <TableContainer className="tooltip-table-container" component={Paper}>
	    <Table>
		  <TableBody>
		    <TableRow className="tooltip-table-row">
			  <TableCell className="tooltip-table-head" component="th" scope="row">ID</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.id}</TableCell>
			</TableRow>
		    <TableRow>
			  <TableCell className="tooltip-table-head" component="th" scope="row">Bezeichnung</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.name_nummer}</TableCell>
			</TableRow>
		    <TableRow>
			  <TableCell className="tooltip-table-head" component="th" scope="row">Standort</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.standort}</TableCell>
			</TableRow>
		    <TableRow>
			  <TableCell className="tooltip-table-head" component="th" scope="row">Erledigt</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.finished}</TableCell>
			</TableRow>
	      </TableBody>
		</Table>
	  </TableContainer>
	  <a onClick={() => {window.location.href=`/workflow/${content.category}/${content.object}/${content.db_schema}/${content.id}` }}>
	  <Button size="small" variant="contained">
	      Protokoll
        </Button>
	  </a>
	  </StylesProvider>
	</Popup>
  </CircleMarker>
)


const MyMarkersList = ({ markers }: { markers: Array<MarkerData> }) => {
  const items = obj.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} />
  ))
  return <Fragment>{items}</Fragment>
}

type State = {
  markers: Array<MarkerData>,
}

let obj = [];
let i = 0;
let j = 0;


class Karte extends Component {
	

  constructor(props) {
    super(props);
    this.state = {
	  height: '',
	  viewport: {center:["47.077451", "7.312646"], zoom: "15"},
	  radius: 10,
	  isDialogOpen: false,
	  db_schema: '',
	  project_id: null,
	  object_id: null,
	  object_table: '',
	  object_url: '',
	  category_short: '',
	  newObjectName: '',
	  newObjectLocation: ''
	}
	this.handleChange = this.handleChange.bind(this);
	this.handleDialogCancel = this.handleDialogCancel.bind(this);
	this.handleDialogSave = this.handleDialogSave.bind(this);
  }

  updateDimensions() {
    const height = window.innerHeight - 50
    this.setState({ height: height })
  }

  componentWillMount() {
//	  localStorage.setItem("viewport", this.state.viewport);

    this.updateDimensions();
  }
  

  componentDidMount() {
    if (typeof this.props.project.db_schema == "undefined" && typeof this.props.project.project_id == "undefined" && typeof this.props.project.object_id == "undefined") {
	  this.props.getObjects(localStorage.getItem('db_schema'), localStorage.getItem('project_id'), localStorage.getItem('object_id'));
	  this.setState({
		  db_schema: localStorage.getItem('db_schema'),
		  project_id: localStorage.getItem('project_id'),
		  object_id: localStorage.getItem('object_id'),
		  object_table: localStorage.getItem('object_table'),
		  object_url: localStorage.getItem('object_url'),
		  category_short: localStorage.getItem('category_short'),
//		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
	  });
	  if (localStorage.getItem('start_lat') && localStorage.getItem('start_long') && localStorage.getItem('start_zoom')) {
		this.setState({
		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
		});
	  }
	}
	else if (this.state.db_schema === '' && this.state.project_id === null && this.state.object_id === null) {
	  this.props.getObjects(this.props.project.db_schema, this.props.project.project_id, this.props.project.object_id);
	  this.setState({
		  db_schema: localStorage.getItem('db_schema'),
		  project_id: localStorage.getItem('project_id'),
		  object_id: localStorage.getItem('object_id'),
		  object_table: localStorage.getItem('object_table'),
		  object_url: localStorage.getItem('object_url'),
		  category_short: localStorage.getItem('category_short'),
		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
	  });
    }
    window.addEventListener("resize", this.updateDimensions.bind(this))
	let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.remove("top-nav-invisibile"); }
  }
  
  componentDidUpdate(prevProps) {
	if(!equal(this.props.projects.project, prevProps.projects.project)) {
	  this.props.getObjects(this.props.project.db_schema, this.props.project.project_id, this.props.project.object_id);
	  this.setState({
		  db_schema: localStorage.getItem('db_schema'),
		  project_id: localStorage.getItem('project_id'),
		  object_id: localStorage.getItem('object_id'),
		  object_table: localStorage.getItem('object_table'),
		  object_url: localStorage.getItem('object_url'),
		  category_short: localStorage.getItem('category_short'),
		  viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}
	  });
    }
  } 

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  // Handle Change bei Create-Dialog
  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }
  
  _onCreated = (e) => {
    let layer = e.layer;
	let NewObject = {
      name_nummer: "NewObjekt",
	  db_schema: this.state.db_schema,
	  table: this.state.object_table,
	  id_project: this.state.project_id,
	  id_object: this.state.object_id,
      lat: layer._latlng.lat,
	  lng: layer._latlng.lng,
    };
    this.props.createObject(NewObject);
    this.setState({
      isDialogOpen: true
    });
  }
  

  _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer( (layer) => {
	  let EditData = {
        id: layer.options.id,
	    db_schema: this.state.db_schema,
	    table: this.state.object_table,
        lat: layer._latlng.lat,
	    lng: layer._latlng.lng
      };
      this.props.updateObjectGeom(EditData);
	  numEdited += 1;	
    });
    console.log(`_onEdited: edited ${numEdited} layers`, e);
  }
  
  _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer( (layer) => {
	  this.props.deleteObject(this.state.db_schema, this.state.category_short, this.state.object_url, layer.options.id);
	  numDeleted += 1;	
    });
    console.log(`onDeleted: removed ${numDeleted} layers`, e);
  }

  onViewportChanged = (viewport: Viewport) => {
    this.setState({ viewport })
  }
  
  handleDialogCancel = () => {
	this.props.deleteObject(this.state.db_schema, this.state.category_short, this.state.object_url, this.props.objects.newObject.id);
	this.props.objects.newObject = {};
    this.setState({
		isDialogOpen: false,
		newObjectLocation: '',
		newObjectName: ''
	});
  }
	
  handleDialogSave = () => {
	// Save to DB
	let EditAttr = {
      id: this.props.objects.newObject.id,
	  db_schema: this.state.db_schema,
	  table: this.state.object_table,
      name_nummer: this.state.newObjectName,
	  standort: this.state.newObjectLocation
    };
	this.props.updateObjectAttr(EditAttr)
	// Push to obj-array
	obj.push({
			key: this.props.objects.newObject.id,
			position: [this.props.objects.newObject.lat, this.props.objects.newObject.lng],
			content: {
				id: this.props.objects.newObject.id, 
				name_nummer: this.state.newObjectName,
				standort: this.state.newObjectLocation,
				color: 'blue',
				finished: 'Nein',
			    category: this.state.category_short,
				object: this.state.object_url,
			    db_schema: this.state.db_schema,
		}
	});
    this.setState({
		isDialogOpen: false,
		newObjectLocation: '',
		newObjectName: ''
	});
    this.props.objects.newObject = {};
  }
  
  state = {obj}
  // Render
  render() {
    const { objects, objectsLoading, newObject } = this.props.objects;

// Overlwy Layer nach Thema
    let overlayLayer
    let overlayLayer2
	if (this.state.db_schema === 'swg' && this.state.category_short == 'was') {
		overlayLayer = (
	  	  <Overlay checked name="Wasser">
		    <WMSTileLayer
              layers='swg_was_wp'
		      format='image/png'
              url="https://wms.rswag.ch/swg/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'lyss' && this.state.category_short == 'abw') {
		overlayLayer = (
	  	  <Overlay checked name="Abwasser">
		    <WMSTileLayer
              layers='rsw_306_abw'
		      format='image/png'
              url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'rsw' && this.state.category_short == 'ew') {
		overlayLayer = (
	  	  <Overlay checked name="Elektro">
		    <WMSTileLayer
              layers='lkmap_306_ew'
		      format='image/png'
              url="https://wms.rswag.ch/lyss/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'rsw' && this.state.category_short == 'was') {
		overlayLayer = (
	  	  <Overlay checked name="Wasser">
		    <WMSTileLayer
              layers='lkmap_306_was'
		      format='image/png'
              url="https://wms.rswag.ch/lyss/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'bueetigen' && this.state.category_short == 'ew') {
		overlayLayer = (
	  	  <Overlay checked name="Elektro">
		    <WMSTileLayer
              layers='rsw_382_ew'
		      format='image/png'
              url="https://wms.rswag.ch/client/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'seelandgas' && this.state.category_short == 'gas') {
		overlayLayer = (
	  	  <Overlay checked name="Gas">
		    <WMSTileLayer
              layers='seelandgas_wp'
		      format='image/png'
              url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'aarberg' && this.state.category_short == 'abw') {
		overlayLayer = (
	  	  <Overlay checked name="Gas">
		    <WMSTileLayer
              layers='rsw_301_abw'
		      format='image/png'
              url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	} else if (this.state.db_schema === 'meikirch' && this.state.category_short == 'abw') {
		overlayLayer = (
	  	  <Overlay checked name="Abwasser">
		    <WMSTileLayer
              layers='rsw_307_abw'
		      format='image/png'
              url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		      transparent='true'
            />
          </Overlay>
		);
	} else if (this.state.db_schema === 'diessbach' && this.state.category_short == 'abw') {
		overlayLayer = (
	  	  <Overlay checked name="Abwasser">
		    <WMSTileLayer
              layers='rsw_385_abw'
		      format='image/png'
              url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		      transparent='true'
            />
          </Overlay>
		);
		overlayLayer2 = (
	  	  <Overlay name="Eigentum">
		    <WMSTileLayer
              layers='rsw_307_abw_eigentum'
		      format='image/png'
              url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		      transparent='true'
            />
          </Overlay>
      );
	}

// Bezeichnung mit erstem Buchstaben Gross
	let objectValueCap;
	if (this.state.object_url) {
		objectValueCap = this.state.object_url.charAt(0).toUpperCase() + this.state.object_url.slice(1)
    };

// Einlesen der Daten in Array
    if (Array.isArray(objects) && objectsLoading === false) {
      obj.length = 0
	  for(let i=0; i < objects.length; i++) {
		let color;
		let finished;
		if (objects[i].erledigt) {color = 'green'} else {color = 'blue'};
		if (objects[i].erledigt) {finished = 'Ja'} else {finished = 'Nein'};
		// Import der Daten
		if (objects[i].lat && objects[i].lng) {
			obj.push({
				key: i,
				position: [objects[i].lat, objects[i].lng],
				content: {
					id: objects[i].id, 
					name_nummer: objects[i].name_nummer,
					standort: objects[i].standort,
					finished: finished,
					color: color,
					category: this.state.category_short,
					object: this.state.object_url,
					object_name: objectValueCap,
					db_schema: this.state.db_schema,
				}
			});
		}
      }
	}
	const object = {}

// Dialog zu Erfassen der eines Punktes
	let dialogNew
	if (newObject === null || Object.keys(newObject).length === 0) {
      dialogNew = <Spinner />;
    } else {
	   dialogNew = (
        <>
		<div>
	      <Dialog
		     disableBackdropClick
             disableEscapeKeyDown
             open={this.state.isDialogOpen}
             aria-labelledby="form-dialog-title"
             aria-describedby="form-dialog-description"
          >
		  <Paper className="create-dialog" elevation={5}>
            <DialogTitle id="form-dialog-title">Objekt (ID = {newObject.id}) erstellt</DialogTitle>
			<Divider />
			<TextField
                label="Name / Nummer"
                name="newObjectName"
                variant="outlined"
                required
                fullWidth
				value={this.state.newObjectName}
                onChange={this.handleChange}
				id='newObjectName'
				className="create-input"
            />
			<TextField
                label="Standort"
                name="newObjectLocation"
                variant="outlined"
                required
                fullWidth
				value={this.state.newObjectLocation}
                onChange={this.handleChange}
				id='newObjectLocation'
				className="create-input"
            />
              <DialogActions>
                <Button onClick={this.handleDialogSave} variant="contained" color="primary">
                  Speichern
                </Button>
                <Button onClick={this.handleDialogCancel} variant="contained" >
                  Abbrechen
                </Button>
              </DialogActions>
			  </Paper>
          </Dialog>
		  </div>
        </>
      );
	}
    return (
    <div className="map-wrapper" style={{ height: this.state.height }}>
      <Map
	    preferCanvas={true}
		viewport={this.state.viewport}
		minZoom={13}
		maxZoom={21}
		zoomControl={false}>
      <ZoomControl position="topright" />
	  <Control position="topleft" >
		<LocateControl /> 
	    <div className="refresh-map leaflet-bar">
	      <a className="leaflet-bar-part leaflet-bar-part-single" onClick={() => window.location.reload(false)}></a>
		</div>
      </Control>
	  <Control position="topleft" >

      </Control>
	    <LayersControl position="topleft">
	  	  <Overlay checked name={objectValueCap}>
			<LayerGroup id="lg1">
	          <FeatureGroup >
                 <EditControl
                   position='topleft'
                   onEdited={this._onEdited}
                   onCreated={this._onCreated}
                   onDeleted={this._onDeleted}
                   draw={{
                     rectangle: false,
		             marker: false,
		             circle: false,
		             polyline: false,
		             polygon: false,
		             circlemarker: {
			           color: 'red',
				       radius: 12
			         }
                   }}
                />
                <MyMarkersList />
            </FeatureGroup>
	  	  </LayerGroup>
        </Overlay>
		{overlayLayer2}
		{overlayLayer}
      </LayersControl>
      <LayersControl key={'baselayer'} className="control-baselayer" position="bottomright">
        <BaseLayer checked name="AV-GD">
          <WMSTileLayer
		    layers='MBSF,LNNA,LOCPOS,HADR,SOPT,SOLI,SOSF,LCSF'
		    format='image/png'
            url="https://geodienste.ch/db/av_0?"
		    transparent='true'
			srs='EPSG:3857'
          />
		</BaseLayer>
	    <BaseLayer name="Orthofoto">
          <TileLayer
             url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
          />
		</BaseLayer>
        <BaseLayer name="AV">
          <WMSTileLayer
		    layers='av_sw'
		    format='image/png'
            url="https://wms.rswag.ch/client/wms?"
		    transparent='true'
			srs='EPSG:3857'
          />
		</BaseLayer>
      </LayersControl>
      </Map>
      <div>{dialogNew}</div>
	  </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects,	
  project: state.projects.project,	
  objects: state.objects,
  object: state.objects.object
});

export default
  connect(
    mapStateToProps,
    { getObjects,
      createObject,
	  updateObjectGeom,
	  updateObjectAttr,
      deleteObject
    }
  )(Karte);