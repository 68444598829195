import React from "react";

import spinner from "../../../img/spinner.gif";

import "./SpinnerOverlay.scss";

const SpinnerOverlay = () => {
  return (
    <div className="spinner">
      <img className="indicator" src={spinner} alt="Loading..." />
    </div>
  );
};

export default SpinnerOverlay;