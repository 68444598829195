import axios from "axios";

import {
  GET_OBJECTS,
  OBJECTS_LOADING,
  GET_OBJECT,
  GET_OBJECT_DS,
  OBJECT_LOADING,
  CREATE_OBJECT,
  UPDATE_OBJECT_GEOM,
  UPDATE_OBJECT_ATTR,
  DELETE_OBJECT
} from "./types";

// GET alle Objekte für Karte und Tabelle
export const getObjects = (db_schema, project_id, id) => dispatch => {
  dispatch(setObjectsLoading());
  axios
    .get(`/api/object/${db_schema}/project/${project_id}/object/${id}`)
    .then(res =>
      dispatch({
        type: GET_OBJECTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_OBJECTS,
        payload: null
      })
    );
};

// Objects loading
export const setObjectsLoading = () => {
  return {
    type: OBJECTS_LOADING
  };
};

// GET alle Objekte für Karte und Tabelle
export const getObject = (category, object, db_schema, id) => dispatch => {
  dispatch(setObjectLoading());
  axios
    .get(`/api/${category}/${object}/${db_schema}/${id}`)
    .then(res =>
      dispatch({
        type: GET_OBJECT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_OBJECT,
        payload: null
      })
    );
};

// GET alle Objekte für Karte und Tabelle
export const getObjectDS = (category, object, db_schema, id) => dispatch => {
  dispatch(setObjectLoading());
  axios
    .get(`/api/${category}/${object}/datasheet/${db_schema}/${id}`)
    .then(res =>
      dispatch({
        type: GET_OBJECT_DS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_OBJECT_DS,
        payload: null
      })
    );
};

// Objects loading
export const setObjectLoading = () => {
  return {
    type: OBJECT_LOADING
  };
};

// CREATE Objekt
export const createObject = objectData => dispatch => {
  axios
    .post('/api/object/create', objectData)
    .then(res =>
      dispatch({
        type: CREATE_OBJECT,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// UPDATE Objekt Geometrie
export const updateObjectGeom = objectData => dispatch => {
  axios
    .patch('/api/object/update/geom', objectData)
    .then(res =>
      dispatch({
        type: UPDATE_OBJECT_GEOM,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

export const updateObjectAttr = EditAttr => dispatch => {
  axios
    .patch('/api/object/update/attr', EditAttr)
    .then(res =>
      dispatch({
        type: UPDATE_OBJECT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Delete Objekt
//export const deleteObject = objectData => dispatch => {
//	console.log(objectData)
//  axios
//    .delete('/api/object/delete', objectData)
//    .then(res =>
//      dispatch({
//        type: DELETE_OBJECT,
//        payload: res.data
//      })
//    )
//    .catch(err => console.log(err));
//};

// GET alle Objekte für Karte und Tabelle
export const deleteObject = (db_schema, category, object, id) => dispatch => {
  dispatch(setObjectsLoading());
  axios
    .delete(`/api/object/delete/${db_schema}/${category}/${object}/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_OBJECT,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};
