import {
  CREATE_EW_BELEUCHTUNG_KONTROLLE,
  CREATE_EW_SCHACHT_KONTROLLE,
  CREATE_EW_STATION_KONTROLLE,
  CREATE_EW_KABINE_KONTROLLE,
  UPDATE_EW_BELEUCHTUNG_ATTR,
  UPDATE_EW_SCHACHT_ATTR,
  UPDATE_EW_STATION_ATTR,
  UPDATE_EW_KABINE_ATTR,
  GET_EW_SCHACHT_EIGENTUEMER,
  GET_EW_SCHACHT_LAGEBESTIMMUNG,
  GET_EW_SCHACHT_TYP,
  GET_EW_SCHACHT_ZUGANG,
  GET_EW_ROHR_TYP
} from "../actions/types";

const initialState = {
  ewBeleuchtungKontrolle: [],
  ewSchachtKontrolle: [],
  ewStationKontrolle: [],
  ewKabineKontrolle: [],
  ewSchachtEigentuemer: [],
  ewSchachtLagebestimmung: [],
  ewSchachtTyp: [],
  ewSchachtZugang: [],
  ewRohrTyp: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_EW_BELEUCHTUNG_KONTROLLE:
      return {
        ...state,
		ewBeleuchtungKontrolle: action.payload
      };
    case CREATE_EW_SCHACHT_KONTROLLE:
      return {
        ...state,
		ewSchachtKontrolle: action.payload
      };
    case CREATE_EW_STATION_KONTROLLE:
      return {
        ...state,
		ewStationKontrolle: action.payload
      };
    case CREATE_EW_KABINE_KONTROLLE:
      return {
        ...state,
		ewKabineKontrolle: action.payload
      };
    case UPDATE_EW_BELEUCHTUNG_ATTR:
      return {
        ...state,
		beleuchtung: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_EW_SCHACHT_ATTR:
      return {
        ...state,
		schacht: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_EW_STATION_ATTR:
      return {
        ...state,
		station: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case UPDATE_EW_KABINE_ATTR:
      return {
        ...state,
		kabine: action.payload,
        objects: [action.payload, ...state.objects]
      };
    case GET_EW_SCHACHT_EIGENTUEMER:
      return {
        ...state,
        ewSchachtEigentuemer: action.payload,
      };
    case GET_EW_SCHACHT_LAGEBESTIMMUNG:
      return {
        ...state,
        ewSchachtLagebestimmung: action.payload,
      };
    case GET_EW_SCHACHT_TYP:
      return {
        ...state,
        ewSchachtTyp: action.payload,
      };
    case GET_EW_SCHACHT_ZUGANG:
      return {
        ...state,
        ewSchachtZugang: action.payload,
      };
    case GET_EW_ROHR_TYP:
      return {
        ...state,
        ewRohrTyp: action.payload,
      };
    default:
      return state;
  }
}