import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AppBar, Toolbar, Typography, Grid, Container, Paper, Box, Button } from "@material-ui/core";
import { Cancel } from '@material-ui/icons';

import Spinner from "../layout/Spinner/Spinner";
import AnlageSheet from "./was/anlage/Sheet";
import HydrantSheet from "./was/hydrant/Sheet";
import ReservoirSheet from "./was/reservoir/Sheet";
import SchieberSheet from "./was/schieber/Sheet";
import PumpwerkSheet from "./was/pumpwerk/Sheet";
import EwSchachtSheet from "./ew/schacht/Sheet";
import GasSchieberSheet from "./gas/schieber/Sheet";
import AVEinzelobjektSheet from "./av/einzelobjekt/Sheet";

import './Datasheet.scss';
import logo from "../../img/logo_mobile.png";

import { getObjectDS } from "../../actions/objectActions";

class Datasheet extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  category: this.props.match.params.category,
	  object: this.props.match.params.object,
	  db_schema: this.props.match.params.db_schema,
	  id: this.props.match.params.id
	}
  }
	  
  componentDidMount() {
	let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.add("top-nav-invisibile"); }
	this.props.getObjectDS(this.props.match.params.category, this.props.match.params.object, this.props.match.params.db_schema, this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.getObjectDS(this.props.match.params.category, this.props.match.params.object, this.props.match.params.db_schema, this.props.match.params.id);
    }
  }
  
  handleMap = () => {this.props.history.push("/map");}
  handleReset = () => {this.props.history.goBack();}


  render() {
	  const object = this.props.object;
	  const objectValue = this.props.match.params.object
	  const objectValueCap = objectValue.charAt(0).toUpperCase() + objectValue.slice(1)
	  let datasheetContent

    if (Object.keys(object).length === 0 && object.constructor === Object) {
      datasheetContent = <Spinner />;
    } else if (Object.keys(object).length > 0) {
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'anlage') {
		  datasheetContent = <AnlageSheet history={this.props.history} data={object.data} objInfo={this.state} />
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'hydrant') {
		  datasheetContent = <HydrantSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'reservoir') {
		  datasheetContent = <ReservoirSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'schieber') {
		  datasheetContent = <SchieberSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'was' && this.props.match.params.object === 'pumpwerk') {
		  datasheetContent = <PumpwerkSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'ew' && this.props.match.params.object === 'schacht') {
		  datasheetContent = <EwSchachtSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'gas' && this.props.match.params.object === 'schieber') {
		  datasheetContent = <GasSchieberSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	    if (this.props.match.params.category === 'av' && this.props.match.params.object === 'einzelobjekt') {
		  datasheetContent = <AVEinzelobjektSheet history={this.props.history} data={object.data} objInfo={this.state}/>
	    }
	}
	  
    return (
	    <div>
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                             <img src={logo} className="wf-logo-mobile" alt="logo" height="70px" />
                         </Grid>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>{objectValueCap}</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
	        <Box component="main" className="wf-box-wrapper">
                <Container maxWidth="md" className="wf-container">
                    <Paper elevation={5}>
						{datasheetContent}
				          <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button  size="small" variant="contained" onClick={this.handleMap}>
                                    Zur Karte
                                </Button>
                            </Grid>
						  </Grid>
                    </Paper>
                </Container>
            </Box>

	    </div>
    );
  }
}


Datasheet.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object
});

export default connect(
  mapStateToProps,
    { getObjectDS }
)(Datasheet);