import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Dialog, DialogTitle, DialogActions, Divider, Button, FormGroup, FormControlLabel, Checkbox, Box } from "@material-ui/core";
import { DoubleArrow } from '@material-ui/icons';

//Actions
import { getElektroSchachtEigentuemer, getElektroSchachtLagebestimmung, getElektroSchachtTyp, getElektroSchachtZugang } from "../../../../actions/ewActions";

import './Sheet.scss';

class EwSchachtSheet extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  isDialogControlOpen: false,
	  control: {}
	}
  }
  
  componentDidMount() {
	  this.props.getElektroSchachtEigentuemer(this.props.objInfo.db_schema);
	  this.props.getElektroSchachtLagebestimmung(this.props.objInfo.db_schema);
	  this.props.getElektroSchachtTyp(this.props.objInfo.db_schema);
	  this.props.getElektroSchachtZugang(this.props.objInfo.db_schema);
  }
  
  handleDialogControlOpen = (kontr) => {
	let control = this.props.data.kontrolle.find(item=>item.id === kontr.id);
    this.setState({
      control: control,
      isDialogControlOpen: true
    });
  }
	
  handleDialogControlClose = () => {
    this.setState({
      isDialogControlOpen: false
    });
  }
  
  render() {
	let objectList1;
	let objectList2;
	let photoList;
	let controlContainer;
	let controlDialog;

	
	if(typeof this.props.data !== "undefined" && typeof this.props.objInfo !== "undefined"){
	  const data = this.props.data;
	  const photos = this.props.data.photos;
	  const kontrolle = this.props.data.kontrolle;
	  // Attribute
	  let erledigtValue;
	  let lagebestimmungValue;
	  let zugangValue;
	  let typValue;
	  let eigentuemerValue;
	  let ausdehnungValue;
	  let actualControl;
	  if (data.erledigt === true) {erledigtValue = 'Ja'} else {erledigtValue = 'Nein'}
	  if (data.id_lagebestimmung && this.props.ewSchachtLagebestimmung.length > 0) { lagebestimmungValue = this.props.ewSchachtLagebestimmung.filter(entity => entity.id === data.id_lagebestimmung)[0].value }
	  if (data.id_zugang && this.props.ewSchachtZugang.length > 0) { zugangValue = this.props.ewSchachtZugang.filter(entity => entity.id === data.id_zugang)[0].value }
	  if (data.id_typ && this.props.ewSchachtTyp.length > 0) { typValue = this.props.ewSchachtTyp.filter(entity => entity.id === data.id_typ)[0].value }
	  if (data.id_eigentuemer && this.props.ewSchachtEigentuemer.length > 0) { eigentuemerValue = this.props.ewSchachtEigentuemer.filter(entity => entity.id === data.id_eigentuemer)[0].value }
	  if (data.dimension_1 && data.dimension_2) { 
	     ausdehnungValue = data.dimension_1 + ' x ' + data.dimension_2
	  } else if (data.dimension_2) {
		 ausdehnungValue = data.dimension_2
	  } else if (data.dimension_1) {
		 ausdehnungValue = data.dimension_1
	  }

	  // Kontrolle
	  if (kontrolle && kontrolle.length > 0) {
		let controlList = [];
		for (let i=0; i < kontrolle.length; i++){
		  //Datum
		  let controlDate = new Date(kontrolle[i].created_at);
	      let newControlDate = ("0"+(String(controlDate.getDate()))).slice(-2) + '.' + ("0"+(String(controlDate.getMonth()+1))).slice(-2) + '.' + String(controlDate.getFullYear());
     	  controlList[i] = {
			"id": kontrolle[i].id,
			"datum": newControlDate
          };
        }
		actualControl = controlList[0].datum
	  }  
	  
	  let dataJSON1 = {
	    ID: data.id,
		Bezeichnung: data.name_nummer,
		Standort: data.standort,
		Erledigt: erledigtValue,
		Kontrolle: actualControl,
		Zugang: zugangValue,
	  };

	  let dataJSON2 = {
		Typ: typValue,
		Eigentümer: eigentuemerValue,
		Lagebestimmung: lagebestimmungValue,
		Ausdehnung: ausdehnungValue,
		Überdeckung: data.ueberdeckung,
		Baujahr: data.baujahr
	  };
	  
	  objectList1 = Object.keys(dataJSON1).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON1[key]} />
                </ListItem>
      );
	  objectList2 = Object.keys(dataJSON2).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON2[key]} />
                </ListItem>
      );

      // Photos
	  if (typeof photos !== "undefined" && photos !== null && photos.length > 0) {
		  photoList =  photos.map(photo => (
             <Grid key={photo.upload_at} className="photo-grid" item xs={12} sm={6} md={4}>
			   <img src={photo.pfad + '/' + photo.name} className="photo" alt={photo.name_orig} />
			 </Grid>
		  ));
	  }
	}
	  
	return (
        <>
          <Grid className="object-container" container spacing={2}>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Attribute
              </Typography>
              <Grid className="item-container" container spacing={2}>
			    <Grid item xs={12} sm={6} md={6}>
                  <List dense={true}>
            	  	{objectList1}
                  </List>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
                  <List dense={true}>
            	  	{objectList2}
                  </List>
				</Grid>
              </Grid>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Fotos
              </Typography>
              <Grid className="photo-container" container spacing={1}>
                {photoList}
              </Grid>
            </Grid>
          </Grid>
		  <div>{controlDialog}</div>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ewSchachtEigentuemer: state.ew.ewSchachtEigentuemer,
  ewSchachtLagebestimmung: state.ew.ewSchachtLagebestimmung,
  ewSchachtTyp: state.ew.ewSchachtTyp,
  ewSchachtZugang: state.ew.ewSchachtZugang,
});

export default connect(
  mapStateToProps,
    { getElektroSchachtEigentuemer,
      getElektroSchachtLagebestimmung,
      getElektroSchachtTyp,
      getElektroSchachtZugang
	  }
)(EwSchachtSheet);