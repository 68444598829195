import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AppBar, Toolbar, Typography, Grid, Container, Paper, Box, } from "@material-ui/core";

import Spinner from "../../../layout/Spinner/Spinner";
import Stepper from "./Stepper";

import './Manhole.scss';
import logo from "../../../../img/logo_mobile.png";

//import { getAVFixpunkt } from "../../../../actions/avActions";
import { getObject } from "../../../../actions/objectActions";

class Manhole extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  id: this.props.match.params.id,
	  db_schema: this.props.match.params.schema
	}
  }
	  
componentDidMount() {
	this.props.getObject('abw', 'schacht', this.props.match.params.schema, this.props.match.params.id);
//	this.props.getAVFixpunkt(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
//      this.props.getAVFixpunkt(this.props.match.params.id);
	  this.props.getObject('abw', 'schacht', this.props.match.params.schema, this.props.match.params.id);
    }
  }


  render() {

//	  const manhole = this.props.objects.object;
//	  const manhole = {};
	  const { object, objectLoading } = this.props.objects;
	  const manhole = object;

	  let stepperContent

	if (manhole === null) {
      stepperContent = <Spinner />;
    } else if (Object.keys(manhole).length === 0 && manhole.constructor === Object) {
      stepperContent = <Spinner />;
    } else if (Object.keys(manhole).length > 0) {
	  stepperContent = <Stepper history={this.props.history} objInfo={this.state} />
	}
	  
    return (
	    <div>
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                             <img src={logo} className="wf-logo-mobile" alt="logo" height="70px" />
                         </Grid>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>Schacht</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
	        <Box component="main" className="wf-box-wrapper">
                <Container maxWidth="md" className="wf-container">
                    <Paper elevation={5}>
						{stepperContent}
                    </Paper>
                </Container>
            </Box>
	    </div>
    );
  }
}

const mapStateToProps = state => ({
  objects: state.objects
});

export default connect(
  mapStateToProps,
    { getObject	}
)(Manhole);