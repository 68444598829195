import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, Checkbox, FormGroup, FormControlLabel, FormLabel, RadioGroup, Radio, StylesProvider, Divider} from "@material-ui/core";

import './Step.scss';


class MaintenanceManholeForm extends Component {
  
render() {
	
	let maengelSchacht
	if (this.props.values.ist_schacht_maengel === true) {
	maengelSchacht = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_maengel_schachthals' + this.props.values.id}
				name="ist_schacht_maengel_schachthals"
				checked={this.props.values.ist_schacht_maengel_schachthals}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Schachthals mangelhaft"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_maengel_schachtrohrfugen' + this.props.values.id}
				name="ist_schacht_maengel_schachtrohrfugen"
				checked={this.props.values.ist_schacht_maengel_schachtrohrfugen}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Schachtrohrfugen nicht vermörtelt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_maengel_seitl_anschl_einfuhr' + this.props.values.id}
				name="ist_schacht_maengel_seitl_anschl_einfuhr"
				checked={this.props.values.ist_schacht_maengel_seitl_anschl_einfuhr}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Seitliche Anschlüsse schlecht eingeführt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_maengel_seitl_anschl_verputzt' + this.props.values.id}
				name="ist_schacht_maengel_seitl_anschl_verputzt"
				checked={this.props.values.ist_schacht_maengel_seitl_anschl_verputzt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Seitliche Anschlüsse nicht verputzt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_maengel_einlauf_verputzt' + this.props.values.id}
				name="ist_schacht_maengel_einlauf_verputzt"
				checked={this.props.values.ist_schacht_maengel_einlauf_verputzt}
				onChange={this.props.handleCheckChange}
				color="default"
				variant="outlined"
              />
		     }
           label="Einlauf nicht verputzt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_maengel_auslauf_verputzt' + this.props.values.id}
				name="ist_schacht_maengel_auslauf_verputzt"
				checked={this.props.values.ist_schacht_maengel_auslauf_verputzt}
				onChange={this.props.handleCheckChange}
				color="default"
				variant="outlined"
              />
		     }
           label="Auslauf nicht verputzt"
           />
	    </FormGroup>
        </Grid>
		)
	} else {
		maengelSchacht = null
	}
	
    let optionEinstieghilfe
	if (this.props.values.ist_schacht_einstiegshilfe === true) {
	optionEinstieghilfe = (
      <Grid item xs={12} sm={6}>
	    <TextField
       id={'id_schacht_einstiegshilfe_typ' + this.props.values.id}
       select
       label="Typ"
		   name="id_schacht_einstiegshilfe_typ"
		   variant="outlined"
		   fullWidth
		   className="step-input"
            value={this.props.values.id_schacht_einstiegshilfe_typ}
            onChange={this.props.handleChange}
          >
            {this.props.einstiegshilfe.map((option) => (
              <MenuItem
               key={option.id}
               value={option.id}
               style={{
                  display: "block",
                  padding: "6px"
               }}
              >
                {option.value}
              </MenuItem>
            ))}
        </TextField>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_einstiegshilfe_verrostet' + this.props.values.id}
				name="ist_schacht_einstiegshilfe_verrostet"
				checked={this.props.values.ist_schacht_einstiegshilfe_verrostet}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="verrostet"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_einstiegshilfe_zustand' + this.props.values.id}
				name="ist_schacht_einstiegshilfe_zustand"
				checked={this.props.values.ist_schacht_einstiegshilfe_zustand}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="in schlechtem Zustand"
           />
	    </FormGroup>
        </Grid>
		)
	} else if (this.props.values.ist_schacht_einstiegshilfe === false) {
	optionEinstieghilfe = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_einstiegshilfe_notwendig' + this.props.values.id}
				name="ist_schacht_einstiegshilfe_notwendig"
				checked={this.props.values.ist_schacht_einstiegshilfe_notwendig}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="notwendig?"
           />
	    </FormGroup>
       </Grid>
		)	
		
	} else {	
		optionEinstieghilfe = null
	}
	
	let optionUndicht
	if (this.props.values.ist_schacht_undicht === true) {
	optionUndicht = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_undicht_schachtrohr' + this.props.values.id}
				name="ist_schacht_undicht_schachtrohr"
				checked={this.props.values.ist_schacht_undicht_schachtrohr}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Schachtrohr"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_undicht_konus' + this.props.values.id}
				name="ist_schacht_undicht_konus"
				checked={this.props.values.ist_schacht_undicht_konus}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Konus"
           />
	    </FormGroup>
        </Grid>
		)
	} else {	
		optionUndicht = null
	}
	
    let optionDurchlaufrinne
	if (this.props.values.ist_schacht_durchlaufrinne === true) {
	optionDurchlaufrinne = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_durchlaufrinne_ablagerung' + this.props.values.id}
				name="ist_schacht_durchlaufrinne_ablagerung"
				checked={this.props.values.ist_schacht_durchlaufrinne_ablagerung}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Ablagerungen"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_durchlaufrinne_ausgebildet' + this.props.values.id}
				name="ist_schacht_durchlaufrinne_ausgebildet"
				checked={this.props.values.ist_schacht_durchlaufrinne_ausgebildet}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="nicht gut ausgebildet"
           />
	    </FormGroup>
        </Grid>
		)
	} else if (this.props.values.ist_schacht_durchlaufrinne === false) {
	optionDurchlaufrinne = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_durchlaufrinne_notwendig' + this.props.values.id}
				name="ist_schacht_durchlaufrinne_notwendig"
				checked={this.props.values.ist_schacht_durchlaufrinne_notwendig}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="notwendig?"
           />
	    </FormGroup>
       </Grid>
		)
	} else {	
		optionDurchlaufrinne = null
	}
	
    let optionTauchbogen
	if (this.props.values.ist_schacht_tauchbogen === true) {
	optionTauchbogen = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_tauchbogen_entfernen' + this.props.values.id}
				name="ist_schacht_tauchbogen_entfernen"
				checked={this.props.values.ist_schacht_tauchbogen_entfernen}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="kann nicht entfernt werden"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_tauchbogen_defekt' + this.props.values.id}
				name="ist_schacht_tauchbogen_defekt"
				checked={this.props.values.ist_schacht_tauchbogen_defekt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Defekt"
           />
	    </FormGroup>
        </Grid>
		)
	} else if (this.props.values.ist_schacht_tauchbogen === false) {
	optionTauchbogen = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_schacht_tauchbogen_notwendig' + this.props.values.id}
				name="ist_schacht_tauchbogen_notwendig"
				checked={this.props.values.ist_schacht_tauchbogen_notwendig}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="notwendig?"
           />
	    </FormGroup>
       </Grid>
		)
	} else {	
		optionTauchbogen = null
	}

	
    return (
	  <>
        <Grid item xs={12}>
          <Typography variant="h6">Zustand Schacht</Typography>
        </Grid>
		<StylesProvider injectFirst>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend">Mängel vorhanden*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_schacht_maengel"
		  	    value={this.props.values.ist_schacht_maengel}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<Radio required={true} /> }
		  	      label="Ja"
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<Radio required={true} /> }
		  	      label="Nein"
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			</FormGroup>
		  </Grid>
          {maengelSchacht}
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend">Einstiegshilfe vorhanden (Tiefe > 1.2m zwingend)*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_schacht_einstiegshilfe"
		  	    value={this.props.values.ist_schacht_einstiegshilfe}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<Radio required={true} /> }
		  	      label="Ja"
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<Radio required={true} /> }
		  	      label="Nein"
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			</FormGroup>
		  </Grid>
          {optionEinstieghilfe}
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Undicht (Risse, Ausbrüche)*</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_schacht_undicht"
		  	   value={this.props.values.ist_schacht_undicht}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		         <FormControlLabel
		  	     value={true}
                 control={<Radio required={true} /> }
		  	     label="Ja"
		  	     labelPlacement="start"
                   />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio required={true} /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
                 />
		  	 </RadioGroup>
			</FormGroup>
		  </Grid>
          {optionUndicht}
	    </Grid>
		<Divider />
		<Grid container item xs={12} spacing={1}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Banket mangelhaft (Risse, ausgebrochen)*</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_schacht_bankett"
		  	   value={this.props.values.ist_schacht_bankett}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		       <FormControlLabel
		  	     value={true}
                 control={<Radio required={true} /> }
		  	     label="Ja"
		  	     labelPlacement="start"
               />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio required={true} /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
               />
		  	  </RadioGroup>
			</FormGroup>
		  </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12} spacing={1}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Durchlaufrinne vorhanden*</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_schacht_durchlaufrinne"
		  	   value={this.props.values.ist_schacht_durchlaufrinne}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		       <FormControlLabel
		  	     value={true}
                 control={<Radio required={true} /> }
		  	     label="Ja"
		  	     labelPlacement="start"
               />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio required={true} /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
               />
		  	  </RadioGroup>
			</FormGroup>
		  </Grid>
          {optionDurchlaufrinne}
	    </Grid>
		<Divider />
		<Grid container item xs={12} spacing={1}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Grundwassereintritt*</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_schacht_grundwassereintritt"
		  	   value={this.props.values.ist_schacht_grundwassereintritt}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		       <FormControlLabel
		  	     value={true}
                 control={<Radio required={true} /> }
		  	     label="Ja"
		  	     labelPlacement="start"
               />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio required={true} /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
		</Grid>
		<Divider />
		<Grid container item xs={12} spacing={1}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Tauchbogen vorhanden*</FormLabel>
		  	 <RadioGroup
               row
               aria-label="position"
		  	   name="ist_schacht_tauchbogen"
		  	   value={this.props.values.ist_schacht_tauchbogen}
		  	   onChange={this.props.handleRadioChange}
		  	 >
		       <FormControlLabel
		  	     value={true}
                 control={<Radio required={true} /> }
		  	     label="Ja"
		  	     labelPlacement="start"
               />
		  	   <FormControlLabel
		  	     value={false}
                 control={<Radio required={true} /> }
		  	     label="Nein"
		  	     labelPlacement="end"
		  	     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
          {optionTauchbogen}
		</Grid>
		</StylesProvider>
		<Grid item xs={12} sm={12}>
		  <TextField
            id={'bemerkung' + this.props.values.id}
		    name='bemerkung_schacht'
            label="Bemerkung"
            multiline
			fullWidth
			rows={4}
            value={this.props.values.bemerkung_schacht}
			onChange={this.props.handleChange}
			variant="outlined"
          />
		</Grid>
    </>
	)
}
}

export default MaintenanceManholeForm;
