import axios from "axios";

import {
  UPLOAD_IMG,
  UPLOAD_LOADING,
  CREATE_PHOTO
} from "./types";

//Upload Image
export const uploadImg = imageFormObj => dispatch => {
  axios
    .post("/api/upload", imageFormObj)
	.then(res => {
      dispatch({
        type: UPLOAD_IMG,
        payload: res.data
      })
	  console.log('action')
	  console.log(res)
    })
    .catch(err => console.log(err));
};

// Projects loading
export const setUploadImgLoading = () => {
  return {
    type: UPLOAD_LOADING
  };
};

export const createPhoto = photoData => dispatch => {
  axios
    .post("/api/upload/create/photo", photoData)
    .then(res =>
      dispatch({
        type: CREATE_PHOTO,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};