import React, { Component,  } from 'react';
import { connect } from "react-redux";
//import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider} from '@material-ui/core';

//Actions
import { uploadImg, createPhoto } from "../../../../actions/uploadActions";
import { updateWasserAnlageAttr, createWasserAnlageKontrolle, getWasserAnlageArt, getWasserAnlageMaterial, getWasserAnlageTyp } from "../../../../actions/wasActions";

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import ControlForm from "./Steps/Step2";
import SubmitForm from "./Steps/Step3";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleCheckChange, handleRadioChange, onImgDrop, values, wasAnlageTyp, wasAnlageArt, wasAnlageMaterial}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} values={values} wasAnlageTyp={wasAnlageTyp} wasAnlageArt={wasAnlageArt} wasAnlageMaterial={wasAnlageMaterial} onImgDrop={onImgDrop}/>;
        case 1:
            return <ControlForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 2:
            return <SubmitForm values={values} wasAnlageTyp={wasAnlageTyp} wasAnlageArt={wasAnlageArt} wasAnlageMaterial={wasAnlageMaterial} />;
        default:
            return <></>;
    }
}


class AnlageStepper extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Stepper
  	  step: 1,
      activeStep: 0,
	  
	  //Photos
	  photos: [],
	  selectedImg: [],

	  //Anlage
	  id: '',
	  fid: '',
	  name_nummer: '',
	  standort: '',
	  bemerkung: '',
	  id_art: '',
	  id_material: '',
	  id_typ: '',
	  leistung: '',

      //Kontrolle
      ist_maengel: null,
      ist_maengel_defekt: false,
      ist_maengel_klemmt: false,
      ist_maengel_verschaubt: false,
      ist_maengel_korrodiert: false,
      ist_einstiegshilfe: null,
      ist_einstiegshilfe_notwendig: false,
      ist_einstiegshilfe_verrostet: false,
      ist_einstiegshilfe_zustand: false,
      ist_sanierung: null,
      ist_massnahmen: null,
      bemerkung_kontrolle: ''
	}
	this.handleChange = this.handleChange.bind(this);
	this.handleCheckChange = this.handleCheckChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

  componentDidMount() {
	  this.props.getWasserAnlageArt(this.props.objInfo.db_schema);
	  this.props.getWasserAnlageMaterial(this.props.objInfo.db_schema);
	  this.props.getWasserAnlageTyp(this.props.objInfo.db_schema);
  }
  
  componentWillReceiveProps(nextProps) {
	  if ((nextProps.object.id !== this.props.object.id) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ id: nextProps.object.id }); }
	  if ((nextProps.object.fid !== this.props.object.fid && nextProps.object.fid !== null) || (nextProps.object.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.object.fid }); }
	  if ((nextProps.object.name_nummer !== this.props.object.name_nummer && nextProps.object.name_nummer !== null) || (nextProps.object.name_nummer && this.state.name_nummer === '')) {this.setState({ name_nummer: nextProps.object.name_nummer }); }
	  if ((nextProps.object.standort !== this.props.object.standort && nextProps.object.standort !== null) || (nextProps.object.standort && this.state.standort === '')) {this.setState({ standort: nextProps.object.standort }); }
	  if ((nextProps.object.bemerkung !== this.props.object.bemerkung && nextProps.object.bemerkung !== null) || (nextProps.object.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.object.bemerkung }); }
	  if ((nextProps.object.id_art !== this.props.object.id_art && nextProps.object.id_art !== null) || (nextProps.object.id_art && this.state.id_art === '')) {this.setState({ id_art: nextProps.object.id_art }); }
	  if ((nextProps.object.id_material !== this.props.object.id_material && nextProps.object.id_material !== null) || (nextProps.object.id_material && this.state.id_material === '')) {this.setState({ id_material: nextProps.object.id_material }); }
	  if ((nextProps.object.id_typ !== this.props.object.id_typ && nextProps.object.id_typ !== null) || (nextProps.object.id_typ && this.state.id_typ === '')) {this.setState({ id_typ: nextProps.object.id_typ }); }

  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }

  handleCheckChange(event) {
    const {name, checked} = event.target
    this.setState({
      [name]: checked
    })
  }
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }

  onImgDrop(photo) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(photo),
	  photos: this.state.photos.concat(photo),
    });
  }

  handleNext = () => {
	  
	if (this.state.activeStep === 0) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		let fileName = newDate + '-' + nr + '-' + this.state.selectedImg[i].name;
	    imageFormObj.append('nummer', nr)
		imageFormObj.append('date', newDate)
        imageFormObj.append('file', this.state.selectedImg[i])
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_object: this.state.id,
		  name: fileName,
		  name_orig: this.state.selectedImg[i].name,
		  nummer: nr,
		  date: newDate,
		  pfad: '/uploads',
		  category: this.props.objInfo.category,
		  db_schema: this.props.objInfo.db_schema,
		  object: this.props.objInfo.object
        };
        this.props.createPhoto(PhotoData)
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
	}

    if (this.state.activeStep === 1) {	

	  let CreateData = {
		// Kontrolle
		db_schema: this.props.objInfo.db_schema,
        id_anlage: this.state.id,
        ist_maengel: this.state.ist_maengel,
        ist_maengel_defekt: this.state.ist_maengel_defekt,
        ist_maengel_klemmt: this.state.ist_maengel_klemmt,
        ist_maengel_verschaubt: this.state.ist_maengel_verschaubt,
        ist_maengel_korrodiert: this.state.ist_maengel_korrodiert,
        ist_einstiegshilfe: this.state.ist_einstiegshilfe,
        ist_einstiegshilfe_notwendig: this.state.ist_einstiegshilfe_notwendig,
        ist_einstiegshilfe_verrostet: this.state.ist_einstiegshilfe_verrostet,
        ist_einstiegshilfe_zustand: this.state.ist_einstiegshilfe_zustand,
        ist_sanierung: this.state.ist_sanierung,
        ist_massnahmen: this.state.ist_massnahmen,
        bemerkung_kontrolle: this.state.bemerkung_kontrolle
      };
	  
      this.props.createWasserAnlageKontrolle(CreateData);
      this.setState({ activeStep: this.state.activeStep + 1 });
	  
    }
    if (this.state.activeStep === 2) {	
      // Anlage
	  let EditData = {
		db_schema: this.props.objInfo.db_schema,
		id: this.state.id,
	    fid: this.state.fid,
	    name_nummer: this.state.name_nummer,
	    standort: this.state.standort,
	    bemerkung: this.state.bemerkung,
	    id_art: this.state.id_art,
	    id_material: this.state.id_material,
	    id_typ: this.state.id_typ,
	    leistung: this.state.leistung
      };
	  
	  if (EditData.id_art === '') {EditData.id_art = null}
	  if (EditData.id_material === '') {EditData.id_material = null}
	  if (EditData.id_typ === '') {EditData.id_typ = null}
	  if (EditData.leistung === '') {EditData.leistung = null}
	  
      this.props.updateWasserAnlageAttr(EditData);  
	  this.setState({ activeStep: 0 })
	  this.props.history.push("/map");       
    } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.goBack();
  }
  

  render() {
	  console.log(this.props)
	  console.log(this.props.object)
	let buttonNext;
    if (this.state.activeStep === 2) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleCheckChange={this.handleCheckChange}
						  handleRadioChange={this.handleRadioChange}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  wasAnlageTyp={this.props.wasAnlageTyp}
						  wasAnlageMaterial={this.props.wasAnlageMaterial}
						  wasAnlageArt={this.props.wasAnlageArt}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  object: state.objects.object,
  wasAnlageArt: state.wasser.wasAnlageArt,
  wasAnlageMaterial: state.wasser.wasAnlageMaterial,
  wasAnlageTyp: state.wasser.wasAnlageTyp,
  photos: state.photos
});

export default connect(
  mapStateToProps,
    { uploadImg,
	  createPhoto,
      updateWasserAnlageAttr,
	  createWasserAnlageKontrolle,
	  getWasserAnlageArt,
	  getWasserAnlageMaterial,
	  getWasserAnlageTyp,
	  }
)(AnlageStepper);