import React, { Component } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider} from "@material-ui/core";

import './Step.scss';

class SubmitForm extends Component {

render() {
	let values = this.props.values
	let fidValue = '-'
	let nameNummerValue = '-'
	let standortValue = '-'
	let bemerkungValue = '-'
	let typValue = '-'
	let versorgungsdruckValue = '-'
	let betriebsdruckValue = '-'
	if (values.fid) { fidValue = values.fid }
	if (values.name_nummer) { nameNummerValue = values.name_nummer }
	if (values.standort) { standortValue = values.standort }
	if (values.bemerkung) { bemerkungValue = values.bemerkung }
	if (values.id_typ) { typValue = this.props.wasHydrantTyp.filter(entity => entity.id === values.id_typ)[0].value }
	if (values.versorgungsdruck) { versorgungsdruckValue = values.versorgungsdruck }
	if (values.betriebsdruck) { betriebsdruckValue = values.betriebsdruck }

  
    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Übersicht</Typography>
              </Grid>
			  <Grid item xs={12} sm={6}>
			    <List>
                  <ListItem>
                    <ListItemText primary="Feature-ID" />
					<ListItemText primary={fidValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bezeichnung" />
					<ListItemText primary={nameNummerValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Standort" />
					<ListItemText primary={standortValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bemerkung" />
					<ListItemText primary={bemerkungValue} />
                  </ListItem>
                </List>
                <Divider />
			    <List>
                  <ListItem>
                    <ListItemText primary="Typ" />
					<ListItemText primary={typValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Versorgungsdruck" />
					<ListItemText primary={versorgungsdruckValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Betriebsdruck" />
					<ListItemText primary={betriebsdruckValue} />
                  </ListItem>
                </List>
              </Grid>
			</>
        );
    }
}

export default SubmitForm;