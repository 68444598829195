import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem } from "@material-ui/core";
import ImageUploader from 'react-images-upload';

import './Step.scss';

class AttributForm extends Component {
  
render() {
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Attribute</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                label="Bezeichnung"
                name="name_nummer"
                variant="outlined"
                required
                fullWidth
				value={this.props.values.name_nummer}
                onChange={this.props.handleChange}
				id={'name_nummer' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Feature-ID"
                name="fid"
                variant="outlined"
                fullWidth
				id={'fid' + this.props.values.id}
				value={this.props.values.fid}
                onChange={this.props.handleChange}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Standort"
                name="standort"
                variant="outlined"
                fullWidth
				id={'standort' + this.props.values.id}
				value={this.props.values.standort}
                onChange={this.props.handleChange}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'typ' + this.props.values.id}
            select
            label="Typ"
		    name="id_typ"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_typ}
            onChange={this.props.handleChange}
          >
            {this.props.wasReservoirTyp.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Überlaufhöhe"
            name="ueberlaufhoehe"
            variant="outlined"
            fullWidth
		    id={'ueberlaufhoehe' + this.props.values.id}
		    value={this.props.values.ueberlaufhoehe}
            onChange={this.props.handleChange}
		    className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Fassungsvermögen"
            name="fassungsvermoegen"
            variant="outlined"
            fullWidth
		    id={'fassungsvermoegen' + this.props.values.id}
		    value={this.props.values.fassungsvermoegen}
            onChange={this.props.handleChange}
		    className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Brauchwasserreserve"
            name="brauchwasserreserve"
            variant="outlined"
            fullWidth
		    id={'brauchwasserreserve' + this.props.values.id}
		    value={this.props.values.brauchwasserreserve}
            onChange={this.props.handleChange}
		    className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Löschwasserreserve"
            name="loeschwasserreserve"
            variant="outlined"
            fullWidth
		    id={'loeschwasserreserve' + this.props.values.id}
		    value={this.props.values.loeschwasserreserve}
            onChange={this.props.handleChange}
		    className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
			id={'bemerkung' + this.props.values.id}
            label="Bemerkung"
            name="bemerkung"
            variant="outlined"
            fullWidth
			value={this.props.values.bemerkung}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Fotos</Typography>
        </Grid>
		<Grid item xs={12}>
           <ImageUploader
               withIcon={true}
			   withPreview={true}
               buttonText='Choose images'
               onChange={this.props.onImgDrop}
               imgExtension={['.jpg', '.gif', '.png', '.gif']}
               maxFileSize={5242880}
           />
		 </Grid>
    </>
	)
}
}

export default AttributForm;
