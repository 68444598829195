import React, { Component } from 'react';
import { TextField, Grid, Typography, FormGroup, FormControlLabel, FormLabel, FormHelperText, RadioGroup, Radio, StylesProvider, Divider, withStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

import './Step.scss';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class MonthControlForm extends Component {
  
render() {

    return (
	  <>
        <Grid item xs={12}>
          <Typography className="form-titel" variant="h6">Kontrolle - Monat</Typography>
        </Grid>
		<StylesProvider injectFirst>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel" >Gerät*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_monat_geraete"
		  	    value={this.props.values.ist_monat_geraete}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Funktionskontrolle von Absorbtionsentfeuchter (inkl. Filtermatte), Heizung, Druckwind-kessel (inkl. Wasserstand, Solldruck bei min. 2bar, Überdruckventil), Notleuchte, Klimaanlage</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_monat_geraete_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_monat_geraete_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
			  value={this.props.values.ist_monat_geraete_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		      <FormLabel component="legend" className="check-titel">Dokumentation*</FormLabel>
		  	  <RadioGroup
                row
                aria-label="position"
		  	    name="ist_monat_dokumentation"
		  	    value={this.props.values.ist_monat_dokumentation}
		  	    onChange={this.props.handleRadioChange}
		  	  >
		        <FormControlLabel
		  	      value={true}
                  control={<GreenRadio required={true} /> }
		  	      label="i.O."
		  	      labelPlacement="start"
                />
		  	    <FormControlLabel
		  	      value={false}
                  control={<RedRadio required={true} /> }
		  	      label="nicht i.O."
		  	      labelPlacement="end"
		  	      className="input-no"
                />
		  	  </RadioGroup>
			  <FormHelperText>Es sind folgende Kontrollablesungen vorzunehmen und festzuhalten:</FormHelperText>
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
            <TextField
			  id={'ist_monat_dokumentation_bemerkung' + this.props.values.id}
              label="Bemerkung"
              name="ist_monat_dokumentation_bemerkung"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
			  value={this.props.values.ist_monat_dokumentation_bemerkung}
              onChange={this.props.handleChange}
			  className="step-input"
            />
          </Grid>
	    </Grid>
		<Divider />
		<Grid item xs={12} sm={6}>
          <TextField
            label="Zählerstand"
            name="ist_monat_dok_zaehler"
            variant="outlined"
            fullWidth
			type="number"
			InputProps={{ inputProps: { min: 0.000, max: 100000.000, step: 0.001} }}
			id={'ist_monat_dok_zaehler' + this.props.values.id}
			value={this.props.values.ist_monat_dok_zaehler}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
		<Grid item xs={12} sm={6}>
          <TextField
            label="Pumpenstunden"
            name="ist_monat_dok_pumpstunden"
            variant="outlined"
            fullWidth
			type="number"
			InputProps={{ inputProps: { min: 0.000, max: 100000.000, step: 0.001} }}
			id={'ist_monat_dok_pumpstunden' + this.props.values.id}
			value={this.props.values.ist_monat_dok_pumpstunden}
            onChange={this.props.handleChange}
			className="step-input"
          />
        </Grid>
      </StylesProvider>
    </>
	)
}
}

export default MonthControlForm;
