import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Dialog, DialogTitle, DialogActions, Divider, Button, FormGroup, FormControlLabel, Checkbox, Box } from "@material-ui/core";
import { DoubleArrow } from '@material-ui/icons';

//Actions
import { getGasSchieberArt, getGasSchieberBeurteilung, getGasSchieberDeckel, getGasSchieberInspektion, getGasSchieberMaterial, getGasSchieberSchacht, getGasSchieberSchaltzustand, getGasSchieberStange, getGasSchieberTafel, getGasSchieberZugang, getGasDruckzone, getGasEigentuemer, getGasGemeinde, getGasStatus } from "../../../../actions/gasActions";


import './Sheet.scss';

class GasSchieberSheet extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  isDialogControlOpen: false,
	  control: {}
	}
  }
  
  componentDidMount() {
	  this.props.getGasSchieberArt(this.props.objInfo.db_schema);
	  this.props.getGasSchieberBeurteilung(this.props.objInfo.db_schema);
	  this.props.getGasSchieberDeckel(this.props.objInfo.db_schema);
	  this.props.getGasSchieberInspektion(this.props.objInfo.db_schema);
	  this.props.getGasSchieberMaterial(this.props.objInfo.db_schema);
	  this.props.getGasSchieberSchacht(this.props.objInfo.db_schema);
	  this.props.getGasSchieberSchaltzustand(this.props.objInfo.db_schema);
	  this.props.getGasSchieberStange(this.props.objInfo.db_schema);
	  this.props.getGasSchieberTafel(this.props.objInfo.db_schema);
	  this.props.getGasSchieberZugang(this.props.objInfo.db_schema);
	  this.props.getGasDruckzone(this.props.objInfo.db_schema);
	  this.props.getGasEigentuemer(this.props.objInfo.db_schema);
	  this.props.getGasGemeinde(this.props.objInfo.db_schema);
	  this.props.getGasStatus(this.props.objInfo.db_schema);  
  }
  
  handleDialogControlOpen = (kontr) => {
	let control = this.props.data.kontrolle.find(item=>item.id === kontr.id);
    this.setState({
      control: control,
      isDialogControlOpen: true
    });
  }
	
  handleDialogControlClose = () => {
    this.setState({
      isDialogControlOpen: false
    });
  }
  
  render() {
	let objectList;
	let photoList;
	let controlContainer;
	let controlDialog;
	
	if(typeof this.props.data !== "undefined" && typeof this.props.objInfo !== "undefined"){
	  const data = this.props.data;
	  const photos = this.props.data.photos;
	  const kontrolle = this.props.data.kontrolle;
	  // Attribute
	  let erledigtValue;
	  let artValue;
	  let druckzoneValue;
	  let schaltzustandValue;
	  let gemeindeValue;
	  let statusValue;
	  let materialValue;
	  let eigentuemerValue;

	  if (data.erledigt === true) {erledigtValue = 'Ja'} else {erledigtValue = 'Nein'}
	  if (data.id_art && this.props.gasSchieberArt.length > 0) { artValue = this.props.gasSchieberArt.filter(entity => entity.id === data.id_art)[0].value }
	  if (data.id_druckzone && this.props.gasDruckzone.length > 0) { druckzoneValue = this.props.gasDruckzone.filter(entity => entity.id === data.id_druckzone)[0].value }
	  if (data.id_schaltzustand && this.props.gasSchieberSchaltzustand.length > 0) { schaltzustandValue = this.props.gasSchieberSchaltzustand.filter(entity => entity.id === data.id_schaltzustand)[0].value }
	  if (data.id_gemeinde && this.props.gasGemeinde.length > 0) { gemeindeValue = this.props.gasGemeinde.filter(entity => entity.id === data.id_gemeinde)[0].name }
	  if (data.id_status && this.props.gasStatus.length > 0) { statusValue = this.props.gasStatus.filter(entity => entity.id === data.id_status)[0].value }
	  if (data.id_material && this.props.gasSchieberMaterial.length > 0) { materialValue = this.props.gasSchieberMaterial.filter(entity => entity.id === data.id_material)[0].value }
	  if (data.id_eigentuemer && this.props.gasEigentuemer.length > 0) { eigentuemerValue = this.props.gasEigentuemer.filter(entity => entity.id === data.id_eigentuemer)[0].value }
	  
	  let dataJSON = {
	    ID: data.id,
		Bezeichnung: data.name_nummer,
		Standort: data.standort,
		Gemeinde: gemeindeValue,
		Status: statusValue,
		Eigentümer: eigentuemerValue,
		Druckzone: druckzoneValue,
		Art: artValue,
		Material: materialValue,
		Schaltzustand: schaltzustandValue
	  };
	  
	  objectList = Object.keys(dataJSON).map(key =>
                <ListItem key={key} className="list-line">
                  <ListItemText className="list-bez" primary={key} />
		  		  <ListItemText className="list-attr" primary={dataJSON[key]} />
                </ListItem>
      );

	  // Kontrolle
	  if (kontrolle && kontrolle.length > 0) {
		let controlList = [];
		for (let i=0; i < kontrolle.length; i++){
		  //Datum
		  let controlDate = new Date(kontrolle[i].created_at);
	      let newControlDate = ("0"+(String(controlDate.getDate()))).slice(-2) + '.' + ("0"+(String(controlDate.getMonth()+1))).slice(-2) + '.' + String(controlDate.getFullYear());

     	  controlList[i] = {
			"id": kontrolle[i].id,
			"datum": newControlDate
          };
        }

	    controlContainer = controlList.map(kontr => (
           <ListItem key={kontr.id} className="list-line">
             <ListItemText className="list-bez" primary="Datum" />
		 	 <ListItemText className="list-attr" primary={kontr.datum} />
             <ListItem button onClick={() => this.handleDialogControlOpen(kontr)} className="list-button">
               <ListItemIcon>
                 <DoubleArrow />
               </ListItemIcon>
             </ListItem>
           </ListItem>
		));
		
		if(Object.keys(this.state.control).length > 0 && this.state.control.constructor === Object) {
			
		  let currentControl = this.state.control;
		  let currentControlList;
		  //Values
		  let zugangValue;
		  let tafelValue;
		  let deckelValue;
		  let schachtValue;
		  let stangeValue;
		  let beurteilungValue;
		  let inspektionValue;
		  
	      if (currentControl.id_zugang && this.props.gasSchieberZugang.length > 0) { zugangValue = this.props.gasSchieberZugang.filter(entity => entity.id === currentControl.id_zugang)[0].value }		  
	      if (currentControl.id_tafel_status && this.props.gasSchieberTafel.length > 0) { tafelValue = this.props.gasSchieberTafel.filter(entity => entity.id === currentControl.id_tafel_status)[0].value }
	      if (currentControl.id_deckel_status && this.props.gasSchieberDeckel.length > 0) { deckelValue = this.props.gasSchieberDeckel.filter(entity => entity.id === currentControl.id_deckel_status)[0].value }
	      if (currentControl.id_schacht_status && this.props.gasSchieberSchacht.length > 0) { schachtValue = this.props.gasSchieberSchacht.filter(entity => entity.id === currentControl.id_schacht_status)[0].value }
	      if (currentControl.id_stange_status && this.props.gasSchieberStange.length > 0) { stangeValue = this.props.gasSchieberStange.filter(entity => entity.id === currentControl.id_stange_status)[0].value }
	      if (currentControl.id_schieber_status && this.props.getGasSchieberBeurteilung.length > 0) { beurteilungValue = this.props.getGasSchieberBeurteilung.filter(entity => entity.id === currentControl.id_schieber_status)[0].value }
	      if (currentControl.id_inspektion_status && this.props.gasSchieberInspektion.length > 0) { inspektionValue = this.props.gasSchieberInspektion.filter(entity => entity.id === currentControl.id_inspektion_status)[0].value }

		  let controlDate = new Date(currentControl.created_at);
	      let newControlDate = ("0"+(String(controlDate.getDate()))).slice(-2) + '.' + ("0"+(String(controlDate.getMonth()+1))).slice(-2) + '.' + String(controlDate.getFullYear());
		  
		  
		  
		  let controlJSON = {
	        Zugang: zugangValue,
			Bemerkung: currentControl.zugang_bemerkung,
	        Tafel: tafelValue,
	        Deckel: deckelValue,
	        Schacht: schachtValue,
	        Stange: stangeValue,
	        Beurteilung: beurteilungValue,
	        Inspektion: inspektionValue,
	         };
	  
	        currentControlList = Object.keys(controlJSON).map(key =>
                      <ListItem key={key} className="list-line">
                        <ListItemText className="list-bez" primary={key} />
		        		  <ListItemText className="list-attr" primary={controlJSON[key]} />
                      </ListItem>
            );
		  
		  

		  // Ueberlagerung Kontrolle
		  controlDialog = (
            <>
	          <Dialog
                open={this.state.isDialogControlOpen}
				disableBackdropClick
				fullWidth={true}
				maxWidth={'sm'}
                onClose={this.handleDialogControlClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
		        <Grid className="object-container" container spacing={2}>
                  <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
                    <Typography variant="h6" className="item-title">
                      Kontrolle vom {newControlDate}
                    </Typography>
                    <div className="item-container">
                      <List dense={true}>
                  		{currentControlList}
                      </List>
                    </div>
                  </Grid>
				</Grid>
				<Divider />
                <DialogActions>
                  <Button onClick={this.handleDialogControlClose} variant="contained" size="small">
                    Zurück
                  </Button>
                </DialogActions>
              </Dialog>
             </>
		  );
		}
	  }

      // Photos
	  if (typeof photos !== "undefined" && photos !== null && photos.length > 0) {
		  photoList =  photos.map(photo => (
             <Grid key={photo.upload_at} className="photo-grid" item xs={12} sm={6} md={4}>
			   <img src={photo.pfad + '/' + photo.name} className="photo" alt={photo.name} />
			 </Grid>
		  ));
	  }
	}
	  
	return (
        <>
          <Grid className="object-container" container spacing={2}>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={6}>
              <Typography variant="h6" className="item-title">
                Attribute
              </Typography>
              <div className="item-container">
                <List dense={true}>
            		{objectList}
                </List>
          	  <a href={"https://fme.geoseeland.ch/fmedatastreaming/RSW/Report_Schieberkontrolle_RSW.fmw?token=49639a184be5e3f58c223a657d66cced7976cff5&project=" + this.props.data.id_project + "&id=" + this.props.data.id}>
          	    <Button size="small" variant="contained">
          	      Protokoll
                  </Button>
          	  </a>
              </div>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={5}>
              <Typography variant="h6" className="item-title">
                Kontrolle
              </Typography>
              <div className="item-container">
                <List dense={true}>
            		{controlContainer}
                </List>
              </div>
            </Grid>
            <Grid style={{margin: "1rem"}} className="list-container" item xs={12} md={12}>
              <Typography variant="h6" className="item-title">
                Fotos
              </Typography>
              <Grid className="photo-container" container spacing={1}>
                {photoList}
              </Grid>
            </Grid>
          </Grid>
		  <div>{controlDialog}</div>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  gasSchieberArt: state.gas.gasSchieberArt,
  gasSchieberBeurteilung: state.gas.gasSchieberBeurteilung,
  gasSchieberDeckel: state.gas.gasSchieberDeckel,
  gasSchieberInspektion: state.gas.gasSchieberInspektion,
  gasSchieberMaterial: state.gas.gasSchieberMaterial,
  gasSchieberSchacht: state.gas.gasSchieberSchacht,
  gasSchieberSchaltzustand: state.gas.gasSchieberSchaltzustand,
  gasSchieberStange: state.gas.gasSchieberStange,
  gasSchieberTafel: state.gas.gasSchieberTafel,
  gasSchieberZugang: state.gas.gasSchieberZugang,
  gasDruckzone: state.gas.gasDruckzone,
  gasEigentuemer: state.gas.gasEigentuemer,
  gasGemeinde: state.gas.gasGemeinde,
  gasStatus: state.gas.gasStatus
});

export default connect(
  mapStateToProps,
    { getGasSchieberArt,
	  getGasSchieberBeurteilung,
	  getGasSchieberDeckel,
	  getGasSchieberInspektion,
	  getGasSchieberMaterial,
	  getGasSchieberSchacht,
	  getGasSchieberSchaltzustand,
	  getGasSchieberStange,
	  getGasSchieberTafel,
	  getGasSchieberZugang,
	  getGasDruckzone,
	  getGasEigentuemer,
	  getGasGemeinde,
	  getGasStatus  }
)(GasSchieberSheet);