import axios from "axios";

import {
  CREATE_WAS_ANLAGE_KONTROLLE,
  CREATE_WAS_HYDRANT_KONTROLLE,
  CREATE_WAS_RESERVOIR_KONTROLLE,
  CREATE_WAS_SCHIEBER_KONTROLLE,
  CREATE_WAS_PUMPWERK_KONTROLLE,
  UPDATE_WAS_ANLAGE_ATTR,
  UPDATE_WAS_HYDRANT_ATTR,
  UPDATE_WAS_RESERVOIR_ATTR,
  UPDATE_WAS_SCHIEBER_ATTR,
  UPDATE_WAS_PUMPWERK_ATTR,
  GET_WAS_ANLAGE_ART,
  GET_WAS_ANLAGE_MATERIAL,
  GET_WAS_ANLAGE_TYP,
  GET_WAS_HYDRANT_TYP,
  GET_WAS_RESERVOIR_TYP,
  GET_WAS_SCHIEBER_MATERIAL,
  GET_WAS_SCHIEBER_TYP,
  GET_WAS_PUMPWERK_KONTRL_TYP
  
} from "./types";

// Create Anlage Kontrolle
export const createWasserAnlageKontrolle = anlageData => dispatch => {
  axios
    .post("/api/was/anlage/create/kontrolle", anlageData)
    .then(res =>
      dispatch({
        type: CREATE_WAS_ANLAGE_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Hydrant Kontrolle
export const createWasserHydrantKontrolle = hydrantData => dispatch => {
  axios
    .post("/api/was/hydrant/create/kontrolle", hydrantData)
    .then(res =>
      dispatch({
        type: CREATE_WAS_HYDRANT_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Reservoir Kontrolle
export const createWasserReservoirKontrolle = reservoirData => dispatch => {
  axios
    .post("/api/was/reservoir/create/kontrolle", reservoirData)
    .then(res =>
      dispatch({
        type: CREATE_WAS_RESERVOIR_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Schieber Kontrolle
export const createWasserSchieberKontrolle = schieberData => dispatch => {
  axios
    .post("/api/was/schieber/create/kontrolle", schieberData)
    .then(res =>
      dispatch({
        type: CREATE_WAS_SCHIEBER_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Pumpwerk Kontrolle
export const createWasserPumpwerkKontrolle = pumpwerkData => dispatch => {
  axios
    .post("/api/was/pumpwerk/create/kontrolle", pumpwerkData)
    .then(res =>
      dispatch({
        type: CREATE_WAS_PUMPWERK_KONTROLLE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Anlage Attribute
export const updateWasserAnlageAttr = anlageData => dispatch => {
  axios
    .patch("/api/was/anlage/update/attr", anlageData)
    .then(res =>
      dispatch({
        type: UPDATE_WAS_ANLAGE_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Hydrant Attribute
export const updateWasserHydrantAttr = hydrantData => dispatch => {
  axios
    .patch("/api/was/hydrant/update/attr", hydrantData)
    .then(res =>
      dispatch({
        type: UPDATE_WAS_HYDRANT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Reservoir Attribute
export const updateWasserReservoirAttr = reservoirData => dispatch => {
  axios
    .patch("/api/was/reservoir/update/attr", reservoirData)
    .then(res =>
      dispatch({
        type: UPDATE_WAS_RESERVOIR_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schieber Attribute
export const updateWasserSchieberAttr = schieberData => dispatch => {
  axios
    .patch("/api/was/schieber/update/attr", schieberData)
    .then(res =>
      dispatch({
        type: UPDATE_WAS_SCHIEBER_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Pumpwerk Attribute
export const updateWasserPumpwerkAttr = pumpwerkData => dispatch => {
  axios
    .patch("/api/was/pumpwerk/update/attr", pumpwerkData)
    .then(res =>
      dispatch({
        type: UPDATE_WAS_PUMPWERK_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// TBDs
//Anlage Art
export const getWasserAnlageArt = (db_schema) => dispatch => {
  axios
    .get(`/api/was/anlage/art/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_ANLAGE_ART,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_ANLAGE_ART,
        payload: null
      })
    );
};
// Anlage Material
export const getWasserAnlageMaterial = (db_schema) => dispatch => {
  axios
    .get(`/api/was/anlage/material/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_ANLAGE_MATERIAL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_ANLAGE_MATERIAL,
        payload: null
      })
    );
};
// Anlage Typ
export const getWasserAnlageTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/was/anlage/typ/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_ANLAGE_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_ANLAGE_TYP,
        payload: null
      })
    );
};
// Hydrant Typ
export const getWasserHydrantTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/was/hydrant/typ/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_HYDRANT_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_HYDRANT_TYP,
        payload: null
      })
    );
};
// Reservoir Typ
export const getWasserReservoirTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/was/reservoir/typ/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_RESERVOIR_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_RESERVOIR_TYP,
        payload: null
      })
    );
};
// Schieber Typ
export const getWasserSchieberTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/was/schieber/typ/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_SCHIEBER_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_SCHIEBER_TYP,
        payload: null
      })
    );
};
// Schieber Material
export const getWasserSchieberMaterial = (db_schema) => dispatch => {
  axios
    .get(`/api/was/schieber/material/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_SCHIEBER_MATERIAL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_SCHIEBER_MATERIAL,
        payload: null
      })
    );
};
// Pumpwerk Kontrolle Typ
export const getWasserPumpwerkKontrolleTyp = (db_schema) => dispatch => {
  axios
    .get(`/api/was/pumpwerk/kontrolle/typ/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_WAS_PUMPWERK_KONTRL_TYP,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_WAS_PUMPWERK_KONTRL_TYP,
        payload: null
      })
    );
};