import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem } from "@material-ui/core";
import ImageUploader from 'react-images-upload';

import './Step.scss';

class AttributForm extends Component {
  
render() {
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Attribute</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                label="Bezeichnung"
                name="name_nummer"
                variant="outlined"
                disabled
                fullWidth
				value={this.props.values.name_nummer}
				id={'name_nummer' + this.props.values.id}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Feature-ID"
                name="fid"
                variant="outlined"
				disabled
                fullWidth
				id={'fid' + this.props.values.id}
				value={this.props.values.fid}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Standort"
                name="standort"
                variant="outlined"
				disabled
                fullWidth
				id={'standort' + this.props.values.id}
				value={this.props.values.standort}
				className="step-input"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'material' + this.props.values.id}
            select
            label="Material"
		    name="id_material"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_material}
          >
            {this.props.gasSchieberMaterial.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'art' + this.props.values.id}
            select
            label="Art"
		    name="id_art"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_art}
          >
            {this.props.gasSchieberArt.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'druckzone' + this.props.values.id}
            select
            label="Druckzone"
		    name="id_druckzone"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_druckzone}
          >
            {this.props.gasDruckzone.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'schaltzustand' + this.props.values.id}
            select
            label="Schaltzustand"
		    name="id_schaltzustand"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_schaltzustand}
          >
            {this.props.gasSchieberSchaltzustand.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'gemeinde' + this.props.values.id}
            select
            label="Gemeinde"
		    name="id_gemeinde"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_gemeinde}
          >
            {this.props.gasGemeinde.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'status' + this.props.values.id}
            select
            label="Status"
		    name="id_status"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_status}
          >
            {this.props.gasStatus.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={'eigentuemer' + this.props.values.id}
            select
            label="Eigentümer"
		    name="id_eigentuemer"
		    variant="outlined"
			disabled
		    fullWidth
		    className="step-input"
            value={this.props.values.id_eigentuemer}
          >
            {this.props.gasEigentuemer.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
			id={'datum_erstellung' + this.props.values.id}
            label="Baujahr"
            name="datum_erstellung"
            variant="outlined"
			disabled
            fullWidth
			value={this.props.values.datum_erstellung}
			className="step-input"
          />
        </Grid>
		<Grid item xs={12}>
           <Typography variant="h6">Fotos</Typography>
        </Grid>
		<Grid item xs={12}>
           <ImageUploader
		       optimisticPreviews
               withIcon={true}
			   withPreview={true}
               buttonText='Bild Auswählen'
               onChange={this.props.onImgDrop}
               imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
			   fileTypeError="Der Datei-Typ wird nicht unterstützt"
               maxFileSize={10485760}
			   fileSizeError="die Datei ist zu gross"
			   label="Bild(er) aufnehmen"
           />
		 </Grid>
    </>
	)
}
}

export default AttributForm;
