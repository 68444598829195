import axios from "axios";

import {
  CREATE_GAS_SCHIEBER_KONTROLLE,
  UPDATE_GAS_SCHIEBER_ATTR,
  GET_GAS_SCHIEBER_ART,
  GET_GAS_SCHIEBER_BEURTEILUNG,
  GET_GAS_SCHIEBER_DECKEL,
  GET_GAS_SCHIEBER_INSPEKTION,
  GET_GAS_SCHIEBER_MATERIAL,
  GET_GAS_SCHIEBER_SCHACHT,
  GET_GAS_SCHIEBER_SCHALTZUSTAND,
  GET_GAS_SCHIEBER_STANGE,
  GET_GAS_SCHIEBER_TAFEL,
  GET_GAS_SCHIEBER_ZUGANG,
  GET_GAS_DRUCKZONE,
  GET_GAS_EIGENTUEMER,
  GET_GAS_GEMEINDE,
  GET_GAS_STATUS
} from "./types";

// Create Schieber Kontrolle
export const createGasSchieberKontrolle = schieberData => dispatch => {
  axios
    .post("/api/gas/schieber/create/kontrolle", schieberData)
    .then(res => {
      const { kuerzel } = res.data;
	  localStorage.setItem("gas_schieber_kuerzel", kuerzel);
      dispatch({
        type: CREATE_GAS_SCHIEBER_KONTROLLE,
        payload: res.data
      })
    })
    .catch(err => console.log(err));
};

// Update Schieber Attribute
export const updateGasSchieberAttr = schieberData => dispatch => {
  axios
    .patch("/api/gas/schieber/update/attr", schieberData)
    .then(res =>
      dispatch({
        type: UPDATE_GAS_SCHIEBER_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// TBDs
//Schieber Art
export const getGasSchieberArt = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/art/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_ART,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_ART,
        payload: null
      })
    );
};

//Schieber Beurteilung
export const getGasSchieberBeurteilung = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/beurteilung/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_BEURTEILUNG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_BEURTEILUNG,
        payload: null
      })
    );
};

//Schieber Deckel
export const getGasSchieberDeckel = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/deckel/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_DECKEL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_DECKEL,
        payload: null
      })
    );
};

//Schieber Inspektion
export const getGasSchieberInspektion = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/inspektion/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_INSPEKTION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_INSPEKTION,
        payload: null
      })
    );
};

//Schieber Material
export const getGasSchieberMaterial = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/material/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_MATERIAL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_MATERIAL,
        payload: null
      })
    );
};

//Schieber Schacht
export const getGasSchieberSchacht = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/schacht/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_SCHACHT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_SCHACHT,
        payload: null
      })
    );
};

//Schieber Schaltzustand
export const getGasSchieberSchaltzustand = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/schaltzustand/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_SCHALTZUSTAND,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_SCHALTZUSTAND,
        payload: null
      })
    );
};

//Schieber Stange
export const getGasSchieberStange = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/stange/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_STANGE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_STANGE,
        payload: null
      })
    );
};

//Schieber Tafel
export const getGasSchieberTafel = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/tafel/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_TAFEL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_TAFEL,
        payload: null
      })
    );
};

//Schieber Zugang
export const getGasSchieberZugang = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/zugang/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_SCHIEBER_ZUGANG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_SCHIEBER_ZUGANG,
        payload: null
      })
    );
};

//Gas Druckzone
export const getGasDruckzone = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/druckzone/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_DRUCKZONE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_DRUCKZONE,
        payload: null
      })
    );
};

//Gas Eigentuemer
export const getGasEigentuemer = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/eigentuemer/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_EIGENTUEMER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_EIGENTUEMER,
        payload: null
      })
    );
};

//Gas Gemeinde
export const getGasGemeinde = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/gemeinde/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_GEMEINDE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_GEMEINDE,
        payload: null
      })
    );
};

//Gas Status
export const getGasStatus = (db_schema) => dispatch => {
  axios
    .get(`/api/gas/schieber/status/${db_schema}`)
    .then(res =>
      dispatch({
        type: GET_GAS_STATUS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GAS_STATUS,
        payload: null
      })
    );
};